import { Component, Input, OnInit } from "@angular/core";
import { SpinnerService } from "app/services/spinner.service";

import * as Highcharts from "highcharts/highcharts";

declare var require: any;


import Exporting from "highcharts/modules/exporting";
import ExportData from "highcharts/modules/export-data";

require("highcharts/highcharts-more")(Highcharts);
// require('highcharts/modules/drilldown')(Highcharts);
require("highcharts/modules/pattern-fill")(Highcharts);
require('highcharts/modules/annotations')(Highcharts);
require("highcharts/modules/drilldown")(Highcharts);
require("highcharts/modules/heatmap")(Highcharts);
require("highcharts/modules/solid-gauge")(Highcharts);

// Exporting(Highcharts);
// PatterFill(Highcharts);
// HighchartsBoost(Highcharts);
Exporting(Highcharts);
ExportData(Highcharts);

@Component({
  selector: "app-highcharts",
  templateUrl: "./highcharts.component.html",
  styleUrls: ["./highcharts.component.css"]
})
export class HighchartsComponent implements OnInit {

  @Input() height: 800;

  private r = Math.random().toString();

  get randomId() {
    return this.r.substr(2, this.r.length);
  }

  get fullId() {
    return "chartContainer_" + this.randomId;
  }

  public highcharts = Highcharts;
  private chart: any;
  private isInitialized: boolean = false;
  private idCounter: number = 1;
  @Input() allOptions: any;
  @Input() chartOption;
  @Input() horizontal = false;
  @Input() title = "Set the chart Title with the Input field title";
  @Input() subTitle = "";
  @Input() xAxis;
  @Input() yAxis;
  @Input() tooltip = { shared: true, split: false };
  @Input() scrollbar;
  @Input() drilldown;
  @Input() legend;
  @Input() series = [];
  @Input() navigator;
  @Input() rangeSelector;
  @Input() plotOptions;
  @Input() chartExportOptions = {
    chartOptions: {
      navigator: {
        enabled: false
      },
      scrollbar: {
        enabled: false
      }
    },
    buttons: {
      contextButton: {
        menuItems: [
          {
            textKey: "downloadCSV",
            onclick: function () {
              this.downloadCSV();
            }
          }, {
            textKey: "downloadXLS",
            onclick: function () {
              this.downloadXLS();
            }
          }, {
            separator: true
          }, {
            textKey: "printChart",
            onclick: function () {
              this.print();
            }
          }, {
            textKey: "downloadPNG",
            onclick: function () {
              this.exportChart();
            }
          }, {
            textKey: "downloadJPEG",
            onclick: function () {
              this.exportChart({
                type: "image/jpeg"
              });
            }
          }, {
            textKey: "downloadPDF",
            onclick: function () {
              this.exportChart({
                type: "application/pdf"
              });
            }
          }, {
            textKey: "downloadSVG",
            onclick: function () {
              this.exportChart({
                type: "image/svg+xml"
              });
            }
          }
        ]
      }
    },
    enabled: true,
  };

  constructor(private spinnerService: SpinnerService) {
  }

  get Chart() {
    return this.chart;
  }

  public getHighcharts() {
    return Highcharts;
  }

  ngOnInit() {

  }

  ngAfterViewInit() {
    let options: any;
    if (this.allOptions) {
      options = this.allOptions;
    } else {

      options = {
        title: {
          text: this.title
        },
        subtitle: {
          text: this.subTitle
        },
        yAxis: this.yAxis,
        credits: {
          enabled: false
        },
        tooltip: this.tooltip,
        series: this.series,

        exporting: this.chartExportOptions
      };
      if (this.chartOption) {
        options["chart"] = this.chartOption;
      }
      if (this.plotOptions) {
        options["plotOptions"] = this.plotOptions;
      }
      if (this.scrollbar) {
        options["scrollbar"] = this.scrollbar;
      }
      if (this.xAxis) {
        options["xAxis"] = this.xAxis;
      }
      if (this.yAxis) {
        options["yAxis"] = this.yAxis;
      }
      if (this.legend) {
        options["legend"] = this.legend;
      }
      if (this.rangeSelector) {
        options["rangeSelector"] = this.rangeSelector;
      }
      if (this.drilldown) {
        options["drilldown"] = this.drilldown;
      }
      // options["componentClass"] = this;
    }

    // console.log(JSON.stringify(options));
    this.chart = Highcharts.chart(this.fullId, options);
    this.isInitialized = true;
  }

  zoomOut() {
    this.chart.zoomOut();
  }

  addYaxis(axis: any, redraw: boolean = true, animation: any = true): boolean {
    if (this.chart && axis.id) {
      this.chart.addAxis(axis, this.horizontal, redraw, animation);
      return true;
    }
    return false;
  }

  updateYaxis(updatedValues: any, id: any = undefined, redraw: boolean = true, animation: any = true): boolean {
    if (this.chart) {
      let axis;
      if (!id) {
        axis = this.chart.yAxis[0];
      } else {
        axis = this.chart.get(id);
      }
      if (axis) {
        axis.update(updatedValues);
      } else {
        this.addYaxis(updatedValues, redraw, animation);
      }
      return true;
    }
    return false;
  }

  updateChart(updatedValues: any): boolean {
    if (this.chart) {
      this.chart.update(updatedValues);
      return true;
    }
    return false;
  }

  updateXaxis(updatedValues: any): boolean {
    if (this.chart) {
      this.chart.xAxis[0].update(updatedValues);
      return true;
    }
    return false;
  }

  addSeries(serie: any, id: any, redraw: boolean = true, animation: any = true): boolean {
    if (this.chart && id) {
      this.chart.addSeries(serie, redraw, animation);
      return true;
    }
    return false;
  }

  updateSeries(updatedSeries: any, id: any, redraw: boolean = true, animation: any = true): boolean {
    if (this.chart) {
      let x = this.chart.get(id);
      if (x) {
        x.update(updatedSeries, redraw, animation);
      } else {
        this.addSeries(updatedSeries, id, redraw, animation);
      }
      return true;
    }
    return false;
  }

  addPointToSerie(point: any[2], seriesPosition: number, redraw: boolean = true, shift: boolean = true, animation: any = true): boolean {
    if (this.chart) {
      this.chart.series[seriesPosition].addPoint(point, redraw, shift, animation);
      return true;
    }
    return false;
  }

  updateById(updatedOptions: any, id: any = undefined, redraw: boolean = true): boolean {
    if (this.chart) {
      if (id) {
        let x = this.chart.get(id);
        if (x) {
          x.update(updatedOptions, redraw);
        }
        return false;
      } else {
        this.chart.update(updatedOptions, redraw, true);
      }
      return true;
    }
    return false;
  }

  removeById(id) {
    if (this.chart) {
      let x = this.chart.get(id);
      if (x) {
        x.remove();
      } else {
        return false;
      }
      return true;
    }
    return false;
  }

  redraw() {
    this.chart.redraw();
  }

  GetDataXaxisMinMax() {
    return [this.chart.xAxis[0].dataMin, this.chart.xAxis[0].dataMax];
  }
}
