import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { BuildingService } from 'app/services/building.service';
import { PointgroupsService } from 'app/services/pointgroups.service';
import { SpinnerService } from 'app/services/spinner.service';
import { SweetalertService } from 'app/services/sweetalert.service';
import { Observable } from 'rxjs';
import { QrPopupComponent } from '../qr-popup/qr-popup.component';
import { SearchPopupComponent } from '../search-popup/search-popup.component';
import { ConfirmationComponent } from './confirmation/confirmation.component';

@Component({
  selector: 'app-groups-list',
  templateUrl: './groups-list.component.html',
  styleUrls: ['./groups-list.component.css']
})
export class GroupsListComponent implements OnInit, OnChanges {

  constructor(public buildingService: BuildingService, private spinnerService: SpinnerService, public dialog: MatDialog, private spinner: SpinnerService, private alert: SweetalertService, private pointsgroupService: PointgroupsService) { }

  ngOnChanges(changes: SimpleChanges): void {
    this.hasResults = this.items.length > 0;
  }

  public qrOptions = [true, false];

  @Input() button: { text: string, style: string, url: string };
  @Input() type: number;

  public hasResults = true;

  @Input() items: {
    _id: string;
    name: string;
    metadata: any,
    type: any;
    icon: string;
    points: string[];
    subGroups: string[];
    searchTags: string[];
    building?: string;
    assetType?: string
  }[] = [];

  @Input() buildings = [{ _id: "", name: "All Buildings" }];

  @Output() pointsChanged: EventEmitter<any> = new EventEmitter<any>();
  @Output() pageChanged: EventEmitter<any> = new EventEmitter<any>();
  @Output() searchChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() buildingChanged: EventEmitter<any> = new EventEmitter<any>();
  @Output() qrCodeLockedChanged: EventEmitter<any> = new EventEmitter<any>();

  ngOnInit(): void {
  }



  public openQR(code, name) {
    const dialogRef = this.dialog.open(QrPopupComponent, {
      width: '440px',
      data: {
        qr: [{ inputString: code, label: name }],
        qrSize: 400
      }
    });
  }

  public refreshPoints(filter: string, pageOptions: { page: number, pageSize: number }): Observable<any> {
    return new Observable(observer => {


      this.spinnerService.activate();
      this.buildingService.metadata(this.selectedBuilding, encodeURI(filter), pageOptions.page, pageOptions.pageSize).subscribe(res => {
        this.spinnerService.desactivate();

        observer.next(res.data);
        observer.complete();
      })
    })
  }

  public points: { name: string, id: string }[] = [];

  public addPoints() {
    const dialogRef = this.dialog.open(SearchPopupComponent, {
      width: '960px',
      data: {
        single: false,
        searchCallback: this.refreshPoints.bind(this),
        displayField: "friendlyName",
        valueField: "_id",
        preselected: this.points.map(x => { return { value: x.id, text: x.name } })
      }
    });

    dialogRef.afterClosed().subscribe(x => {
      if (x && x.length > 0) {
        this.points = x.map(t => { return { name: t.friendlyName, id: t._id } })
        this.pointsChanged.emit(this.points.map(t => t.id));
      }
    })
  }

  public removePoint(id) {
    this.points.splice(this.points.indexOf(this.points.find(x => x.id == id)), 1);
    this.pointsChanged.emit(this.points ? this.points.map(t => t.id) : []);
  }

  @Input() selectedBuilding = "";

  public deleteItem(item) {
    this.alert.yesAndNow("Attention!", "This operation can not be undone. Are you sure you want to continue?", "").subscribe(
      res => {
        if (res) {
          this.spinner.activate();
          this.pointsgroupService.delete(item).subscribe(x => {
            this.spinner.desactivate();

            this.pageChanged.emit(this.pagination);
          },
            err => {
              this.spinner.desactivate();

              if (err.error.errors && err.error.data) {

                let names = "";
                let groups = err.error.data.filter(d => d.type == 2).map(d => d.name).join(",");
                let assets = err.error.data.filter(d => d.type == 1).map(d => d.name).join(",");

                if (groups) {
                  names += "\nGroups: " + groups;
                }
                if (assets) {
                  names += "\nAsets: " + assets;
                }

                this.alert.custom({
                  title: "Attention!", text: "The following groups/assets contains the the resource you are trying to delete. Do you want to continue?" + names, buttons: {
                    yes: {
                      text: "Yes",
                      value: "yes"
                    },
                    exit: {
                      text: "No",
                      value: "no"
                    }
                  }
                }).then(r => {
                  if (r == "yes") {
                    this.spinner.activate();
                    this.pointsgroupService.delete(item, 1).subscribe(x => {
                      this.spinner.desactivate();

                      this.pageChanged.emit(this.pagination);
                    })
                  }
                })

              }
            })
        }
      })
  }

  public lockItem(item) {
    let original: any = {};
    original = Object.assign(original, item);

    original.building = original.building._id;

    let newObject: any = {};
    newObject = Object.assign(newObject, original);

    newObject.qrCodeLocked = newObject.qrCodeLocked ? !newObject.qrCodeLocked : true;

    console.log(original)

    this.spinner.activate();
    this.pointsgroupService.update(original, newObject).subscribe(x => {
      this.spinner.desactivate();

      this.pageChanged.emit(this.pagination);
    })
  }

  public deleteAll() {
    this.alert.yesAndNow("Attention!", "This operation can not be undone. Are you sure you want to continue?", "").subscribe(res => {
      if (res) {
        this.spinner.activate();
        this.pointsgroupService.deleteAll(this.type).subscribe(x => {
          this.spinner.desactivate();

          this.pageChanged.emit(this.pagination);
        })
      }
    })
  }

  public filter() {
    this.searchChange.emit(this.searchField);
  }

  public qrCodeLockedChange() {
    this.qrCodeLockedChanged.emit(this.qrOptions);
  }


  @Input() tooltipSubGroups: boolean = true;

  @Input() searchField: string = "";
  @Input() pagination = { page: 1, pageSize: 100, hasNext: true };

  public changeBuilding() {
    this.points = [];
    this.buildingChanged.emit(this.selectedBuilding);
  }

  public changePage(value) {
    this.pagination.page = value.page;
    this.pagination.pageSize = value.pageSize;

    this.pageChanged.emit(this.pagination);
  }
}
