import { AfterViewInit, Component, Input, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { HighchartsComponent } from 'app/components/common/highcharts/highcharts/highcharts.component';
import { AppHighstockComponent } from 'app/components/common/highcharts/highstock/highstock.component';
import { PointTagsComponent } from 'app/components/common/point-tags/point-tags.component';
import { AdminPointService } from 'app/services/data.service';
import { PointgroupsService } from 'app/services/pointgroups.service';
import { SpinnerService } from 'app/services/spinner.service';
import * as moment from 'moment-timezone';
import { forkJoin, Observable } from 'rxjs';
import { AllChartComponent } from './all-chart/all-chart.component';
import { ComparsionChartComponent } from './comparsion-chart/comparsion-chart.component';

@Component({
  selector: 'app-admin-asset-view',
  templateUrl: './admin-asset-view.component.html',
  styleUrls: ['./admin-asset-view.component.css']
})
export class AdminAssetViewComponent implements AfterViewInit {

  constructor(private pointsService: AdminPointService, public dialog: MatDialog, private route: ActivatedRoute, private spinnerService: SpinnerService, private groups: PointgroupsService) { }

  @ViewChild("chart", { static: false }) chartPoints: AppHighstockComponent;

  public datasource = new MatTableDataSource<any>();

  public root: AssetGroupList = null;
  public breadcrumbs: { name: string, id: string, icon: string }[] = [];

  public isPoint: boolean = false;
  public current: any = null;

  public currentType: string = "";

  public currentSubgroups: any[] = [];
  public currentAssets: any[] = [];
  public currentPoints: any[] = [];

  public selectedIndex = 1;

  public selectItem(item, type) {
    this.current = item;
    if (type == "point") {
      this.isPoint = true;
    }
    else {
      this.isPoint = false;
    }
  }

  public load(id) {
    this.getGroup(id).subscribe(x => {
      this.breadcrumbs.push({ name: this.root.name, id: this.root._id, icon: this.root.icon })
    });
  }

  public openTags(id: string) {
    let p = this.currentPoints.find(x => x._id == id);
    const dialogRef = this.dialog.open(PointTagsComponent, {
      width: '600px',
      data: {
        list: p.tags,
        title: "Tags for " + p.displayOrd
      }
    });
  }

  public openFacets(id: string) {
    let p = this.currentPoints.find(x => x._id == id);
    const dialogRef = this.dialog.open(PointTagsComponent, {
      width: '600px',
      data: {
        list: p.meta,
        title: "Meta for " + p.displayOrd
      }
    });
  }

  public stepback(id) {
    this.getGroup(id).subscribe(t => {
      this.breadcrumbs.splice(this.breadcrumbs.findIndex(x => x.id == id) + 1);
    })
  }

  public chartsInfo: { name: string, series: { id: string, name: string, data: any[] }[] }[] = [];

  public displayedColumns: string[] = ["index", "ord", "lastValue", "lastReceivedData", "facets"]
  public lastGroup: string = "";

  public dataview(id) {
    this.spinnerService.activate();
    this.pointsService.GetDataForPoint({ building: this.current.building, point: id, from: moment.utc().startOf("day").toISOString(), to: moment.utc().endOf("day").toISOString() }).subscribe(x => {
      this.spinnerService.desactivate();
      let dataView = { name: this.root.name, data: [] }

      let s = []
      let d = x.data[0];
      let start = moment(d.timestamp);

      if (d) {
        for (let i = 1; i <= 7; i++) {
          for (let j = 0; j < 1440; j++) {
            if (d.weekPointData[i.toString()] && d.weekPointData[i.toString()][j] != null) {
              s.push([start.valueOf(), d.weekPointData[i.toString()][j]]);
            }
            // else {
            //   s.push([start.valueOf(), null]);
            // }
            start.add(1, "minute");
          }
        }
        let t = this.datasource.data.find(x => x._id == d.point);
        dataView.data.push({
          id: t._id,
          name: t.displayOrd.split("/")[t.displayOrd.split("/").length - 1],
          data: s,
          showInNavigator: true
        })

        for (let p of this.datasource.data.filter(x => x._id != d.point)) {
          dataView.data.push({
            id: p._id,
            name: p.displayOrd.split("/")[p.displayOrd.split("/").length - 1],
            data: [],
            showInNavigator: true,
            visible: false
          })
        }
      }

      this.dialog.open(AllChartComponent, { width: '1080px', data: { values: dataView,  from: moment.utc().startOf("day").toISOString(), to: moment.utc().endOf("day").toISOString(), building: this.current.building } });
    })

    // let ids = this.datasource.data.map(x => x._id);
    // let arr = [];
    // ids.forEach(id => {
    //   arr.push(this.pointsService.GetDataForPoint({
    //     building: this.current.building,
    //     point: id,
    //     from: moment.utc().startOf("day").toISOString(),
    //     to: moment.utc().endOf("day").toISOString(),
    //   }));
    // })
    // this.spinnerService.activate();
    // forkJoin(arr).subscribe((res) => {
    //   this.spinnerService.desactivate();
    //   let dataView = { name: this.root.name, data: [] }
    //   for (let data of <any>res) {
    //     let s = []
    //     let start = moment.utc().subtract(1, "week").startOf("week");
    //     let d = data.data[0];
    //     if (d) {
    //       for (let i = 1; i <= 7; i++) {
    //         for (let j = 0; j < 1440; j++) {
    //           if (d.weekPointData[i.toString()] && d.weekPointData[i.toString()][j] != null) {
    //             s.push([start.valueOf(), d.weekPointData[i.toString()][j]]);
    //           }
    //           // else {
    //           //   s.push([start.valueOf(), null]);
    //           // }
    //           start.add(1, "minute");
    //         }
    //       }
    //       let name = this.datasource.data.find(x => x._id == d.point).friendlyName;
    //       dataView.data.push({
    //         id: name,
    //         name: name.split("/")[name.split("/").length - 1],
    //         data: s,
    //         showInNavigator: true
    //       })
    //     }
    //   }
    //   this.dialog.open(AllChartComponent, { width: '1080px', data: dataView });
    // });
  }


  private getGroup(id): Observable<any> {
    return new Observable((observer) => {

      this.spinnerService.activate();
      this.groups.getPointGroup(id).subscribe(x => {
        this.spinnerService.desactivate();

        this.currentAssets = [];
        this.currentSubgroups = [];
        this.currentPoints = [];

        let assetGroup = x.data;
        assetGroup.points = x.points;
        assetGroup.open = true;

        this.root = assetGroup;
        this.current = this.root;

        if (this.root.type == 2) {
          this.currentType = "Group"
          this.lastGroup = this.root._id;
        }
        else {
          this.currentType = "Asset"
        }

        if (this.current.subGroups == undefined) this.current.subGroups = [];
        if (this.current.points == undefined) this.current.points = [];

        for (let t of this.root.subGroups) {
          if (t.type == 1)
            this.currentAssets.push(t);
          else {
            this.currentSubgroups.push(t);
          }
        }

        this.currentPoints = this.root.points;
        this.chartsInfo = [];

        for (let i = 0; i < this.currentPoints.length; i++) {
          let p = this.currentPoints[i];

          let name = p.friendlyName.split("/");
          name = name[name.length - 1];
          let ch = this.chartsInfo.find(y => y.name == name);
          if (ch == null) {
            ch = {
              name: name,
              series: []
            }

            this.chartsInfo.push(ch);
          }

          ch.series.push({
            data: [p.lastReceivedDataValue],
            id: p.friendlyName,
            name: p.friendlyName
          })

          p.functioName = name;

          p.index = i + 1;
          p.lastReceivedData = moment(p.lastReceivedData).format("DD/MM/YYYY HH:mm");
          p.timezoneTooltip = moment(p.lastReceivedData).format("Z z");
          p.lastValue = (Math.round(p.lastReceivedDataValue * 100) / 100) + (p.unit ? p.unit : "");
          p.meta = [];

          if (p.facetsObj) {
            for (let key of Object.keys(p.facetsObj)) {
              if (typeof p.facetsObj[key] === 'object' && p.facetsObj[key] !== null) {
                for (let nested of Object.keys(p.facetsObj[key])) {
                  p.meta.push(key + ": {" + nested + ":" + p.facetsObj[key][nested] + "}");

                }
              }
              else if (Array.isArray(p.facetsObj[key])) {
                p.meta.push(key + ": " + p.facetsObj[key].join(", "));
              }
              else {
                p.meta.push(key + ": " + p.facetsObj[key]);
              }
            }
          }
        }

        this.datasource.data = this.currentPoints;


        if (this.currentPoints.length > 0) {
          this.selectedIndex = 0;
        }
        else if (this.currentAssets.length > 0) {
          this.selectedIndex = 1;
        }
        else if (this.currentSubgroups.length > 0) {
          this.selectedIndex = 2;
        }

        observer.next();
        observer.complete();
      })
    })
  }

  public comparsion(name) {

    this.spinnerService.activate();
    this.groups.getPointGroup(this.lastGroup, 1, name).subscribe(x => {
      this.spinnerService.desactivate();

      let data = {
        name: name + " Group View (" + this.breadcrumbs.find(x => x.id == this.lastGroup).name + ")",
        xAxis: {
          categories: x.points.map(t => t.friendlyName),
          title: {
            text: null
          },
          type: 'category',
          min: 0,
          max: 20,
          scrollbar: {
            enabled: true
          },
          tickLength: 0
        },
        series: [{
          id: "main", name: "Last Value",
          data: x.points.map(t => t.lastReceivedDataValue)
        }]
      }

      const dialogRef = this.dialog.open(ComparsionChartComponent, {
        width: '1000px',
        data: data
      });
    });

  }


  ngAfterViewInit(): void {

    this.route.params.subscribe(p => {
      this.getGroup(p["id"]).subscribe(x => {
        this.breadcrumbs.push({ name: this.root.name, id: this.root._id, icon: this.root.icon })
      });
    })
  }

}


// @Component({
//   selector: 'app-admin-asset-item-view',
//   templateUrl: './admin-asset-view.component.html',
//   styleUrls: ['./admin-asset-view.component.css']
// })
// export class AdminAssetItemViewComponent implements OnInit {

//   constructor() { }

//   ngOnInit(): void {

//   }
// }


export interface AssetGroupList {
  points?: AssetItemList[];
  searchTags: string[];
  icon: string;
  name: string;
  subGroups?: AssetGroupList[];
  type: number;
  _id: string;
  open: boolean;
}

export interface AssetItemList {
  friendlyName: string;
  ord: string;
  _id: string;
}
