import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AdvancedSearchCondition, AdvancedSearchFunction, AdvancedSearchQuery } from '../advanced-search.component';

@Component({
  selector: 'app-advanced-search-query',
  templateUrl: './advanced-search-query.component.html',
  styleUrls: ['./advanced-search-query.component.css']
})
export class AdvancedSearchQueryComponent implements OnInit {

  @Input() query: AdvancedSearchQuery;
  @Input() enableRemove: boolean = true;
  @Input() index: number;
  @Output() removeThis: EventEmitter<any> = new EventEmitter<any>();
  @Output() queryChange: EventEmitter<AdvancedSearchQuery> = new EventEmitter<AdvancedSearchQuery>();

  public options = AdvancedSearchCondition;
  public functions = AdvancedSearchFunction;

  constructor() { }

  public removeQuery(){
    this.removeThis.emit(this.index);
  }

  public addComparsion() {
    this.query.functions.push({
      function: "Contains",
      value: ""
    })

    this.queryChange.emit(this.query);
  }

  public removeInner(index){
    this.query.subQuery.splice(index, 1);

    this.queryChange.emit(this.query);
  }

  public removeFunction(index) {
    this.query.functions.splice(index, 1);

    this.queryChange.emit(this.query);
  }

  public addCondition() {
    this.query.subQuery.push({
      condiotion: "And",
      expanded: true,
      functions: [{
        function: "Contains",
        value: ""
      }],
      subQuery: []
    })

    this.queryChange.emit(this.query);
  }

  ngOnInit(): void {
  }

}
