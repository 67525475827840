import { Component, OnInit, Renderer2 } from "@angular/core";
import { environment } from 'environments/environment';
import { VersionCheckService } from './services/version-checker.service';
import { PlatformLocation } from '@angular/common';
import { ThemingService } from "./services/theming.service";
import { Title } from "@angular/platform-browser";
import { AppService } from "./services/app.service";
import { TranslocoService, TRANSLOCO_LOADING_TEMPLATE } from "@ngneat/transloco";
import { filter } from "rxjs/operators";
import { LoginService } from "./services/login.service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"],
  providers: [
    {
      provide: TRANSLOCO_LOADING_TEMPLATE,
      useValue: '<p>loading...</p>'
    }
  ]
})
export class AppComponent implements OnInit {
  constructor(private translocoService: TranslocoService, private appService: AppService, private titleService: Title, private themingService: ThemingService, private versionCheckService: VersionCheckService, private platformLocation: PlatformLocation, private renderer: Renderer2) { }

  public loaded: boolean = false;

  ngOnInit(): void {


    if (this.themingService.hasProperty('title')) {
      this.titleService.setTitle(this.themingService.dictionary.title);
      this.appService.productName = this.themingService.dictionary.title;
    }

    this.translocoService.events$.subscribe((t) => {
      if (t.type == "translationLoadSuccess") {
        this.loaded = true;
      }
    })



    let lang = this.appService.getLanguage();
    if (lang) {
      this.translocoService.setActiveLang(lang);
    }
    else {
      this.translocoService.setActiveLang('en');
    }


    if (this.themingService.hasProperty('bodyClass')) {
      this.renderer.addClass(document.body, this.themingService.dictionary.bodyClass);
    }



    if ((this.platformLocation as any).location.origin.indexOf("localhost") == -1) {
      this.versionCheckService.initVersionCheck((this.platformLocation as any).location.origin + "/version.json");
    }
  }
}
