import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { HttpClient } from "@angular/common/http";
import { VersionCheckService } from './version-checker.service';

@Injectable({
  providedIn: 'root'
})
export class ErrorReportService {

  constructor(private http: HttpClient, private versionChecker: VersionCheckService) { }

  private pendingReport: {
    stack: string,
    user?: string,
    date: string,
    url?: string,
    other?: string,
    img?: any
  } = null;

  public prepareReport(stack: string, date: string, user?: string, url?: string, other?: string, img?: any) {
    this.pendingReport = {
      stack: stack,
      user: user,
      date: date,
      url: url,
      other: other,
      img: img
    };

    this.sendReport();
  }

  //[POST] /frontEndErrorReport
  private sendReport() {
    let url = environment.apiUrl + "frontEndErrorReport";

    if (this.versionChecker.version == "{{POST_BUILD_ENTERS_VERSION_HERE}}"){
      this.pendingReport = null;
      return;
    }

    this.http.post(url, {
      apiKey: "pLVYXwnh52iirQSPE8EKn3FFqkYk4uzKR8bjxxjRtGvdYesnDUE7UGDCVqgnX7kH",
      version: this.versionChecker.version + " " + this.versionChecker.hash ,
      timestamp: this.pendingReport.date,
      report: {
        stack: this.pendingReport.stack,
        url: this.pendingReport.url,
        user: this.pendingReport.user,
        other: this.pendingReport.other
      },
      hasImage: this.pendingReport.img ? true : undefined
    }).subscribe(x => {
      console.log(x);

      this.pendingReport = null;
    })
  }
}
