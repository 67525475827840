import { Component, OnInit, ViewChild } from "@angular/core";
import { AppI18nService } from "app/services/app.i18n.service";
import { MAT_DATE_FORMATS } from "@angular/material/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { AppService } from "app/services/app.service";
import { SpinnerService } from "app/services/spinner.service";
import { SweetalertService } from "app/services/sweetalert.service";
import { LoginService } from "app/services/login.service";
import { ActivatedRoute } from "@angular/router";
import { UserType } from "app/interfaces/baseinterface";
import { BuildingService } from "../../../services/building.service";

import * as moment from "moment-timezone";
import { DATEPICKER_FORMAT } from 'app/components/adapters/DatepickerFormats';
import { FutureDecisionsDate } from "app/components/classes/futuredecisions.date";
import { MatDialog } from "@angular/material/dialog";
import { ImagePopupComponent } from "app/components/common/image-popup/image-popup.component";
import { SessionService } from "app/services/session.service";

@Component({
  selector: "app-admin-meter-reading",
  templateUrl: "./meter-reading.component.html",
  styleUrls: ["./meter-reading.component.css"],
  providers: [
    { provide: MAT_DATE_FORMATS, useValue: DATEPICKER_FORMAT }
  ]
})

export class AdminMeterReadingComponent implements OnInit {
  public momentLib = moment;

  public noResults = false;

  public timewindow = "Week"
  public possibleWindows = ["Day", "Week", "Month", "Year"];

  public dataSource = new MatTableDataSource();

  public fromDate = new FutureDecisionsDate(moment().toISOString());
  public toDate = moment();

  public max = new FutureDecisionsDate(moment().endOf("day").format());

  public displayedColumns: string[];
  public building: string = null;
  public enums = UserType;

  public onlyFailures = false;
  public onlyLiveData = false;
  public onlyVirtual = false;
  public onlyProcessed = false;

  public filterText = "";

  private rawData: any;

  public canEdit = true;


  public pagination: { hasNext: boolean, page: number, pageSize: number } = { hasNext: false, page: 1, pageSize: 100 }
  public changePage(event) {

    this.pagination.page = event.page;
    this.pagination.pageSize = event.pageSize;

    this.refresh();
  }

  public filter: string = "";
  public applyFilter(value) {
    this.filter = value;
    this.pagination.page = 1;

    this.refresh();
  }

  constructor(private sessionService: SessionService, private buildingService: BuildingService, public dialog: MatDialog, public loginService: LoginService, public dataService: BuildingService, private appService: AppService,
    public i18n: AppI18nService, private spinnerService: SpinnerService, private alert: SweetalertService, private route: ActivatedRoute) {

  }

  public changeEdit(row) {
    if (this.canEdit) {
      row.editing = true;
    }
  }

  public buildings = [];

  ngOnInit() {

    this.spinnerService.activate();
    this.route.queryParams.subscribe(params => {
      this.building = params["building"];

      this.spinnerService.desactivate();

      if(this.building){
        this.sessionService.building = this.building;
      }
      else{
        this.building = this.sessionService.building;
        this.refresh();
      }
    })

    this.spinnerService.activate();
    this.buildingService.getAll().subscribe(x => {
      this.buildings = x.data;

      this.spinnerService.desactivate();
    })

    this.displayedColumns = [
      "timestamp",
      "status",
      "meter_unit",
      "point",
      "meter_reading",
      // "units_match",
      "download",
      "validate"
      // "op"
    ];

    if (this.building)
      this.refresh();
  }


  filterByOrd(pointName) {
    this.filterText = pointName;
    this.applyFilter(pointName);
  }

  refresh() {
    let ut = this.loginService.userInfo.type;
    this.canEdit = ut == UserType.CompanyAdmin || ut == UserType.BuildingAdmin || ut == UserType.Root || ut == UserType.Maintenance;

    let start = this.fromDate.dateObject.startOf("day");
    let end;
    switch (this.timewindow) {
      case "One day":
        end = start.clone().add(1, "day").startOf("day");
        break;
      case "Last three days":
        end = start.clone().startOf("day");
        start = start.add(3, "day").startOf("day");
        break;
      case "Week":
        start = start.startOf("week");
        end = start.clone().endOf("week");
        break;
      case "Month":
        start = start.startOf("month");
        end = start.clone().endOf("month");
        break;
      case "Year":
        start = start.startOf("year");
        end = start.clone().endOf("year");
        break;
    }

    this.spinnerService.activate();
    this.dataService.GetMeterReadings(this.building, start.toISOString(), end.toISOString(), this.filter, this.pagination.page, this.pagination.pageSize, this.onlyProcessed, this.onlyVirtual, this.onlyLiveData).subscribe(
      results => {
        this.noResults = results.data.length == 0;

        this.rawData = results.data.map((i: any) => {
          let t = Object.assign({
            meter_reading_changed: i.meter_reading,
            meter_unit_changed: i.meter_unit,
            unit_match: i.meter_unit?.toLowerCase() == i.point_unit?.toLowerCase(),

          }, i)

          t.timestamp = moment(t.timestamp).format("MMMM Do, HH:mm");
          t.timestampTooltip = moment(t.timestamp).format("MMMM Do, HH:mm Z z");

          t.hasErrors = !i.readingMatch || i.meter_unit?.toLowerCase() != i.point_unit?.toLowerCase()
          if (t.hasErrors) {
            let f = 0;
            t.errorTooltip = "";

            if (!i.readingMatch) {
              f++;
              t.errorTooltip = "Reading mismatch\n";
            }
            if (i.meter_unit?.toLowerCase() != i.point_unit?.toLowerCase()) {
              f++;
              t.errorTooltip += "Units mismatch";
            }

            if (f == 1) {
              t.errorClass = "btn-danger";
            }
            else {
              t.errorClass = "btn-danger";
            }
          }

          return t;
        });
        this.pagination.hasNext = results.data.length > this.pagination.pageSize;
        this.dataSource.data = this.rawData;
        this.spinnerService.desactivate();
      },
      err => {
        this.spinnerService.desactivate();
        console.log(err);
        // this.alert.error(this.i18n.translate('general.errors.error'), this.i18n.translate('general.errors.unkwonError', err));
      }
    );
  }

  saveReading(row) {
    if (row.meter_reading_changed != row.meter_reading || row.meter_unit_changed != row.meter_unit) {
      this.spinnerService.activate();
      this.dataService.SaveMeterReadings(row._id, row.meter_reading_changed, row.meter_unit_changed).subscribe(
        results => {
          this.alert.success(this.i18n.translate("general.messages.success"),
            this.i18n.translate("general.messages.updated", this.i18n.translate("general.sections.admin.building.meterReading")));
          this.spinnerService.desactivate();
          row.meter_reading = row.meter_reading_changed;
          row.editing = false;

          this.refresh();
        },
        err => {
          this.spinnerService.desactivate();
          row.editing = false;
          console.log(err);
          // this.alert.error(this.i18n.translate('general.errors.error'), this.i18n.translate('general.errors.unkwonError', err));
        }
      );
    } else {
      row.editing = false;
    }
  }

  //

  manualValidate(row) {
    this.spinnerService.activate();
    this.dataService.validateMeterReadings(row._id).subscribe(
      results => {
        row.readingMatch = true;
        this.alert.success(this.i18n.translate("general.messages.success"),
          this.i18n.translate("general.messages.updated", this.i18n.translate("general.sections.admin.building.meterReading")));
        this.spinnerService.desactivate();
        row.editing = false;
      },
      err => {
        this.spinnerService.desactivate();
        console.log(err);
        // this.alert.error(this.i18n.translate('general.errors.error'), this.i18n.translate('general.errors.unkwonError', err));
      }
    );
  }

  ngAfterViewInit() {

  }


  public dataFilter() {
    // this.dataSource.data = this.rawData.filter((d) => {
    //   if (this.onlyProcessed && !d.processed) {
    //     return false;
    //   }
    //   if (this.onlyVirtual && (d.liveDataProcessed == true || d.liveDataProcessed == false)) {
    //     return false;
    //   }
    //   if (this.onlyLiveData && !(d.liveDataProcessed == true || d.liveDataProcessed == false)) {
    //     return false;
    //   }
    //   if (this.onlyFailures && !(d.readingMatch == false || d.liveDataValid == false)) {
    //     return false;
    //   }
    //   return true;
    // });
  }

  public download(reading) {
    this.spinnerService.activate();
    this.dataService.GetMeterReadingImage(reading._id).subscribe(
      results => {
        this.dialog.open(ImagePopupComponent, {
          width: '960px',
          data: {
            source: results.url
          }
        })
        this.spinnerService.desactivate();
      },
      err => {
        this.spinnerService.desactivate();
        console.log(err);
        // this.alert.error(this.i18n.translate('general.errors.error'), this.i18n.translate('general.errors.unkwonError', err));
      }
    );
  }
}
