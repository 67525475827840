import { Component, OnInit } from "@angular/core";
import { UserService } from "app/services/user.service";
import { SpinnerService } from "app/services/spinner.service";
import { LoginService } from "app/services/login.service";
import { User } from "app/interfaces/user";
import { AppI18nService } from "app/services/app.i18n.service";
import { SweetalertService } from "app/services/sweetalert.service";
import { IReturnedError } from "app/services/base.service";
import { Router } from "@angular/router";

@Component( {
  selector: "app-profile",
  templateUrl: "./profile.component.html",
  styleUrls: [ "./profile.component.css" ]
} )
export class ProfileComponent implements OnInit {

  constructor( private router: Router, private i18n: AppI18nService, private alert: SweetalertService, private userService: UserService,
    private spinnerService: SpinnerService, private loginService: LoginService ) {
  }

  public user: User;
  private originalUser: User;

  ngOnInit() {
    this.spinnerService.activate();
    this.userService.getOne( this.loginService.userInfo._id ).subscribe( x => {
      this.spinnerService.desactivate();

      this.user = x.data;
      this.originalUser = this.user;
    } );
  }

  public save( event ) {
    console.log( this.originalUser );
    console.log( event.value );
    let user: User = {
      _id: this.originalUser._id,
      company: event.value.company,
      password: event.value.password,
      email: this.originalUser.email,
      person: {
        city: event.value.city,
        country: event.value.country,
        landline: event.value.landline,
        contacts: { emails: event.value.emails, telephones: event.value.telephones },
        primaryEmail: event.value.email,
        name: event.value.name,
        mobilePhone: event.value.mobilephone
      },
      type: event.value.type,
      username: ""
    };

    // console.log(newCompany);

    this.spinnerService.activate();
    this.userService.update( this.originalUser, user ).subscribe(
      res => {
        this.spinnerService.desactivate();
        this.alert.success( this.i18n.translate( "general.messages.success" ),
          this.i18n.translate( "general.messages.updated", this.i18n.translate( "interfaces.user.user" ) ) );
        this.router.navigate( [ "/" + this.loginService.userInfo.type ] );
      },
      err => {
        let errors: IReturnedError = {
          errors: []
        };

        for( let x of err.errors ) {
          errors.errors.push( { field: "", message: x } );
        }

        this.spinnerService.desactivate();
        this.alert.error( this.i18n.translate( "general.messages.error" ),
          this.i18n.translate( "general.messages.invalidForm", this.userService.generateHtmlListOfErrors( errors ) ) );
      } );
  }

}
