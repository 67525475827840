import { Component, OnInit } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { BuildingService } from 'app/services/building.service';
import { PointgroupsService } from 'app/services/pointgroups.service';
import { SessionService } from 'app/services/session.service';
import { SpinnerService } from 'app/services/spinner.service';

@Component({
  selector: 'app-admin-assets-list',
  templateUrl: './admin-assets-list.component.html',
  styleUrls: ['./admin-assets-list.component.css']
})
export class AdminAssetsListComponent implements OnInit {

  constructor(private sessionService: SessionService, private translocoService: TranslocoService, private buildingService: BuildingService, private spinnerService: SpinnerService, private pointsGroupService: PointgroupsService) { }

  public createText = "";
  public items = [];
  public searchField = "";
  public pagination = { page: 1, pageSize: 100, hasNext: false };
  public buildings = [];
  public selectedBuilding = "";
  public qrCodeLocked = "";
  public points = [];

  public pointsChanged(event){
    this.points = event;
    this.pagination.page = 1;
    this.pagination.pageSize = 100;
    this.refresh();
  }

  public qrCodeLockedChanged(values: boolean[]) {
    if (values.length == 2 || values.length == 0) {
      this.qrCodeLocked = "";
    }
    else {
      this.qrCodeLocked = values[0] + "";
    }
    this.refresh();
  }

  public searchChange(value) {
    this.pagination.page = 1;
    this.pagination.pageSize = 100;
    this.searchField = value;
    this.refresh();
  }

  public pageChanged(value) {
    this.pagination = value;

    this.refresh();
  }

  public buildingChanged(event) {
    this.pagination.page = 1;
    this.pagination.pageSize = 100;

    this.selectedBuilding = event;
    this.sessionService.building = event;

    this.refresh();

  }

  ngOnInit(): void {
    this.createText = this.translocoService.translate("admin.groupings.assets.create");

    this.spinnerService.activate();
    this.buildingService.getAll().subscribe(x => {
      this.spinnerService.desactivate();

      this.buildings = x.data;

      this.buildings.push({ _id: "", name: "All Buildings" });

      if (this.sessionService.building) {
        this.selectedBuilding = this.sessionService.building;
      }

      this.refresh();
    });
  }

  private refresh() {
    this.spinnerService.activate();
    this.pointsGroupService.pointGroups(1, this.pagination.page, this.pagination.pageSize, this.searchField, this.selectedBuilding, this.qrCodeLocked, this.points).subscribe(x => {
      this.spinnerService.desactivate();
      this.items = x.data;

      this.items.forEach(i => i.building = this.buildings.find(b => b._id == i.building))

      this.pagination.hasNext = x.data.length > this.pagination.pageSize;
    });
  }
}
