import { Component, OnInit, Directive, ViewChild } from '@angular/core';
import { SpinnerService } from 'app/services/spinner.service';
import { ReportService, MONTH_YEAR, MONTH, YEAR } from 'app/services/report.service';
import { BuildingService } from 'app/services/building.service';

import * as moment from "moment-timezone";

import { Moment } from 'moment-timezone';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { MatDatepicker } from '@angular/material/datepicker';
import { DriverService } from 'app/services/driver.service';
import { AdminPointService } from 'app/services/data.service';
import { deepStrictEqual } from 'assert';

import * as humanizeDuration from "humanize-duration"
import { GanttComponent } from 'app/components/common/highcharts/gantt/gantt.component';
import { AppService } from 'app/services/app.service';
import { GantAlarmsComponent } from 'app/components/common/gant-alarms/gant-alarms.component';
import { SweetalertService } from 'app/services/sweetalert.service';
import { SessionService } from 'app/services/session.service';

// const humanizeDuration = require("humanize-duration");

@Component({
  selector: 'app-admin-health',
  templateUrl: './admin-health.component.html',
  styleUrls: ['./admin-health.component.css']
})
export class AdminHealthComponent implements OnInit {

  @ViewChild("ganttchart") gantChart: GantAlarmsComponent;

  constructor(private sessionService: SessionService, private appService: AppService, private alertService: SweetalertService, private pointsService: AdminPointService, private spinnerService: SpinnerService, private reportService: ReportService, private buildingService: BuildingService, private driver: DriverService) { }

  public buildings: { name: string, id: string }[] = [];
  public selectedBuilding: string = "";

  public max = moment().endOf("month");

  public devices: { name: string, id: string }[] = [];
  public device: string = "";

  public points: { name: string, id: string, ord: string }[] = [];
  public point: string = "";

  public yearOnly: boolean = false;

  public nodeIds: { name: string, id: string }[] = [];
  public nodeId: string = "";

  public date = moment().endOf("month");

  public pageInfo: { pageSize: number, page: number, hasNext: boolean } = { pageSize: 10, page: 1, hasNext: false };

  public summary: { ord: string, integrityIssues: { name: string, num: string, months: number[] }[] }[] = [];

  public year: number = this.date.year();
  public month: number = this.date.month();

  public months = [];

  public chartsOptions = [];

  public possibleOrds: string[] = [];
  public selectedOrdsReport: string[] = [];


  public initSummary() {
    let t: { name: string, num: string, months: number[] }[] = [];

    for (let err of this.reportService.possibleAlarms) {
      t.push({
        months: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        name: this.reportService.getErrorType(err),
        num: err.toString()
      })
    }

    return t;
  }

  chosenMonthHandler(normalizedMonth: Moment, datepicker: MatDatepicker<Moment>) {
    this.month = normalizedMonth.month();
    datepicker.close();
    this.date = normalizedMonth;
  }

  chosenYearHandler(normalizedYear: Moment) {
    this.year = normalizedYear.year();
  }

  public displayMonth = false;

  ngOnInit() {

    for (let i = 0; i < 12; i++) {
      this.months.push(moment().month(i).format("MMMM"));
    }

    this.spinnerService.activate();
    this.buildingService.getAll().subscribe(x => {
      this.spinnerService.desactivate();

      this.buildings = x.data.map(t => {
        return {
          name: t.name,
          id: t._id
        }
      })

    })

    this.selectedBuilding = this.sessionService.building;
  }

  public onBuildingChanged() {
    this.spinnerService.activate();
    this.sessionService.building = this.selectedBuilding;
    this.driver.getDevices(this.selectedBuilding).subscribe(x => {
      this.devices = x.data.map(t => {
        return {
          name: t.friendlyName,
          id: t._id
        }
      })

      this.spinnerService.desactivate();
    })

    this.spinnerService.activate();
    this.pointsService.GetAllPointsWithMetadata(this.selectedBuilding).subscribe(x => {
      this.spinnerService.desactivate();

      this.points = x.data.map(t => {
        return {
          name: t.friendlyName,
          id: t._id,
          ord: t.ord
        }
      })
    })
  }

  public generateSummary() {


    this.displayMonth = false;
    this.summary = [];

    this.spinnerService.activate();
    this.reportService.getBMSHealthReport(this.selectedBuilding, null, null, null, this.year, null).subscribe(x => {
      this.spinnerService.desactivate();

      this.summary = [];

      x.integrityIssues.forEach(t => {
        let o = this.summary.find(y => y.ord == t.ord);

        if (o == undefined || o == null) {
          this.summary.push({
            ord: t.point.ord,
            integrityIssues: this.initSummary()
          });

          o = this.summary[this.summary.length - 1];
        }


        for (let key of this.reportService.possibleAlarms.map(x => { return x.toString() })) {

          if (t.integrityIssues[key] == undefined) {
            continue;
          }

          o.integrityIssues.find(d => d.num == key).months[t.month - 1] = t.integrityIssues[key];
        }
      })
    },
      err => {
        this.spinnerService.desactivate();
        this.alertService.info("Attenttion!", "Call support.");
      })
  }

  private initReport(ord) {
    this.chartsOptions.push({
      ord: ord,
      show: false,
      yAxis: { categories: this.reportService.possibleAlarms.map(t => this.reportService.getErrorType(t)) },
      tooltip: {
        outside: true
      },
      series: [{
        name: this.appService.convertFromASCII(ord.substr(ord.lastIndexOf("/") + 1, ord.length - 1)),
        data: []
      }],
      dataLabels: [{
        enabled: true,
        format: '<div style="width: 20px; height: 20px; overflow: hidden; border-radius: 50%; margin-left: -25px"></div>',
        useHTML: true,
        align: 'left'
      }, {
        enabled: true,
        format: '<i class="fa fa-{point.fontSymbol}" style="font-size: 1.5em"></i>',
        useHTML: true,
        align: 'right'
      }],
      // xAxis: {
      //   max: this.date.clone().endOf("month").valueOf(),
      //   min: this.date.clone().startOf("month").valueOf()
      // }
    })
  }

  public changeOrdReport(event) {
    if (event.value == "removeAll") {
      this.possibleOrds = Array.from(this.allOrdsReport);
      this.selectedOrdsReport = [];
    }
    else if (event.value == "addAll") {
      this.selectedOrdsReport = Array.from(this.allOrdsReport);
      this.possibleOrds = [];
    }
    else {
      this.selectedOrdsReport.push(event.value);
      this.possibleOrds.splice(this.possibleOrds.indexOf(event.value), 1);
    }
  }

  public gantChartOptions = {
    chart: {
      zoomType: "x"
    },
    yAxis: { uniqueNames: true, /*categories: []*/ },
    series: [],
    credits: {
      enabled: false
    },
    tooltip: {
      pointFormatter: function () {
        if (this.options.hide) {
          return;
        }
        return `<div style="font-size:12px;">
        <span style="font-weight:bold;">${this.name}</span><br>
        <span>Start: ${moment(this.x).format("DD/MM/YYYY HH:mm Z")}</span><br>
        <span>End: ${moment(this.x2).format("DD/MM/YYYY HH:mm Z")}</span> <br>
        <span>Duration: ${humanizeDuration(moment.utc(this.x2).diff(moment.utc(this.x)), { round: true })} </span>
        </div>`
      }
      // headerFormat: '<span style="font-size: 10px">{point.x} - {point.x2}</span><br/>',
      // pointFormat: '<span style="color:{point.color}">\u25cf</span> {series.name}: <b>{point.yCategory}</b><br/>',
      // headerFormat: '<span style="font-size: 10px">{point.key}</span><br/>',
      // pointFormat: '<span style="color:{point.color}">\u25cf</span> {series.name}: <b>{point.y}</b><br/>',
      // shared: true
    },
    xAxis: {
      max: (this.date.month() == moment.utc().month()) ? this.date.clone().valueOf() : this.date.clone().endOf("month").valueOf(),
      min: this.date.clone().startOf("month").valueOf()
    },
    time: { useUTC: false },
    plotOptions: {
      series: {
        turboThreshold: 99999,
      }
    }
  }

  //old one, dead
  public comparisonReport = {
    chart: {
      type: 'bar'
    },
    xAxis: {
      categories: []
    },
    yAxis: {
      min: 0
    },
    legend: {
      reversed: true
    },
    plotOptions: {
      turboThreshold: 0,
      series: {
        stacking: 'normal'
      }
    },
    navigator: {
      enabled: false
    },
    rangeSelector: {
      enabled: false
    },
    series: []
  }

  public removeOrdReport(event) {
    this.possibleOrds.push(event);
    this.selectedOrdsReport.splice(this.selectedOrdsReport.indexOf(event), 1);
  }

  public pagination = { page: 1, pageSize: 10, hasNext: false };

  public pageChange(event) {
    this.pagination.page = event.page;
    this.pagination.pageSize = event.pageSize;

    this.generateReport();
  }

  public summaryMonth: {
    downtime: string,
    responseTime: {
      avg: string,
      max: string,
      min: string,
      sd: string,
      sum: string
    }
  }

  // private generateOverlapsGaps(data, name, id, parent = null) {

  //   series.data.push({
  //     name: name,
  //     id: id,
  //     collapsed: true,
  //     color: "transparent",
  //     hide: true,
  //     parent: parent ? parent : undefined
  //   })

  //   let s = {
  //     name: name,
  //     start: series.data[startIndex].start,
  //     end: series.data[startIndex].end,
  //     color: series.data[startIndex].color,
  //     parent: (parent && deep) ? parent : undefined
  //   }

  // for (let i = startIndex; i < endIndex; i++) {

  //   if (i == endIndex - 1) {
  //     series.data.push(s);
  //   }
  //   else {
  //     if (this.overlapTimes(series.data[i].start, series.data[i].end, series.data[i + 1].start, series.data[i + 1].end)) {

  //       if (series.data[i].start > series.data[i + 1].start)
  //         s.start = series.data[i + 1].start;

  //       if (series.data[i].end < series.data[i + 1].end)
  //         s.end = series.data[i + 1].end;

  //       if (series.data[i + 1].color == "#ed5565")
  //         s.color = "#ed5565";
  //     }
  //     else {
  //       series.data.push(s);

  //       s = {
  //         name: name,
  //         start: series.data[i + 1].start,
  //         end: series.data[i + 1].end,
  //         color: series.data[i + 1].color,
  //         parent: (parent && deep) ? parent : undefined
  //       }
  //     }
  //   }

  // }
  // }

  // private generateGroups(series, indixes, name, idBase, arr, endMonth, refObj: { hasOpen: boolean, min: number, max: number }, parent = null) {
  //   for (let i of indixes) {

  //     let z = arr[i];

  //     let start = moment.utc(z.start).valueOf();
  //     let end = moment.utc(z.end).valueOf();

  //     if (z.end == null || z.end == undefined) {
  //       end = endMonth;
  //       refObj.max = endMonth;
  //     }

  //     if (refObj.min == 0) refObj.min = start;
  //     if (refObj.max == 0) refObj.max = end;

  //     if (start < refObj.min) refObj.min = start;
  //     if (end > refObj.max) refObj.max = end;

  //     series.data.push({
  //       start: start,
  //       id: idBase + i,
  //       name: name,
  //       end: end,
  //       color: z.end ? "#23c6c8" : "#ed5565",
  //       samples: z.samplesAffected,
  //       parent: parent ? parent : undefined
  //     })


  //     if (z.end == null || z.end == undefined) {
  //       refObj.hasOpen = true;
  //     }
  //   }
  // }

  private checkOverlaps(seriesData, data: { start, end, open }[], name, id, parent = null) {
    console.log(name);
    seriesData.push({
      name: name,
      id: id,
      collapsed: true,
      color: "transparent",
      hide: true,
      parent: parent ? parent : undefined
    })

    let overlaps: { start, end, open }[] = [];

    data.sort(function (a, b) { return a.start - b.start });

    let s = {
      name: name,
      start: data[0].start,
      end: data[0].end,
      color: data[0].open ? "#ed5565" : "#23c6c8",
      parent: parent ? parent : undefined
    }

    for (let i = 0; i < data.length; i++) {

      if (i == data.length - 1) {
        seriesData.push(s);
      }
      else {
        if (this.overlapTimes(s.start, s.end, data[i + 1].start, data[i + 1].end)) {

          if (s.start > data[i + 1].start)
            s.start = data[i + 1].start;

          if (s.end < data[i + 1].end)
            s.end = data[i + 1].end;

          if (data[i + 1].open)
            s.color = "#ed5565";
        }
        else {
          seriesData.push(s);

          s = {
            name: name,
            start: data[i + 1].start,
            end: data[i + 1].end,
            color: data[i + 1].open ? "#ed5565" : "#23c6c8",
            parent: parent ? parent : undefined
          }
        }
      }
    }

  }

  public data;
  public issuTypes;
  @ViewChild("ganttalarms") chartGantAlarms: GantAlarmsComponent;

  private allOrdsReport: string[] = [];
  private groupsIndexes: number[] = [];

  public generateReport() {
    this.displayMonth = true;
    this.summary = [];

    this.groupsIndexes = [];

    this.possibleOrds = [];
    this.selectedOrdsReport = [];

    this.spinnerService.activate();
    this.reportService.getBMSHealthReport(this.selectedBuilding, this.device, this.point, this.nodeId, this.year, this.month + 1, this.pagination.page, this.pagination.pageSize).subscribe(x => {
      this.chartsOptions = [];

      this.pagination.hasNext = x.integrityIssues.length >= this.pagination.pageSize;

      let endMonth = (this.date.month() == moment.utc().month()) ? moment.utc().valueOf() : this.date.clone().endOf("month").valueOf();

      this.issuTypes = x.issueTypes;
      this.data = x;
      this.data.date = this.date;

      this.mapData(this.data);
      this.gantChart.generateReport();

      // this.chartGantAlarms.generateReport();

      this.comparisonReport.series = this.reportService.possibleAlarms.map(f => {
        return {
          name: this.reportService.getErrorType(f),
          data: new Array(x.data.length).fill(0)
        }
      })
      this.comparisonReport.xAxis.categories = [];

      this.summaryMonth = {
        downtime: humanizeDuration(x.downtime[this.date.month() + 1] * 60 * 1000, { round: true }),
        responseTime: {
          avg: humanizeDuration(x.responseTime.avg * 60 * 1000, { round: true }),
          max: humanizeDuration(x.responseTime.max * 60 * 1000, { round: true }),
          min: humanizeDuration(x.responseTime.min * 60 * 1000, { round: true }),
          sd: humanizeDuration(x.responseTime.sd * 60 * 1000, { round: true }),
          sum: humanizeDuration(x.responseTime.sum * 60 * 1000, { round: true }),
        }
      }

      this.spinnerService.desactivate();
    }, err => {
      console.log(err);
      this.spinnerService.fullStop();
    })
  }

  private mapData(data) {
    this.gantData.series.data = [];
    this.gantData.date = this.date;

    // for (let issue of data.integrityIssues) {

    //   let open = false;

    //   for (let key of Object.keys(issue.integrityIssues)) {

    //     issue.integrityIssues[key].active ? open = true : open = open;

    //     let indexes = Object.keys(issue.integrityIssues[key].data);

    //     for (let i = 0; i < indexes.length; i++) {
    //       this.gantData.series.data.push({
    //         name: data.issueTypes[key],
    //         id: issue.point_id + "_" + key + "_" + i,
    //         parent: issue.displayOrd,
    //         start: this.date.clone().date(issue.day).add(parseInt(indexes[i]), "minutes").valueOf(),
    //         end: this.date.clone().date(issue.day).add(parseInt(indexes[i]) + issue.integrityIssues.sampleAffected, "minutes").valueOf(),
    //         color: open ? "#ed5565" : "#23c6c8"
    //       })
    //     }
    //   }

    //   let d = this.gantData.series.data.find(x => x.id == issue.point_id);
    //   if (!d) {
    //     d = {
    //       name: issue.displayOrd,
    //       id: issue.displayOrd,
    //       color: open ? "#ed5565" : "#23c6c8"
    //     };
    //     this.gantData.series.data.push(d)
    //   }

    // }

    for (let key of Object.keys(data.data)) {
      let open = false;

      for (let i = 0; i < data.data[key].length; i++) {
        let issue = data.data[key][i];

        issue.active ? open = true : open = open;

        this.gantData.series.data.push({
          name: data.issueTypes[parseInt(issue.type)],
          start: moment(issue.start).valueOf(),
          end: moment(issue.end).valueOf(),
          parent: key,
          color: issue.active ? "#ed5565" : "#23c6c8",
          id: key + "_" + issue.type + "_" + i
        })
      }

      this.gantData.series.data.push({
        name: key,
        id: key,
        color: open ? "#ed5565" : "#23c6c8"
      });
    }

    console.log(this.gantData);
  }


  @ViewChild("ganttchart") ganttchart: GantAlarmsComponent;
  public gantData: { series: any, proccessed: boolean, date: any } = { date: null, series: { name: "Occurrences", minPointLength: 10, id: "ocurrences", data: [] }, proccessed: true };

  public getOrdName(ord) {
    let t = this.appService.convertFromASCII(ord).split("/").slice(-3).join("/");
    return t;
  }

  public overlapTimes(start1: number, end1: number, start2: number, end2: number) {
    return (start1 >= start2 && start1 <= end2) || (end1 >= start2 && end1 <= end2) || (start2 >= start1 && start2 <= end1) || (end2 >= start1 && end2 <= end1)
  }
}

@Directive({
  selector: '[dateFormatMonthYear]',
  providers: [
    { provide: MAT_DATE_FORMATS, useValue: MONTH_YEAR },
  ],
})
export class FormatMonthYear {
}


@Directive({
  selector: '[dateFormatYear]',
  providers: [
    { provide: MAT_DATE_FORMATS, useValue: YEAR },
  ],
})
export class FormatYear {
}

@Directive({
  selector: '[dateFormatMonth]',
  providers: [
    { provide: MAT_DATE_FORMATS, useValue: MONTH },
  ],
})
export class FormatMonth {
}
