import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { HashLocationStrategy, LocationStrategy, PathLocationStrategy } from "@angular/common";

import { ROUTES } from "./app.routes";
import { AppComponent } from "./app.component";
// App views
import { ViewsModule } from "./views/views.module";
// App modules/components
import { LayoutsModule } from "./components/common/layouts/layouts.module";
// App guards
import { AuthGuard } from "app/guards/auth.guard";
// App services
import { UserService } from "./services/user.service";
import { CompanyService } from "./services/company.service";
import { BuildingService } from "./services/building.service";
import { AppI18nService } from "app/services/app.i18n.service";
import { LoginService } from "app/services/login.service";
import { AppService } from "./services/app.service";
import { BaseRequestOptions } from "@angular/http";
import { SpinnerService } from "./services/spinner.service";
import { SweetalertService } from "./services/sweetalert.service";
import { MockBackend } from "@angular/http/testing";
import { AgmCoreModule } from "@agm/core";
import { HttpClientModule } from "@angular/common/http";
import { QRCodeModule } from "angular2-qrcode";
import { AdminPointService } from "./services/data.service";
import { ApiKeyService } from "app/services/apiKey.service";

import { MAT_MOMENT_DATE_FORMATS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { ValidatorsService } from "./services/validators.service";
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DriverService } from './services/driver.service';
import { VersionCheckService } from './services/version-checker.service';
import { ReportService } from './services/report.service';
import { ErrorHandlerModule } from "./components/error_handler/error-handler.module";
import { MatSnackBar } from "@angular/material/snack-bar";
import { QrPopupModule } from "./components/common/qr-popup/qr-popup.module";
import { QrPrintModule } from "./components/common/qr-print/qr-print.module";
import { TranslocoRootModule } from './transloco/transloco-root.module';
import { PointFilterComponent } from './components/common/point-filter/point-filter.component';

@NgModule({
  declarations: [
    AppComponent,
    PointFilterComponent
  ],
  imports: [
    QrPrintModule,
    QrPopupModule,
    ErrorHandlerModule,
    QRCodeModule,
    BrowserModule,
    FormsModule,
    LayoutsModule,
    ViewsModule,
    RouterModule.forRoot(ROUTES,
      {
        // initialNavigation: false,
        // enableTracing: true
      }
    ),
    AgmCoreModule.forRoot({
      apiKey: "AIzaSyD-xGH9oUUgpkhiY-LpbffO0kb_TWHqh-I"
    }),
    HttpClientModule,
    BrowserAnimationsModule,
    TranslocoRootModule
  ],
  providers: [
    {
      provide: LocationStrategy,
      useClass: PathLocationStrategy
    },
    { provide: MAT_DATE_LOCALE, useValue: "en-GB" },
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },

    AuthGuard,
    UserService,
    CompanyService,
    ApiKeyService,
    BuildingService,
    LoginService,
    AppI18nService,
    AppService,
    SpinnerService,
    SweetalertService,
    AdminPointService,
    ValidatorsService,
    DriverService,
    VersionCheckService,
    ReportService,
    MatSnackBar
  ],
  bootstrap: [AppComponent]
})

export class AppModule {
}

