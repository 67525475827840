import { Component, OnInit, ViewChild } from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { AppService } from "app/services/app.service";
import { AppI18nService } from "app/services/app.i18n.service";
import { SpinnerService } from "app/services/spinner.service";
import { SweetalertService } from "app/services/sweetalert.service";
import { ActivatedRoute } from "@angular/router";
import { ApiKeyService } from "../../../services/apiKey.service";
import { FormGroup } from '@angular/forms';
import { BuildingService } from 'app/services/building.service';
import { QrPopupComponent } from "app/components/common/qr-popup/qr-popup.component";
import { MatDialog } from "@angular/material/dialog";
import { TOUCH_BUFFER_MS } from "@angular/cdk/a11y";
import { SessionService } from "app/services/session.service";
import { QueryparamsService } from "app/services/queryparams.service";

@Component({
  selector: "app-admin-buildings",
  templateUrl: "./admin-apiKey.component.html",
  styleUrls: ["./admin-apiKey.component.css"]
})

export class AdminApiKeyComponent implements OnInit {

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  public dataSource = new MatTableDataSource<any>();

  public hasKeys = true;

  public displayedColumns: string[];

  constructor(private queryService: QueryparamsService, private sessionService: SessionService, private buildingService: BuildingService, public dialog: MatDialog, private route: ActivatedRoute, public appService: AppService, public i18n: AppI18nService,
    private spinnerService: SpinnerService, private alert: SweetalertService, private apiKeyService: ApiKeyService) {
  }

  public companyToken: string = "";
  public pagination: { hasNext: boolean, page: number, pageSize: number } = { hasNext: false, page: 1, pageSize: 100 }
  public changePage(event) {

    this.pagination.page = event.page;
    this.pagination.pageSize = event.pageSize;

    this.refresh();
  }

  public filter: string = "";
  public applyFilter(value) {
    this.filter = value;
    this.pagination.page = 1;

    this.refresh();
  }

  public building;
  public buildings = [];

  public refresh() {
    this.spinnerService.activate();
    this.sessionService.building = this.building;

    this.queryService.ReplaceParams({
      building: this.building,
      page: this.pagination.page,
      pageSize: this.pagination.pageSize,
      filter: this.filter
    }).subscribe(params => {

    })

    this.apiKeyService.getAll(`building=${this.building}`, { page: this.pagination.page, pageSize: this.pagination.pageSize }, this.filter).subscribe(
      results => {

        this.companyToken = results.companyToken;
        this.pagination.hasNext = results.data.length > this.pagination.pageSize;
        results.data = results.data.map(x => {
          if (x.allPoints) {
            x.points = ["All points"]
          }
          return x
        });
        console.log(results.data);
        this.dataSource.data = results.data;

        this.hasKeys = results.data.length > 0;

        this.spinnerService.desactivate();
      },
      err => {
        this.spinnerService.desactivate();
        console.log(err);
      }
    );
  }

  public deleteOne(id) {
    this.spinnerService.activate();
    this.apiKeyService.delete(id).subscribe(x => {
      this.spinnerService.desactivate();

      this.alert.success("Success!", "API key was deleted.");

      this.refresh();
    })
  }

  public deleteAll() {
    this.spinnerService.activate();
  }

  ngOnInit() {

    this.displayedColumns = ["description", "expiration_date", "linkedIPAddress", "points", "apiKey"];

    this.spinnerService.activate();
    this.buildingService.getAll().subscribe(x => {
      this.buildings = x.data;

      this.spinnerService.desactivate();

      this.queryService.hasParams().subscribe(check => {
        if (check) {
          this.queryService.params().subscribe(params => {
            this.building = params["building"];
            this.filter = params["filter"];
            this.pagination.page = parseInt(params["page"]);
            this.pagination.pageSize = parseInt(params["pageSize"]);

            this.refresh();
          })
        }
        else {
          this.building = this.sessionService.building;

          if (this.building)
            this.refresh();
        }
      })
    })
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  public openQR(value, label) {
    const dialogRef = this.dialog.open(QrPopupComponent, {
      width: '980px',
      data: {
        qr: [{ inputString: this.companyToken, label: "Company Token" }, { inputString: value, label: "Building Token / API Token:" + label }],
        qrSize: 300
      }
    });
  }

  public newToken(id) {
    this.spinnerService.activate();

    this.apiKeyService.newToken(id).subscribe(
      result => {
        this.spinnerService.desactivate();

        this.alert.success("Success!", "New token generated.");

        for (let d of this.dataSource.data) {
          if (d._id == id) {
            d.apiKey = result.data.apiKey;
          }
        }
        this.spinnerService.desactivate();
      },
      err => {
        this.spinnerService.desactivate();
        console.log(err);
      }
    );
  }
}
