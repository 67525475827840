import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-admin-devices-form',
  templateUrl: './admin-devices-form.component.html',
  styleUrls: ['./admin-devices-form.component.css']
})
export class AdminDevicesFormComponent implements OnInit {

  public form: FormGroup = new FormGroup({
    friendlyName: new FormControl("", [Validators.required]),
    hostID: new FormControl("", [Validators.required]),
    building: new FormControl("", [Validators.required]),
    autoGenerateTags: new FormControl(true)
  })

  public buildings: {id: string, name: string}[] = [];

  public title = "New Device";

  public onSubmit(){
    this.dialogRef.close(this.form.value);
  }

  constructor(public dialogRef: MatDialogRef<AdminDevicesFormComponent>, @Inject(MAT_DIALOG_DATA) public data) { }

  ngOnInit() {
    this.buildings = this.data.buildings;
    if(this.data.selectedBuilding){
      this.form.get("building").setValue(this.data.selectedBuilding);
    }

    if(this.data.device){
      this.title = "Update Device";

      this.form.get("building").disable();

      this.form.get("autoGenerateTags").setValue(this.data.device.generateTags)
      this.form.get("autoGenerateTags").disable();

      this.form.get("hostID").setValue(this.data.device.hostID);
      // this.form.get("hostID").disable();

      this.form.get("friendlyName").setValue(this.data.device.friendlyName);

    }
  }

  public close(){
    this.dialogRef.close(false);
  }

}
