import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AdvancedSearchComponent } from './advanced-search.component';
import { MatTreeModule } from '@angular/material/tree';
import { AdvancedSearchQueryComponent } from './advanced-search-query/advanced-search-query.component';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { FormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';



@NgModule({
  declarations: [AdvancedSearchComponent, AdvancedSearchQueryComponent],
  imports: [
    CommonModule,
    MatTreeModule,
    MatIconModule,
    MatSelectModule,
    FormsModule,
    MatInputModule
  ],
  exports:[
    AdvancedSearchComponent
  ]
})
export class AdvancedSearchModule { }
