import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { UserService } from 'app/services/user.service';
import { AppService } from 'app/services/app.service';
import { AppI18nService } from 'app/services/app.i18n.service';
import { SpinnerService } from 'app/services/spinner.service';
import { SweetalertService } from 'app/services/sweetalert.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-root-users',
  templateUrl: './root-users.component.html',
  styleUrls: ['./root-users.component.css']
})
export class RootUsersComponent implements OnInit, AfterViewInit {

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  public dataSource = new MatTableDataSource();

  public displayedColumns: string[];

  constructor(public userService: UserService, private appService: AppService, public i18n: AppI18nService,
    private spinnerService: SpinnerService, private alert: SweetalertService) {
  }

  ngOnInit() {
    this.appService.sectionName = this.i18n.translate('general.sections.user.users');

    this.displayedColumns = ['name','company', 'contactPhone', 'contactEmail', 'type'];

    // merge(this.sort.sortChange, this.paginator.page).pipe()

    this.spinnerService.activate();
    this.userService.getAll('refQuery=' + encodeURIComponent(JSON.stringify([{ "path": "company" }])), undefined, undefined, undefined).subscribe(
      results => {
        // console.log(results);
        this.dataSource.data = results.data.map(x => {
          return {
            id: x._id,
            username: x.username,
            company: (x.company ? x.company.name : ""),
            type: x.type,
            landline:  (x.person.landline ? x.person.landline.number : "") + " " + (x.person.landline ? x.person.landline.internalExtension : ""),
            mobile: x.person.mobilePhone,
            email: x.email,
            contactPhones: x.person.contacts.telephones,
            contactEmails: x.person.contacts.emails,
            name: x.person.name
          }
        });
        this.spinnerService.desactivate();
      },
      err => {
        this.spinnerService.desactivate();
        //this.alert.error(this.i18n.translate('general.errors.error'), this.i18n.translate('general.errors.unkwonError', err));
      }
    );
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }


  public applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
    this.dataSource.filter = filterValue;
  }

}
