import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { QrPopupComponent } from './qr-popup.component';
import { MyQRCodeModule } from '../QRCode/qr-code.module';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';



@NgModule({
  declarations: [
    QrPopupComponent
  ],
  exports:[
    QrPopupComponent
  ],
  imports: [
    CommonModule,
    MyQRCodeModule,
    MatIconModule,
    MatTooltipModule,
    RouterModule
  ]
})
export class QrPopupModule { }
