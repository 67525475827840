export interface Contact {
  emails: string[];
  telephones: {
    number: string,
    internalExtension?: string,
    type: PhoneType
  }[],
}

export enum PhoneType {
  Mobile = "mobile",
  Landline = "landline"
}
