import { Component, OnInit } from "@angular/core";
import { AppI18nService } from "app/services/app.i18n.service";
import { IBuilding } from "app/interfaces/building";
import { LoginService } from "app/services/login.service";
import { BuildingService } from "app/services/building.service";
import { SpinnerService } from "app/services/spinner.service";
import { SweetalertService } from "app/services/sweetalert.service";
import { Router } from "@angular/router";
import { FileUploadService } from 'app/services/file-upload.service';

@Component({
  selector: "app-admin-create-buildings",
  templateUrl: "./admin-create-buildings.component.html",
  styleUrls: ["./admin-create-buildings.component.css"]
})
export class AdminCreateBuildingsComponent implements OnInit {

  constructor(private router: Router, private spinner: SpinnerService, private buildingService: BuildingService,
    public i18n: AppI18nService, private loginService: LoginService, private alert: SweetalertService, private uploadService: FileUploadService) {
  }

  ngOnInit() {
  }

  private return(id) {
    this.alert.success(this.i18n.translate("general.messages.success"),
      this.i18n.translate("general.messages.created", this.i18n.translate("interfaces.building.building")));
    this.router.navigate(["admin", "buildings"]);
  }

  public save(event) {
    let values = event.value;

    let building = {
      address: values.address,
      butterWorthRecoveryMethod: values.butterWorthRecoveryMethod,
      company: this.loginService.userInfo.company,
      contacts: values.contact,
      description: values.description,
      name: values.name,
      timeZone: values.timeZone
    };

    this.spinner.activate();
    this.buildingService.create(building).subscribe(
      x => {
        if (values.photo) {
          this.buildingService.getUploadURL(x.data._id).subscribe(res => {
            let url = res.data.uploadURL;
            console.log(url);

            this.uploadService.upload(values.photo, url).subscribe(up => {
              this.spinner.desactivate();
              this.return(x.data._id);
            })
          })
        }
        else {

          this.spinner.desactivate();
          this.return(x.data._id);
        }


      }
    );
  }

}
