import { Component, OnInit } from "@angular/core";
import { AppI18nService } from "app/services/app.i18n.service";
import { Router } from "@angular/router";
import { LoginService, NewPasswordUser } from "app/services/login.service";
import { SweetalertService } from "app/services/sweetalert.service";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { AppService } from "app/services/app.service";
import { SpinnerService } from "app/services/spinner.service";

@Component( {
  selector: "app-change-password",
  templateUrl: "./change-password.component.html",
  styleUrls: [ "./change-password.component.css" ]
} )

export class ChangePasswordComponent implements OnInit {
  private registrationUser: any;

  constructor( private spinner: SpinnerService, public i18n: AppI18nService,
    private router: Router, public loginService: LoginService, private alert: SweetalertService, public appService: AppService ) {
  }

  /*
   ^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*(_|[^\w])).{6,}+$
   */

  public form: FormGroup = new FormGroup( {
    username: new FormControl( "", [ Validators.required ] ),
    oldPassword: new FormControl( "", [ Validators.required ] ),
    newPassword: new FormControl( "", [ Validators.required ] ),
    newPasswordConfirmation: new FormControl( "", [ Validators.required, validatePassword ] )
  } );

  ngOnInit() {
    this.registrationUser = new NewPasswordUser();

    // this.loginService.isAuthenticated(this);

    if( this.loginService.params ) {
      this.form.patchValue( { username: this.loginService.params.username } );
      this.form.patchValue( { oldPassword: this.loginService.params.password } );
    }

    this.form.controls[ "newPassword" ].valueChanges.subscribe!( {
      next: ( value ) => {
        this.form.controls[ "newPasswordConfirmation" ].updateValueAndValidity();
      }
    } );
  }

  public onSubmit() {
    this.spinner.activate();
    this.registrationUser = {
      existingPassword: this.form.value.oldPassword,
      password: this.form.value.newPassword,
      username: this.form.value.username
    };
    this.loginService.changePassword( this.registrationUser ).subscribe(
      result => {
        this.loginService.params = undefined;
        this.alert.success( this.i18n.translate( "general.messages.success" ), this.i18n.translate( "general.messages.passwordChanged" ) );

        let userInfo = this.loginService.setUserInfo( result );

        let type = userInfo.type;
        if( type === "root" ) {
          this.router.navigate( [ "/root/" ] );
        } else if( type === "admin" ) {
          this.router.navigate( [ "/admin/" ] );
        } else if( type === "employee" ) {
          this.router.navigate( [ "/employee/" ] );
        }
        this.spinner.desactivate();
      },
      err => {
        console.log( "ChangePassword: Error " );
        console.log( err );
        this.alert.info( this.i18n.translate( "general.errors.error" ), this.i18n.translate( "general.errors.invalidUsernamePassword" ) );
        this.spinner.desactivate();
      }
    );
  }
}

export function validatePassword( f: FormControl ) {
  if( f.parent ) {
    return f.parent.controls[ "newPassword" ].value == f.parent.controls[ "newPasswordConfirmation" ].value ? null :
      { validatePassword: { valid: false } };
  }
  return undefined;
}
