import { AfterViewInit, Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Location } from "@angular/common";
import { AdminPointService, IMetadata } from "../../../../services/data.service";
import { SelectItem } from "../../../../components/common/appSelect/select.item.interface";
import { SpinnerService } from "../../../../services/spinner.service";
import { AppI18nService } from "../../../../services/app.i18n.service";
import { AppService } from "../../../../services/app.service";
import { CompanyService } from "app/services/company.service";
import { LoginService } from "app/services/login.service";
import { SweetalertService } from "../../../../services/sweetalert.service";
import { FormGroup, FormControl, Validators, FormArray } from '@angular/forms';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { DATEPICKER_FORMAT } from 'app/components/adapters/DatepickerFormats';
import { MatDialog } from "@angular/material/dialog";
import { SearchPopupComponent } from "app/components/common/search-popup/search-popup.component";
import { Observable } from "rxjs";
import { BuildingService } from "app/services/building.service";

@Component({
  selector: "metadata-edit",
  templateUrl: "metadata.edit.template.html",
  styleUrls: ["./metadata.edit.component.css"],
  providers: [
    { provide: MAT_DATE_FORMATS, useValue: DATEPICKER_FORMAT }
  ]
})

export class AdminMetadataEditComponent implements OnInit, AfterViewInit {

  public form = new FormGroup({
    accuracy: new FormControl(),
    dateInstalled: new FormControl(),
    friendlyName: new FormControl(),
    manufacturer: new FormControl(),
    nonZeroDataNotification: new FormControl(),
    subscribedPoint: new FormControl(),
    maxValue: new FormControl(),
    minValue: new FormControl(),
    model: new FormControl(),
    pointName: new FormControl("", [Validators.required]),
    resolution: new FormControl(),
    tags: new FormArray([]),
    unit: new FormControl(),
    unitType: new FormControl(),
    building: new FormControl("", [Validators.required]),
    pointType: new FormControl(),
    meterType: new FormControl(),
    approximation: new FormControl(),
    dataStaleAlarm: new FormControl(),
    inMaintenance: new FormControl(),
    serialNumber: new FormControl(),
    displayOrd: new FormControl()
  });

  public printQR() {
    window.print();
  }

  typeChanged(item) {
    if (item.value != null && item.value != undefined) {
      this.units = [];

      // this.point.unit = "";
      // this.point.unitType = item.id;

      this.form.get('unit').setValue("");
      this.form.get("unitType").setValue(item.value);


      if (this.form.get('unitType').value) {
        for (let u of this.unitTypes[<any>this.form.get('unitType').value]) {
          this.units.push({ id: u, text: u });
        }
      }
    } else if (item.id != this.form.get("unitType").value) {
      this.units = [];
      this.form.get('unit').setValue("");
    }
  }

  // public unitChanged( item ) {
  //   this.point.unit = item.id;
  // }

  // public meterTypeChanged( event ) {
  //   this.point.meterType = event.id;
  // }
  // public meterApproximationChanged( event ) {
  //   this.point.approximation = event.id;
  // }

  public newTag: string = "";
  public linkedPoint = "";
  public ord = "";
  public pointName = "";
  public originalPoint = undefined;
  public point: IMetadata = {};

  public units: SelectItem[] = [];
  public types: SelectItem[] = [];
  public meterTypes: SelectItem[] = [];
  public approximationOptions: SelectItem[] = [
    { id: "average", text: "average" },
    { id: "high", text: "highest" },
    { id: "low", text: "lowest" },
    { id: "sum", text: "sum" }
  ];

  public selectedType;
  public selectedUnit;
  public selectedMeterType;
  public selectedApproximation;
  private unitTypes = {};


  public GetDateString(date) {
    return this.appService.GetDateString(date);
  }

  constructor(private buildingService: BuildingService, public dialog: MatDialog, public alert: SweetalertService, private appService: AppService, private dataService: AdminPointService, private route: ActivatedRoute,
    private location: Location, private spinner: SpinnerService, private router: Router, public i18n: AppI18nService,
    private companyService: CompanyService, private loginService: LoginService, private activatedRoute: ActivatedRoute) {


  }

  public refreshPoints(filter: string, pageOptions: { page: number, pageSize: number }): Observable<any> {
    return new Observable(observer => {

      console.log(this.form.get("building").value);

      this.spinner.activate();
      this.buildingService.metadata(this.form.get("building").value, filter, pageOptions.page, pageOptions.pageSize).subscribe(res => {
        this.spinner.desactivate();



        observer.next(res.data);
        observer.complete();
      })
    })
  }

  public opentAddPoint() {
    const dialogRef = this.dialog.open(SearchPopupComponent, {
      width: '960px',
      data: {
        single: true,
        searchCallback: this.refreshPoints.bind(this),
        displayField: "ord",
        valueField: "_id"
      }
    });

    dialogRef.afterClosed().subscribe(ret => {
      this.form.get("inMaintenance").setValue(ret._id);
      this.linkedPoint = ret.friendlyName ? ret.friendlyName : ret.ord;
      this.ord = ret.ord;
    });
  }

  public removeLinked() {

    this.form.get("inMaintenance").setValue("");
    this.linkedPoint = "";
  }


  pointTypes = ["Meter", "Temperature", "CO2", "Other"];

  ngAfterViewInit() {

    this.spinner.activate();
    this.companyService.getOne(this.loginService.userInfo.company).subscribe(
      companyRes => {
        if (companyRes.data.tags && companyRes.data.tags.length > 0) {
          this.groupedTags = [
            {
              name: "Used Tags", listOfTags: companyRes.data.tags.sort((a, b) => {
                return a.toLowerCase() < b.toLowerCase() ? -1 : 1;
              })
            }
          ].concat(this.groupedTags);
          this.filterTags();
        }

        this.dataService.GetUnits().subscribe(
          resp => {
            let i = 0;
            for (let type of resp["data"]["UnitList"]) {
              this.types.push({ id: type["type"], text: type["type"] });
              this.unitTypes[type["type"]] = type["units"];

              if (this.originalPoint)
                this.typeChanged({ value: this.originalPoint.unitType });
            }

            for (let type in this.appService.meterImages) {
              this.meterTypes.push({ id: type, text: type });
            }
            console.log(this.meterTypes);
            this.filterTags();

            this.route.params.subscribe(
              params => {

                this.route.queryParams.subscribe(query => {
                  this.dataService.GetPointMetadata(query["building"], params["id"]).subscribe(
                    data => {

                      let pointData = data["data"];
                      if (!pointData) {
                        pointData = {};
                      }
                      this.originalPoint = Object.assign({}, pointData);

                      this.form.get('pointName').setValue(this.originalPoint.pointName);

                      if (this.originalPoint.ord) {
                        this.form.get('pointName').disable();
                      }
                      this.form.get('friendlyName').setValue(this.originalPoint.friendlyName);

                      this.form.get("nonZeroDataNotification").setValue(this.originalPoint.nonZeroDataNotification);
                      this.form.get("subscribedPoint").setValue(this.originalPoint.subscribedPoint);

                      this.form.get('dateInstalled').setValue(this.originalPoint.dateInstalled ?
                        new Date(this.originalPoint.dateInstalled).toISOString().slice(0, 10) :
                        new Date().toISOString().slice(0, 10));
                      this.form.get('model').setValue(this.originalPoint.model);
                      this.form.get('building').setValue(this.originalPoint.building);
                      this.form.get('manufacturer').setValue(this.originalPoint.manufacturer);
                      this.form.get('serialNumber').setValue(this.originalPoint.serialNumber);
                      this.form.get('displayOrd').setValue(this.originalPoint.displayOrd);
                      this.form.get('displayOrd').disable();
                      this.form.get('resolution').setValue(this.originalPoint.resolution);
                      this.form.get('accuracy').setValue(this.originalPoint.accuracy);
                      this.form.get('minValue').setValue(this.originalPoint.minValue);
                      this.form.get('maxValue').setValue(this.originalPoint.maxValue);
                      this.form.get('unitType').setValue(this.originalPoint.unitType);
                      // this.typeChanged({value: this.originalPoint.unitType});


                      this.form.get("dataStaleAlarm").setValue(this.originalPoint.dataStaleAlarm);
                      this.form.get("dataStaleAlarm").valueChanges.subscribe(k => {
                        if (k == false) {
                          this.removeLinked();
                        }
                      });

                      this.form.get("inMaintenance").setValue(this.originalPoint.inMaintenance);
                      this.form.get('pointType').setValue(this.originalPoint.pointType);

                      this.form.get('meterType').setValue(this.originalPoint.meterType);
                      this.form.get('approximation').setValue(this.originalPoint.approximation || "Average");

                      if (this.originalPoint.tags) {
                        for (let tag of this.originalPoint.tags) {
                          (this.form.get('tags') as FormArray).push(new FormControl(tag));
                        }
                      }

                      if (this.originalPoint.unitType) {
                        for (let u of this.unitTypes[<any>this.originalPoint.unitType]) {
                          this.units.push({ id: u, text: u });
                        }
                        this.form.get('unit').setValue(this.originalPoint.unit);
                      }


                      // if (this.form.get("pointType").value == "Meter") {
                      //   this.form.get("serialNumber").enable();
                      // }
                      // else {
                      //   this.form.get("serialNumber").disable();
                      // }

                      this.form.get("pointType").valueChanges.subscribe(x => {
                        if (x == "Meter")
                          this.form.get("serialNumber").enable();
                        else
                          this.form.get("serialNumber").disable();
                      })

                      this.spinner.desactivate();

                    }
                  );

                })
              }
            );
          }
        );



      }
    );
  }

  ngOnInit() {


  }

  public goBack() {
    this.router.navigate([".."], { relativeTo: this.activatedRoute, queryParams: { building: this.form.get('building').value } });
  }

  public add(event) {
    // this.point.tags.push(event.option.value);
    (this.form.get('tags') as FormArray).push(new FormControl(event.option.value))
    this.newTag = "";
    this.filterTags();
  }

  public addCustom() {
    // this.point.tags.push(this.newTag);
    (this.form.get('tags') as FormArray).push(new FormControl(this.newTag))
    this.newTag = "";

  }

  public removeTag(i) {
    (this.form.get('tags') as FormArray).removeAt(i);
  }

  public submit() {
    console.log(this.form.value);
    this.spinner.activate();
    this.dataService.SetMetadata(this.originalPoint, this.form.value).subscribe(
      resp => {
        console.log("Point metadata updated!");
        this.spinner.desactivate();
        this.goBack();
      },
      erro => {
        console.log(erro);
        if(erro.error && erro.error.errors)
        this.alert.info(this.i18n.translate("general.notice.notice"), erro.error.errors.map(x => x.message).join("\n"));
        else
        this.alert.info(this.i18n.translate("general.notice.notice"), "Unexpected error. Please contact support.")
        this.spinner.desactivate();
      }
    );

  }

  get tagsForm() {
    return (<FormArray>this.form.get('tags')).controls;
  }

  public filterTags() {
    for (let group of this.groupedTags) {
      group.filteredTags = group.listOfTags.filter(tag => tag.toLowerCase().indexOf(this.newTag.toLowerCase()) >= 0);
    }
  }

  public groupedTags: any[] = [
    {
      name: "haystack", listOfTags: [
        "absorption",
        "ac",
        "active",
        "ahu",
        "ahuRef",
        "air",
        "airCooled",
        "angle",
        "apparent",
        "area",
        "avg",
        "barometric",
        "blowdown",
        "boiler",
        "bypass",
        "centrifugal",
        "chilled",
        "chilledBeamZone",
        "chilledWaterCool",
        "chilledWaterPlant",
        "chilledWaterPlantRef",
        "chiller",
        "circ",
        "circuit",
        "closedLoop",
        "cloudage",
        "cmd",
        "co",
        "co2",
        "coldDeck",
        "condensate",
        "condenser",
        "connection",
        "constantVolume",
        "cool",
        "coolOnly",
        "cooling",
        "coolingCapacity",
        "coolingTower",
        "cur",
        "curErr",
        "curStatus",
        "curVal",
        "current",
        "damper",
        "dc",
        "delta",
        "device",
        "device1Ref",
        "device2Ref",
        "dew",
        "directZone",
        "direction",
        "dis",
        "discharge",
        "diverting",
        "domestic",
        "dualDuct",
        "ductArea",
        "dxCool",
        "effective",
        "efficiency",
        "elec",
        "elecHeat",
        "elecMeterLoad",
        "elecMeterRef",
        "elecPanel",
        "elecPanelOf",
        "elecReheat",
        "enable",
        "energy",
        "entering",
        "enum",
        "equip",
        "equipRef",
        "evaporator",
        "exhaust",
        "export",
        "faceBypass",
        "fan",
        "fanPowered",
        "fcu",
        "filter",
        "flow",
        "flue",
        "freezeStat",
        "freq",
        "gas",
        "gasHeat",
        "gasMeterLoad",
        "geoAddr",
        "geoCity",
        "geoCoord",
        "geoCountry",
        "geoCounty",
        "geoPostalCode",
        "geoState",
        "geoStreet",
        "header",
        "heat",
        "heatExchanger",
        "heatPump",
        "heatWheel",
        "heating",
        "his",
        "hisErr",
        "hisInterpolate",
        "hisStatus",
        "hisTotalized",
        "hot",
        "hotDeck",
        "hotWaterHeat",
        "hotWaterPlant",
        "hotWaterPlantRef",
        "hotWaterReheat",
        "humidifier",
        "humidity",
        "hvac",
        "id",
        "imbalance",
        "import",
        "irradiance",
        "isolation",
        "kind",
        "leaving",
        "level",
        "lightLevel",
        "lighting",
        "lights",
        "lightsGroup",
        "load",
        "mag",
        "makeup",
        "mau",
        "max",
        "maxVal",
        "meter",
        "min",
        "minVal",
        "mixed",
        "mixing",
        "multiZone",
        "net",
        "network",
        "networkRef",
        "neutralDeck",
        "occ",
        "occupancyIndicator",
        "occupied",
        "oil",
        "openLoop",
        "outside",
        "parallel",
        "perimeterHeat",
        "pf",
        "phase",
        "point",
        "power",
        "precipitation",
        "pressure",
        "pressureDependent",
        "pressureIndependent",
        "primaryFunction",
        "primaryLoop",
        "protocol",
        "pump",
        "reactive",
        "reciprocal",
        "refrig",
        "reheat",
        "reheating",
        "return",
        "rooftop",
        "run",
        "screw",
        "secondaryLoop",
        "sensor",
        "series",
        "singleDuct",
        "site",
        "siteMeter",
        "sitePanel",
        "siteRef",
        "solar",
        "sp",
        "speed",
        "stage",
        "standby",
        "steam",
        "steamHeat",
        "steamMeterLoad",
        "steamPlant",
        "steamPlantRef",
        "subPanelOf",
        "submeterOf",
        "sunrise",
        "tank",
        "temp",
        "thd",
        "total",
        "tripleDuct",
        "tz",
        "unit",
        "unocc",
        "uv",
        "valve",
        "variableVolume",
        "vav",
        "vavMode",
        "vavZone",
        "vfd",
        "visibility",
        "volt",
        "volume",
        "water",
        "waterCooled",
        "waterMeterLoad",
        "weather",
        "weatherCond",
        "weatherPoint",
        "weatherRef",
        "wetBulb",
        "wind",
        "writable",
        "writeErr",
        "writeLevel",
        "writeStatus",
        "writeVal",
        "yearBuilt",
        "zone"
      ]
    }
  ];
}
