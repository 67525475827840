import { AfterViewInit, ChangeDetectorRef, Component, OnInit, ViewEncapsulation } from "@angular/core";
import { AdminPointService } from "../../../services/data.service";
import { AppService } from "../../../services/app.service";
import { SpinnerService } from "../../../services/spinner.service";
import { ActivatedRoute } from "@angular/router";
import * as momentImport from "moment-timezone";
import { LoginService } from "../../../services/login.service";
import { MapPopupComponent } from 'app/components/common/map-popup/map-popup.component';
import { MatDialog } from '@angular/material/dialog';

import * as moment from "moment-timezone";
import { FutureDecisionsDate } from "app/components/classes/futuredecisions.date";
import { TranslocoService } from "@ngneat/transloco";
import { HelpPageService } from "app/services/help-page.service";

@Component({
  selector: "app-admin-dashboard",
  templateUrl: "./admin-dashboard.component.html",
  styleUrls: ["./admin-dashboard.component.css"],
  encapsulation: ViewEncapsulation.None
})
export class AdminDashboardComponent implements OnInit, AfterViewInit {

  private company: string = undefined;
  public buildings: any[] = [];
  public moment = momentImport;

  constructor(private helppageService: HelpPageService, public dialog: MatDialog, public dataService: AdminPointService, private appService: AppService, public loginService: LoginService,
    private spinnerService: SpinnerService, private translocoService: TranslocoService, private route: ActivatedRoute) {
    route.params.subscribe(params => {
      this.company = params["company"];
    });
  }

  ngAfterViewInit(): void {
    this.helppageService.changeUrl("./assets/help-pages/dashboard.html?language=" + this.appService.getLanguage());
  }



  public CompanyPointSummary;

  public openMap(id) {
    let building = this.buildings.find(x => x._id == id);
    building.address.lat
    let data = {
      lat: building.address.lat,
      lng: building.address.lng,
      textDescription: building.address.street + " " + building.address.number + ", " + building.address.city + " - " + building.address.country
    }


    const dialogRef = this.dialog.open(MapPopupComponent, {
      width: '600px',
      data: data
    });
  }

  public pagination: { hasNext: boolean, page: number, pageSize: number } = { hasNext: false, page: 1, pageSize: 100 }
  public changePage(event) {
    console.log(event);

    this.pagination.page = event.page;
    this.pagination.pageSize = event.pageSize;

    this.updateData();
  }


  private updateData() {
    this.spinnerService.activate();
    this.dataService.GetDashboardInfo(this.company, this.pagination.page, this.pagination.pageSize).subscribe(
      results => {
        this.buildings = [];
        this.CompanyPointSummary = results.CompanyPointSummary;
        for (let building of results.data) {

          if (building.pointSummary) {

            building.pointsTooltip = building.pointSummary.total + " Total Points\n" + building.pointSummary.linked + " Linked Points\n" + (building.pointSummary.notInDeviceWithData + building.pointSummary.unLinked) + " Unlinked Points\n"


            building.descriptionTooltip = building.description + "\nAddress: " + building.address.street + "," + building.address.city + "," + building.address.country + "\nNumber of Data Readings: " + building.numberOfDataPoints;

            // if (building.name == "POCDemoBuilding") {
            //   building.lastReceivedAlarmMessage = moment().subtract(1, "month").toISOString();
            //   building.lastReceivedIntegrityIssue = moment().subtract(1, "minute").toISOString();
            //   building.lastReceivedAlarm = moment().subtract(1, "month").toISOString();
            //   building.pointsWithOpenIntegrityIssues = 8
            //   building.openAlarms = 12
            //   // building.lastReceivedDataMessage = moment().subtract(1, "month").toISOString();
            // }

            building.dataStatus = {
              message: "",
              status: "btn-primary",
              info: "OK"
            }

            building.timeSync = {
              message: "",
              status: "btn-primary",
              icon: "far fa-clock",
              info: "OK"
            }

            building.alarmStatus = {
              message: "",
              status: "btn-primary",
              info: "OK"
            }

            building.lastIntegrityIssue = {
              message: "",
              status: "btn-primary",
              icon: "fas fa-check",
              info: "OK"
            };

            if (!building.downloadURL)
              building.downloadURL = "assets/images/building.png";

            if (building.lastReceivedDataMessage) {
              if (building.lastReceivedDataMessage && moment().diff(moment(building.lastReceivedDataMessage), "minute") >= 2) {
                building.dataStatus.status = "btn-danger";
                building.dataStatus.info = "No communication"
              }
              else if (building.lastReceivedData && moment().diff(moment(building.lastReceivedData), "minute") >= 2) {
                building.dataStatus.status = "btn-warning";
                building.dataStatus.info = "Empty messages"
              }
              let dataAge = (building.lastReceivedData ? moment(building.lastReceivedData).format("DD/MM HH:mm Z") : "NA");
              let messageAge = (building.lastReceivedDataMessage ? moment(building.lastReceivedDataMessage).format("DD/MM HH:mm Z") : "NA");

              building.dataStatus.message = this.translocoService.translate('admin.dashboard.cards.activity.tooltips.dataAge', { age: dataAge }) + "\n" + this.translocoService.translate('admin.dashboard.cards.activity.tooltips.dataMessage', { age: messageAge });
            }
            building.dataStatus.message += "\n" + this.translocoService.translate('admin.dashboard.cards.activity.tooltips.dataColors');


            if (building.lastReceivedAlarmMessage) {
              if (building.lastReceivedAlarmMessage && moment().diff(moment(building.lastReceivedAlarmMessage), "minute") >= 5) {
                building.alarmStatus.status = "btn-danger";
                building.alarmStatus.info = "No communication"
              }
              else if (building.lastReceivedAlarm && moment().diff(moment(building.lastReceivedAlarm), "hour") <= 1) {
                building.alarmStatus.status = "btn-warning";
                building.alarmStatus.info = "Active alarms"
              }

              building.alarmStatus.message = "Last Alarm Received: " + (building.lastReceivedAlarm ? moment(building.lastReceivedAlarm).format("DD/MM HH:mm Z") : "NA") + "\nLast Alarm Message: " + (building.lastReceivedAlarmMessage ? moment(building.lastReceivedAlarmMessage).format("DD/MM HH:mm:ss Z") : "NA");
            }

            building.alarmStatus.message += "\n" + this.translocoService.translate('admin.dashboard.cards.activity.tooltips.alarmColors');

            if (building.lastReceivedIntegrityIssue) {

              if (moment().diff(moment(building.lastReceivedIntegrityIssue), "hour") <= 1) {
                building.lastIntegrityIssue.status = "btn-warning"
              }


              building.lastIntegrityIssue.message = "Last integrity alarm: " + moment(building.lastReceivedIntegrityIssue).format("DD/MM HH:mm Z");

            }
            building.lastIntegrityIssue.message += "\n" + this.translocoService.translate('admin.dashboard.cards.activity.tooltips.integrityColors');


            this.buildings.push(building);
          }
        }
        this.spinnerService.desactivate();
      },
      err => {
        this.spinnerService.desactivate();
        console.log(err);
      }
    );
  }

  ngOnInit() {
    this.updateData();
  }
}
