import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatOptionModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { IboxModule } from '../ibox/ibox.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TagMapsComponent } from './tag-maps.component';

@NgModule({
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatOptionModule,
    MatSelectModule,
    MatInputModule,
    IboxModule,
    FormsModule,
    ReactiveFormsModule,
    MatDatepickerModule,
    RouterModule,
    MatCheckboxModule
  ],
  declarations: [TagMapsComponent],
  exports: [TagMapsComponent]
})
export class TagMapsModule { }
