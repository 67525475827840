import { BaseInterface } from "./baseinterface";

export interface ITagMaps {
  entity: ITagMapsEntity,
  applyToExistingPoints: boolean
}

export interface ITagMapsEntity extends BaseInterface {
  name: string,
  lookup: string,
  type: TagMapType,
  tags: string[],
  condition: TagMapCondition,
  caseSensitive: boolean
}

export enum TagMapCondition {
  StartsWith = "Starts With",
  Contains = "Contains",
  EndsWith = "Ends With",
  ExactlyMatch = "Exactly Matches"
}


export enum TagMapType {
  PathOrd = "Path Ord",
  Name = "Name"
}
