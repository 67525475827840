import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BuildingService } from 'app/services/building.service';
import { SpinnerService } from 'app/services/spinner.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { DriverService } from 'app/services/driver.service';
import { MatTableDataSource } from '@angular/material/table';
import { TranslocoService } from '@ngneat/transloco';
import { SessionService } from 'app/services/session.service';

@Component({
  selector: 'app-admin-alarms-notification',
  templateUrl: './admin-alarms-notification.component.html',
  styleUrls: ['./admin-alarms-notification.component.css']
})
export class AdminAlarmsNotificationComponent implements OnInit {

  constructor(private sessionService: SessionService, private route: ActivatedRoute, private spinner: SpinnerService, private buildingService: BuildingService, private driverService: DriverService, private transloco: TranslocoService) { }

  public types: { value: string, tooltip: string, label: string, number: number }[] = [
    {
      value: "missingData",
      label: this.transloco.translate("admin.alarms.notifications.missingdata"),
      tooltip: this.transloco.translate("admin.alarms.notifications.missingdatatooltip"),
      number: 2
    },
    {
      value: "niagaraAlarms",
      label: this.transloco.translate("admin.alarms.notifications.niagaraalarms"),
      tooltip: this.transloco.translate("admin.alarms.notifications.niagaraalarmstooltip"),
      number: 3
    },
    {
      value: "pointStatesChanged",
      label: this.transloco.translate("admin.alarms.notifications.statechanged"),
      tooltip: this.transloco.translate("admin.alarms.notifications.statechangedtooltip"),
      number: 4
    },
    {
      value: "nodeAlarm",
      label: this.transloco.translate("admin.alarms.notifications.nodealarm"),
      tooltip: this.transloco.translate("admin.alarms.notifications.nodealarmtooltip"),
      number: 5
    },
    {
      value: "nonZeroData",
      label: this.transloco.translate("admin.alarms.notifications.nonzerodata"),
      tooltip: this.transloco.translate("admin.alarms.notifications.nonzerodatatooltip"),
      number: 6
    },
  ]

  public buildingContacts: { email: string, types: number[] }[] = [];
  public deviceContacts: { email: string, types: number[] }[] = [];

  public selectedDevice: any;

  public selectedBuilding: any;

  public pagination: { page: number, pageSize: number, hasNext: boolean } = { page: 1, pageSize: 10, hasNext: false };
  public filter: string = "";

  public saveBuildingContacts(contacts) {

    let nb: any = {};
    let original = this.building;
    Object.assign(nb, original);
    nb['notificationsTo'] = contacts;

    this.spinner.activate();
    this.buildingService.update(original, nb).subscribe(x => {
      this.spinner.desactivate();
    })
  }

  public saveDeviceContacts(contacts) {
    let nd: any = {};
    let original = this.device;
    Object.assign(nd, original);
    nd['notificationsTo'] = contacts;

    this.spinner.activate();
    this.driverService.update(this.selectedBuilding, nd, nd._id).subscribe(x => {

      this.spinner.desactivate();
    })
  }

  public onBuildingChange() {
    this.selectedDevice = "";
    this.sessionService.building = this.selectedBuilding;
    this.spinner.activate();
    this.driverService.getDevices(this.selectedBuilding, this.pagination.page, this.pagination.pageSize, this.filter).subscribe(x => {
      this.spinner.desactivate();

      this.devicesList = x.data;

      this.pagination.hasNext = x.data.length > this.pagination.pageSize;

      this.buildingContacts = this.building.notificationsTo;
    })
  }


  public onDeviceChanged() {
    this.deviceContacts = this.device.notificationsTo;
  }

  public applyFilter(filter) {
    this.filter = filter;
  }

  public buildingsList: any[] = [];
  public devicesList: any[] = [];

  ngOnInit() {
    this.spinner.activate();
    this.buildingService.getAll().subscribe(x => {
      this.buildingsList = x.data;

      for (let b of this.buildingsList) {
        if (b.notificationsTo == undefined) {
          b.notificationsTo = [];
        }
      }

      this.spinner.desactivate();


      this.spinner.activate();
      this.route.queryParams.subscribe(param => {
        this.spinner.desactivate();

        if (param["building"]) {

          this.selectedBuilding = param['building'];

          this.onBuildingChange();
        }
        else if(this.sessionService.building){
          this.selectedBuilding = this.sessionService.building;
          this.onBuildingChange();
        }
      })

    })

  }


  get building() {
    return this.buildingsList.find(x => x._id == this.selectedBuilding);
  }

  get device() {
    return this.devicesList.find(x => x._id == this.selectedDevice);
  }
}
