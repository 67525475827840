import { Component, OnInit, ViewChild } from "@angular/core";
import { AppI18nService } from "app/services/app.i18n.service";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { AppService } from "app/services/app.service";
import { SpinnerService } from "app/services/spinner.service";
import { SweetalertService } from "app/services/sweetalert.service";
import { LoginService } from "app/services/login.service";
import { AdminPointService } from "../../../../services/data.service";
import { ActivatedRoute } from "@angular/router";
import { UserType } from "app/interfaces/baseinterface";
import { SelectionModel } from "@angular/cdk/collections";
import { BuildingService } from 'app/services/building.service';
import { SessionService } from "app/services/session.service";
import { QueryparamsService } from "app/services/queryparams.service";

@Component({
  selector: "app-admin-metadata-visibility",
  templateUrl: "./metadata.visibility.component.html",
  styleUrls: ["./metadata.visibility.component.css"]
})
export class AdminMetadataVisibilityComponent implements OnInit {

  public hasPoints = true;

  public displayedColumns: string[];
  public building: string = "";
  public enums = UserType;

  public dataSource = new MatTableDataSource<any>();
  public apiSelection = new SelectionModel<any>(true, []);
  public dashboardSelection = new SelectionModel<any>(true, []);

  public pagination: { hasNext: boolean, page: number, pageSize: number } = { hasNext: false, page: 1, pageSize: 100 }
  public changePage(event) {

    this.pagination.page = event.page;
    this.pagination.pageSize = event.pageSize;

    this.refresh();
  }

  public filter: string = "";
  public applyFilter(value) {
    this.filter = value;
    this.pagination.page = 1;

    this.refresh();
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllApiSelected() {
    const numSelected = this.apiSelection.selected.filter((f: any) => this.dataSource.filteredData.indexOf(f) >= 0).length;
    const numRows = this.dataSource.filteredData.length;
    return numSelected === numRows;
  }

  isAllDashboardSelected() {
    const numSelected = this.dashboardSelection.selected.filter((f: any) => this.dataSource.filteredData.indexOf(f) >= 0).length;
    const numRows = this.dataSource.filteredData.length;
    return numSelected === numRows;
  }

  isAnyApiSelected() {
    return this.apiSelection.selected.filter((f: any) => this.dataSource.filteredData.indexOf(f) >= 0).length > 0;
  }

  isAnyDashboardSelected() {
    return this.dashboardSelection.selected.filter((f: any) => this.dataSource.filteredData.indexOf(f) >= 0).length > 0;
  }

  /** Selects all rows if they are not all selected; otherwise clear apiSelection. */
  masterApiToggle() {
    this.isAllApiSelected() ?
      this.apiSelection.clear() :
      this.dataSource.filteredData.forEach(row => this.apiSelection.select(row));
  }

  masterDashboardToggle() {
    this.isAllDashboardSelected() ?
      this.dashboardSelection.clear() :
      this.dataSource.filteredData.forEach(row => this.dashboardSelection.select(row));
  }

  constructor(private queryService: QueryparamsService, private sessionService: SessionService, private buildingService: BuildingService, public loginService: LoginService, public dataService: AdminPointService, private appService: AppService,
    public i18n: AppI18nService, private spinnerService: SpinnerService, private alert: SweetalertService, private route: ActivatedRoute) {

  }

  public massOperation(filter: boolean, api?: boolean, dashboard?: boolean) {
    this.spinnerService.activate();
    this.buildingService.massVisibility(this.building, filter ? this.filter : undefined, dashboard, api).subscribe(x => {
      this.spinnerService.desactivate();

      this.alert.success("Success!", "Selection updated.");

      this.refresh();
    })
  }

  public buildings = [];

  ngOnInit() {

    this.spinnerService.activate();
    this.buildingService.getAll().subscribe(x => {
      this.spinnerService.desactivate();

      this.buildings = x.data;

      this.queryService.hasParams().subscribe(check => {
        if (check) {
          this.queryService.params().subscribe(params => {

            this.building = params["building"];
            this.filter = params["filter"];
            this.pagination.page = parseInt(params["page"]);
            this.pagination.pageSize = parseInt(params["pageSize"]);

            this.refresh();
          })
        }
        else {
          this.building = this.sessionService.building;

          if (this.building != null && this.building != undefined)
            this.refresh();
        }
      })
    })

    this.displayedColumns = ["index",
      "Ord", "apiAccessible", "dashboardAccessible"
    ];
  }

  public refresh() {
    this.spinnerService.activate();
    this.sessionService.building = this.building;
    this.queryService.ReplaceParams({
      page: this.pagination.page,
      pageSize: this.pagination.pageSize,
      filter: this.filter,
      building: this.building
    }).subscribe(x => { })

    this.dataService.GetAllPointsWithVisibility(this.building, this.filter, this.pagination.page, this.pagination.pageSize).subscribe(
      (results: any) => {

        let data = results.data;

        let c = 1;
        for (let i of data) {
          i["index"] = (this.pagination.page - 1) * this.pagination.pageSize + c;
          c++;
        }

        this.pagination.hasNext = data.length > this.pagination.pageSize;

        this.hasPoints = data.length > 0;
        this.dataSource.data = data;
        this.apiSelection.select(...data.filter((d: any) => d.apiAccessible));
        this.dashboardSelection.select(...data.filter((d: any) => d.dashboardAccessible));
        this.spinnerService.desactivate();
      },
      err => {
        this.spinnerService.desactivate();
        console.log(err);
        // this.alert.error(this.i18n.translate('general.errors.error'), this.i18n.translate('general.errors.unkwonError', err));
      }
    );
  }

  ngAfterViewInit() {
  }


  public save() {
    let apiAccessible = this.apiSelection.selected.map((d: any) => d._id);
    let dashboardAccessible = this.dashboardSelection.selected.map((d: any) => d._id);
    let update = {
      apiAccessible: apiAccessible,
      dashboardAccessible: dashboardAccessible,
      removeApiAccessible: this.dataSource.data.filter((d: any) => d.apiAccessible).
        map((d: any) => d._id).
        filter((d: any) => apiAccessible.indexOf(d) == -1),
      removeDashboardAccessible: this.dataSource.data.filter((d: any) => d.dashboardAccessible).
        map((d: any) => d._id).
        filter((d: any) => dashboardAccessible.indexOf(d) == -1)
    };

    this.spinnerService.activate();
    this.dataService.UpdatePointsVisibility(this.building, update).subscribe(
      results => {
        this.spinnerService.desactivate();
        this.alert.success(this.i18n.translate("general.messages.success"), this.i18n.translate("general.messages.updated", this.i18n.translate('general.sections.admin.building.pointVisibility')));
      },
      err => {
        this.spinnerService.desactivate();
        console.log(err);
        this.alert.info(this.i18n.translate("general.errors.error"), this.i18n.translate("general.errors.unkwonError", err));
      }
    );
  }
}

