import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from "@angular/core";
import { AppI18nService } from "app/services/app.i18n.service";
import { SweetalertService } from "app/services/sweetalert.service";
import { Router } from "@angular/router";
import { SpinnerService } from "app/services/spinner.service";
import { FormArray, FormControl, FormGroup, Validators } from "@angular/forms";
import { Location } from "@angular/common";
import { User } from "app/interfaces/user";
import { Company } from "app/interfaces/company";
import { CompanyService } from "app/services/company.service";
import { AppService } from "app/services/app.service";
import { LoginService } from "app/services/login.service";
import { UserType } from "app/interfaces/baseinterface";
import { PhoneType } from "app/interfaces/contact";

import * as moment from "moment-timezone";
import { map, startWith } from "rxjs/operators";
import { Observable } from "rxjs";
import { TranslocoService } from "@ngneat/transloco";

@Component({
  selector: "app-user-form",
  templateUrl: "./user-form.component.html",
  styleUrls: ["./user-form.component.css"]
})
export class UserFormComponent implements OnInit, OnChanges {
  @Output() onsubmit: EventEmitter<any> = new EventEmitter<any>();
  @Output() onDelete: EventEmitter<any> = new EventEmitter<any>();
  @Input() user: any;
  @Input() disabledFields: string[] = [];

  public isOwner: boolean = false;
  public companyList: Company[];
  public phoneTypes: { key: string, value: string }[] = [
    { key: "mobile", value: "mobile" },
    { key: "landline", value: "landline" },
  ];

  public countriesFiltered: Observable<string[]>;
  public countries: any[] = [];

  public passwordForm: FormGroup = new FormGroup({
    oldPassword: new FormControl("", Validators.required),
    newPasswordConfirmation: new FormControl("", [Validators.required, this.validatePassword]),
    newPassword: new FormControl("", [Validators.required])
  });

  public userForm: FormGroup = new FormGroup({
    type: new FormControl({ value: UserType.BuildingAdmin, disabled: true }, [Validators.required]),
    company: new FormControl({ value: "", disabled: true }, [Validators.required]),
    country: new FormControl("", [Validators.required]),
    name: new FormControl("", [Validators.required]),
    primaryEmail: new FormControl("", [Validators.required, Validators.email]),
    emails: new FormArray([]),
    telephones: new FormArray([])
  });;

  constructor(private translocoService: TranslocoService, private companyService: CompanyService, private router: Router, private _location: Location, private spinnerService: SpinnerService,
    private alertService: SweetalertService, public appService: AppService, public loginService: LoginService) {
  }

  public userType: string;

  public newEmail = "";
  public newPhone = {
    number: "",
    type: "landline",
    extension: "",
  }

  public userTypes = Object.keys(UserType).map(key => UserType[key]).filter(x => typeof (x) == "number");

  public candEditUser = [ UserType.CompanyAdmin, UserType.Root]

  private _filterCountries(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.countries.filter(x => x.toLowerCase().indexOf(filterValue) >= 0);
  }

  ngOnInit() {

    this.countries = moment.tz.names();

    this.countriesFiltered = this.userForm.get("country").valueChanges.pipe(
      startWith(''),
      map(value => this._filterCountries(value))
    );

    this.userType = this.loginService.userInfo.type;



    if (this.loginService.userInfo.type == UserType.Root) {
      this.spinnerService.activate();
      this.companyService.getAll().subscribe(results => {
        this.companyList = results.data;
        this.spinnerService.desactivate();
      });


      // this.userForm.get("type").enable();
      this.userForm.get("company").enable();
    }

    this.canEdit();
  }

  public canEdit() {
    if (this.user && this.user._id == this.loginService.userInfo._id) {
      this.userForm.get("type").disable();
    }
    else if (this.candEditUser.indexOf(this.loginService.userInfo.type) >= 0) {
      this.userForm.get("type").enable();

      this.userTypes = this.userTypes.filter(x => x <= this.loginService.userInfo.type)
    }
  }

  get phones() {
    return (<FormArray>this.userForm.get("telephones")).controls
  }

  get emails() {
    return (<FormArray>this.userForm.get("emails")).controls
  }

  ngOnChanges(changes: SimpleChanges) {
    console.log(changes);

    if (this.user) {
      this.canEdit();

      this.userForm.get("type").setValue(this.user.type);
      this.userForm.get("company").setValue(this.user.company);
      this.userForm.get("primaryEmail").setValue(this.user.email);
      this.userForm.get("name").setValue(this.user.person.name);

      this.userForm.get("country").setValue(this.user.person.country);

      for (let phone of this.user.person.contacts.telephones) {
        this.newPhone.type = phone.type;
        this.newPhone.number = phone.number;
        this.newPhone.extension = phone.internalExtension;

        this.addPhone();
      }


      if (this.user._id == this.loginService.userInfo._id) {
        this.isOwner = true;
      }

      console.log(this.userTypes);

    }

  }

  public changePassword() {
    if (this.passwordForm.valid) {
      this.spinnerService.activate();
      let registrationUser = {
        existingPassword: this.passwordForm.value.oldPassword,
        password: this.passwordForm.value.newPassword,
        username: ""
      };
      this.loginService.changePassword(registrationUser).subscribe(
        result => {
          this.spinnerService.desactivate();
          this.alertService.success(this.translocoService.translate("admin.users.form.passwordUpdate.success"),
            this.translocoService.translate("admin.users.form.passwordUpdate.updated"));
          this.passwordForm = new FormGroup({
            oldPassword: new FormControl("", Validators.required),
            newPasswordConfirmation: new FormControl("", [Validators.required, this.validatePassword]),
            newPassword: new FormControl("", [Validators.required])
          });
          this.passwordForm.controls["newPassword"].valueChanges.subscribe!({
            next: (value) => {
              this.passwordForm.controls["newPasswordConfirmation"].updateValueAndValidity();
            }
          });
        },
        err => {
          console.error(err);
          this.spinnerService.desactivate();

          if (err.code == "InvalidParameterException") {
            this.alertService.error(this.translocoService.translate("admin.users.form.passwordUpdate.error"),
              this.translocoService.translate("admin.users.form.passwordUpdate.instructions.full"), 0);
          } else if (err.code == "NotAuthorizedException") {
            this.alertService.error(this.translocoService.translate("admin.users.form.passwordUpdate.error"),
              this.translocoService.translate("admin.users.form.passwordUpdate.invalidPassword"), 0);
          }
        }
      );
    }
  }

  public onSubmit() {
    this.onsubmit.emit(this.userForm);
  }

  public delete() {
    this.onDelete.emit(this.user);
  }

  public addEmail() {
    (<FormArray>this.userForm.controls.emails).push(
      new FormControl(this.newEmail)
    );

    this.newEmail = "";
  }

  public removeEmail(pos) {
    (<FormArray>this.userForm.controls.emails).removeAt(pos);
  }

  public addPhone() {
    (<FormArray>this.userForm.get("telephones")).push(
      new FormGroup({
        number: new FormControl(this.newPhone.number),
        internalExtension: new FormControl(this.newPhone.extension),
        type: new FormControl(this.newPhone.type)
      })
    );

    this.newPhone.number = "";
    this.newPhone.extension = "";
  }

  public removePhone(pos) {
    (<FormArray>this.userForm.controls.telephones).removeAt(pos);
  }

  public validateNewEmail() {
    let formStatus = this.passwordForm.valid;

    if (this.passwordForm.value.newPassword == this.passwordForm.value.newPasswordConfirmation && this.passwordForm.value.newPassword !=
      "") {


      return formStatus;
    }

    return false;
  }

  private validatePassword(f: FormControl) {
    if (f.parent) {
      return f.parent.controls["newPassword"].value == f.parent.controls["newPasswordConfirmation"].value ? null :
        { validatePassword: { valid: false } };
    }
    return null;
  }
}
