import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { QrPrintComponent } from './qr-print.component';
import { MyQRCodeModule } from '../QRCode/qr-code.module';



@NgModule({
  declarations: [QrPrintComponent],
  imports: [
    CommonModule,
    MyQRCodeModule
  ],
  exports: [
    QrPrintComponent
  ]
})
export class QrPrintModule { }
