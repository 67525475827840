import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from "@angular/core";
import { Location } from "@angular/common";
import { AppI18nService } from "app/services/app.i18n.service";
import { SpinnerService } from "app/services/spinner.service";
import { UserService } from "app/services/user.service";
import { User } from "app/interfaces/user";
import { FormArray, FormControl, FormGroup, Validators } from "@angular/forms";
import { Company } from "app/interfaces/company";
import { SweetalertService } from "app/services/sweetalert.service";
import { CompanyService } from "app/services/company.service";
import { Router } from "@angular/router";
import { AppService } from "app/services/app.service";
import { PhoneType } from "app/interfaces/contact";

@Component( {
  selector: "app-company-form",
  templateUrl: "./company-form.component.html",
  styleUrls: [ "./company-form.component.css" ]
} )
export class CompanyFormComponent implements OnInit, OnChanges {

  @Output() onsubmit: EventEmitter<any> = new EventEmitter<any>();
  @Input() company: Company;
  @Input() disabledFields: string[] = [];

  public isEdit: boolean = false;

  public types: { key: string, value: string }[] = [];

  public usersList: User[];
  public max = 0;

  public companyForm: FormGroup = new FormGroup( {
    name: new FormControl( "", Validators.required ),
    address: new FormGroup( {
      country: new FormControl( "", Validators.required ),
      street: new FormControl( "", Validators.required ),
      city: new FormControl( "", Validators.required ),
      neighborhood: new FormControl( "", Validators.required ),
      complement: new FormControl( "", Validators.required ),
      zipcode: new FormControl( "", Validators.required ),
      number: new FormControl( "", Validators.required ),
      lat: new FormControl( 1, Validators.required ),
      lng: new FormControl( 1, Validators.required )
    } ),
    primaryEmail: new FormControl( "", [ Validators.required, Validators.email ] ),
    primaryTelephone: new FormGroup( {
      number: new FormControl( "", [ Validators.required ] ),
      internalExtension: new FormControl( "" ),
      type: new FormControl( "", Validators.required )
    } ),
    otherEmails: new FormArray( [] ),
    otherTelephones: new FormArray( [] ),
    responsible: new FormGroup( {
      country: new FormControl( "", Validators.required ),
      name: new FormControl( "", Validators.required ),
      mobilephone: new FormControl( "", Validators.required ),
      landline: new FormGroup( {
        number: new FormControl( "", [ Validators.required ] ),
        internalExtension: new FormControl( "" )
      } ),
      primaryEmail: new FormControl( "", [ Validators.required, Validators.email ] ),
      emails: new FormArray( [] ),
      telephones: new FormArray( [] )
    } ),
    responsibles: new FormControl()
  } );

  constructor( private router: Router, private companyService: CompanyService, public i18n: AppI18nService,
    private _location: Location, private spinnerService: SpinnerService, private userService: UserService,
    private alertService: SweetalertService, public appService: AppService ) {
  }

  ngOnChanges( changes: SimpleChanges ) {
    if( this.company ) {
      this.isEdit = true;
      this.companyForm =
        new FormGroup( {
          name: new FormControl( this.company.name, Validators.required ),
          address: new FormGroup( {
            country: new FormControl( { value: this.company.address.country, disabled: this.disabledFields.indexOf( "country" ) > -1 },
              Validators.required ),
            street: new FormControl( { value: this.company.address.street, disabled: this.disabledFields.indexOf( "street" ) > -1 },
              Validators.required ),
            city: new FormControl( { value: this.company.address.city, disabled: this.disabledFields.indexOf( "city" ) > -1 },
              Validators.required ),
            neighborhood: new FormControl(
              { value: this.company.address.neighborhood, disabled: this.disabledFields.indexOf( "neighborhood" ) > -1 },
              Validators.required ),
            complement: new FormControl(
              { value: this.company.address.complement, disabled: this.disabledFields.indexOf( "complement" ) > -1 }, Validators.required ),
            zipcode: new FormControl( { value: this.company.address.zipCode, disabled: this.disabledFields.indexOf( "zipcode" ) > -1 },
              Validators.required ),
            number: new FormControl( { value: this.company.address.number, disabled: this.disabledFields.indexOf( "number" ) > -1 },
              Validators.required ),
            lat: new FormControl( this.company.address.lat, Validators.required ),
            lng: new FormControl( this.company.address.lng, Validators.required )
          } ),
          primaryEmail: new FormControl(
            { value: this.company.primaryEmail || [], disabled: this.disabledFields.indexOf( "primaryEmail" ) > -1 },
            [ Validators.required, Validators.email ] ),
          primaryTelephone: new FormGroup( {
            number: new FormControl(
              { value: this.company.primaryTelephone.number, disabled: this.disabledFields.indexOf( "primaryTelephone" ) > -1 },
              [ Validators.required ] ),
            internalExtension: new FormControl( {
              value: this.company.primaryTelephone.internalExtension,
              disabled: this.disabledFields.indexOf( "primaryTelephone" ) > -1
            } ),
            type: new FormControl( this.company.primaryTelephone.type, Validators.required )
          } ),
          otherEmails: new FormArray( ( this.company.contacts.emails || [] ).map( x => {
            return new FormControl( x, [ Validators.required, Validators.email ] );
          } ) ),
          otherTelephones: new FormArray( ( this.company.contacts.telephones || [] ).map( x => {
            return new FormGroup( {
              number: new FormControl( x.number, [ Validators.required ] ),
              internalExtension: new FormControl( x.internalExtension ),
              type: new FormControl( x.type, Validators.required )
            } );
          } ) ),
          responsibles: new FormControl( this.company.responsibles, Validators.minLength( 1 ) )
        } );
    }
  }

  public addContactEmail() {
    ( <FormArray>this.companyForm.controls.otherEmails ).push(
      new FormControl( "", [ Validators.required, Validators.email ] )
    );
  }

  public addContactPhone() {
    ( <FormArray>this.companyForm.controls.otherTelephones ).push(
      new FormGroup( {
        number: new FormControl( "", [ Validators.required ] ),
        internalExtension: new FormControl( "" ),
        type: new FormControl( "", Validators.required )
      } )
    );
  }

  public removeContactEmail( pos ) {
    ( <FormArray>this.companyForm.controls.otherEmails ).removeAt( pos );
  }

  public removeContactPhone( pos ) {
    ( <FormArray>this.companyForm.controls.otherTelephones ).removeAt( pos );
  }

  public addEmail() {
    // if (!(<FormArray>(<FormGroup>this.companyForm.controls.responsibles).controls.emails))
    //   (<FormGroup>this.companyForm.controls.responsibles).addControl("emails",new FormArray([new FormControl('', Validators.email)]))
    // else {
    ( <FormArray>( <FormGroup>this.companyForm.controls.responsible ).controls.emails ).push(
      new FormControl( "", [ Validators.required, Validators.email ] )
    );
    // }
  }

  public removeEmail( pos ) {
    ( <FormArray>( <FormGroup>this.companyForm.controls.responsible ).controls.emails ).removeAt( pos );
  }

  public addPhone() {
    ( <FormArray>( <FormGroup>this.companyForm.controls.responsible ).controls.telephones ).push(
      new FormGroup( {
        number: new FormControl( "", [ Validators.required ] ),
        internalExtension: new FormControl( "" ),
        type: new FormControl( "", Validators.required )
      } )
    );
  }

  public removePhone( pos ) {
    ( <FormArray>( <FormGroup>this.companyForm.controls.responsible ).controls.telephones ).removeAt( pos );
  }

  ngOnInit() {
    for( let value in PhoneType ) {
      this.types.push( { key: value, value: PhoneType[ value ] } );
    }

    this.spinnerService.activate();
    this.userService.getAll().subscribe(
      results => {
        this.usersList = results.data;
        this.spinnerService.desactivate();
      },
      err => {
        console.log( err );
      } );

  }

  public goBack() {
    this._location.back();
  }

  public onSubmit() {
    if( this.companyForm.valid ) {
      this.onsubmit.emit( this.companyForm );
    }
  }

  public reset() {
    this.companyForm.reset( {
      responsibles: this.company.responsibles,
      name: this.company.name,
      telephones: this.company.contacts.telephones.join( "," ),
      emails: this.company.contacts.emails.join( "," )
    } );
  }

}

