import { Component, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { HighchartsComponent } from 'app/components/common/highcharts/highcharts/highcharts.component';
import { AppHighstockComponent } from 'app/components/common/highcharts/highstock/highstock.component';
import { AdminPointService } from 'app/services/data.service';
import { PointgroupsService } from 'app/services/pointgroups.service';
import { SpinnerService } from 'app/services/spinner.service';
import * as moment from 'moment';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'app-admin-violin-detailed',
  templateUrl: './admin-violin-detailed.component.html',
  styleUrls: ['./admin-violin-detailed.component.css']
})
export class AdminViolinDetailedComponent implements OnInit {

  constructor(private translocoService: TranslocoService, private pointsService: AdminPointService, private spinner: SpinnerService, private pointgroupService: PointgroupsService, private activatedroute: ActivatedRoute) { }

  public selectedAsset = "";
  public assetOptions: { name: string, id: string }[] = [];

  public groupInfo;

  public test = {

    credits: {
      enabled: false
    },
    chart: {
      zoomType: "x",
      height: 600,
      // alignTicks: false
    },
    plotOptions: {
      series: {
        connectNulls: false,
        showEmpty: false,
        dataGrouping: {
          enabled: false,
          // enabled: true,
          // units: [
          //   ["minute", [15, 30]],
          //   ["hour", [1, 2, 6, 12]],
          //   ["day", [1]],
          //   ["week", [1]],
          //   ["month", [1, 2, 3, 6]],
          //   ["year", [1]]
          // ]
        }
      }
    },
    xAxis: {
      type: "datetime",
      ordinal: false
    },
    navigator: {
      // enabled: false,
      series: {
        includeInCSVExport: true
      }
    },
    legend: {
      floating: false,
      enabled: true,
      align: "center",
      shadow: true,
      y: 50,
      verticalAlign: "top"
    },
    tooltip: {
      enabled: true,
      valueDecimals: 2,
      format: "{point.y:." + 2 + "f}",
      shared: true,
      split: false
    },
    rangeSelector: {
      inputEnabled: false,
      buttons: [
        /*{
         type: "hour",
         count: 1,
         text: "1h"
         }, {
         type: "day",
         count: 1,
         text: "1d"
         }, {
         type: "week",
         count: 1,
         text: "1w"
         }, {
         type: "month",
         count: 1,
         text: "1m"
         }, {
         type: "year",
         count: 1,
         text: "1y"
         },*/ {
          type: "all",
          text: "All"
        }
      ],
      selected: 1, // all
      buttonTheme: {
        width: null
      }
    }
  };;

  @ViewChild("summary") summary: AppHighstockComponent;
  @ViewChildren("polar") polar: QueryList<HighchartsComponent>;
  @ViewChildren("present") present: QueryList<HighchartsComponent>;
  @ViewChildren("average") average: QueryList<HighchartsComponent>;

  public violinOptionss = {
    plotOptions: {
      series: {

        enableMouseTracking: false,
        marker: { enabled: false },
        zoneAxis: "x",

        borderWidth: 1,
        lineColor: "#000"

      }
    },
    yAxis: {
      labels: {
        formatter: (value) => {
          let first = value.axis.paddedTicks[0];
          let last = value.axis.paddedTicks[value.axis.paddedTicks.length - 1];

          let mid = (last - first) / 2;

          return value.value - last + mid;
        }
      }
    }
  }

  public polarOptions = {

    chart: {
      type: "column",
      polar: true
    },
    credits: {
      enabled: false
    },
    title: {
      text: ""
    },
    pane: {
      startAngle: 0,
      endAngle: 360
    },

    xAxis: {
      categories: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23]
    },
    legend: {
      enabled: false
    },
    exporting: {
      enabled: false
    },
    yAxis: {
      min: 0,
      labels: {
        enabled: false
      }
    },

    tooltip: {
      formatter: function () {
        return "From target "+ Math.floor(this.y * 1000) / 1000 ;
      }
    },
    plotOptions: {
      series: {
        pointPlacement: 'on',
      },
      column: {
        pointPadding: 0,
        groupPadding: 0
      }
    },
  }

  private originalData;
  public from: string = "";
  public to: string = ""

  ngOnInit(): void {
    this.activatedroute.params.subscribe(p => {
      this.selectedAsset = p["asset"];

      this.spinner.activate();
      this.from = moment.utc().subtract(1, "week").format("DD/MM/YYYY");
      this.to =  moment.utc().subtract(1, "week").endOf("week").format("DD/MM/YYYY");
      this.pointgroupService.weekViolin(p["id"], moment.utc().subtract(1, "week").startOf("week").toISOString()).subscribe(res => {

        this.originalData = res;

        this.spinner.desactivate();



        this.spinner.activate();
        this.pointgroupService.getPointGroup(p["id"], 1).subscribe(x => {
          this.spinner.desactivate();
          this.groupInfo = x;
          this.assetOptions = x.data.subGroups.filter(t => t.type == 1).map(t => { return { name: t.name, id: t._id } })


          this.updateCharts();
        })
      })
    })
  }

  public dataTypeDesc: { color: string, desc: string }[] = [];
  public summaryData: { range: string, target: string, sd: string, temp: string, status: string } = { range: "", target: "", sd: "", temp: "", status: "" }

  private updatePolar() {
    this.spinner.activate();
    this.pointgroupService.weekPolar(this.selectedAsset, moment.utc().subtract(1, "week").startOf("week").toISOString()).subscribe(x => {

      this.dataTypeDesc = [];

      if (x.dataTypeDesc)
        for (let key of Object.keys(x.dataTypeDesc)) {
          this.dataTypeDesc.push({
            color: this.colors[parseInt(key)],
            desc: x.dataTypeDesc[key]
          })

        }

      let polar = this.polar.toArray();
      for (let i = 0; i < polar.length; i++) {

        let arrayChartsPolar = polar[i];

        while (arrayChartsPolar.Chart.series.length) {
          arrayChartsPolar.Chart.series[0].remove(false);
        }

        let data = [];

        for (let j = 0; j < x.data[i].length; j++) {
          data.push({ x: j, y: x.data[i][j].y, color: this.colors[x.data[i][j].color] });
        }

        arrayChartsPolar.addSeries({ id: "polar" + i, data: data, name: "polar" }, "polar" + i);
      }

      this.spinner.desactivate();
    })
  }

  private colors = ['#058DC7', '#50B432', '#ED561B', '#DDDF00', '#24CBE5', '#64E572', '#FF9655', '#FFF263', '#6AF9C4'];

  private updateSummary() {

    let points = this.groupInfo.data.subGroups.find(x => x._id == this.selectedAsset).points;

    // while (this.summary.Chart.series.length) {
    //   this.summary.Chart.series[0].remove(false);
    // }

    let arr = [];

    for (let p of points) {
      arr.push(this.pointsService.GetDataForPoint({ building: this.groupInfo.data.building, point: p, from: moment.utc().subtract(1, "week").startOf("week").toISOString(), to: moment.utc().subtract(1, "week").endOf("week").toISOString() }));
    }

    this.spinner.activate();
    forkJoin(arr).subscribe((res) => {
      this.spinner.desactivate();

      for (let data of <any>res) {
        let s = []
        let start = moment.utc().subtract(1, "week").startOf("week");
        let end = moment.utc().subtract(1, "week").endOf("week");

        let d = data.data[0];
        for (let i = 1; i <= 7; i++) {
          for (let j = 0; j < 1440; j++) {
            if (d.weekPointData[i.toString()] && d.weekPointData[i.toString()][j] != null) {
              s.push([start.valueOf(), d.weekPointData[i.toString()][j]]);
            }
            else {
              s.push([start.valueOf(), null]);
            }

            start.add(1, "minute");
          }
        }

        // for (let d of data.data) {
        //   for (let key of Object.keys(d.weekPointData)) {
        //     for (let value of d.weekPointData[key]) {
        //       s.push([start.valueOf(), value]);
        //       start.add(1, "minute");
        //     }
        //   }
        // }

        let p = this.groupInfo.points.find(x => x._id == data.data[0].point)

        let name = p.friendlyName.split("/");

        console.log({ id: name[name.length - 1], name: name[name.length - 1], data: s });
        this.summary.updateSeries({ showInNavigator: true, id: name[name.length - 1], name: name[name.length - 1], data: s }, name[name.length - 1], false, false);

      }

      this.summary.redraw();
    });

  }

  public updateCharts() {
    let present = this.present.toArray();
    let average = this.average.toArray();

    this.updatePolar();
    this.updateSummary();

    for (let i = 0; i < present.length; i++) {
      let arrayChartsPresent = present[i];
      let arrayChartsAverage = average[i];

      while (arrayChartsPresent.Chart.series.length) {
        arrayChartsPresent.Chart.series[0].remove(false);
      }

      while (arrayChartsAverage.Chart.series.length) {
        arrayChartsAverage.Chart.series[0].remove(false);
      }

      if (this.originalData.data[i] && this.originalData.data[i].violinData) {
        for (let violin of this.originalData.data[i].violinData.data) {
          for (let series of violin.series) {

            if (series._id == this.selectedAsset) {

              arrayChartsPresent.addSeries(series, series.id, false, false);
            }
          }
        }


        arrayChartsPresent.redraw();

        let t = this.originalData.data[i].violinData.data;
        let last = t[t.length - 1];

        if (last) {
          arrayChartsAverage.addSeries(last.series[last.series.length - 1], last.series[last.series.length - 1].id, false, false);
          arrayChartsAverage.addSeries(last.series[last.series.length - 2], last.series[last.series.length - 2].id, false, false);

          arrayChartsAverage.redraw();
        }
      }
    }
  }
}
