import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot } from "@angular/router";
import { Observable, Observer } from "rxjs";
import { LoginService } from "app/services/login.service";

const AdminHistory: string = "admin/remote/history";
const AdminServerPoints: string = "admin/remote/server-points";
const AdminDriver: string = "admin/remote/points-management";
const AdminDevices: string = "admin/remote/devices";
const AdminAutomaticGroups: string = "admin/grouping/automatic";
const AdminGroupsList: string = "admin/grouping/groups";
const AdminAssetsList: string = "admin/grouping/assets";
const AdminAssetView: string = "admin/grouping/view/:id";
const AdminViolin: string = "admin/grouping/violin/:id";
const AdminViolinDetailed: string = "admin/grouping/detailedviolin/:id/:asset";
const AdminGroupsCreate: string = "admin/grouping/groups/create";
const AdminGroupsEdit: string = "admin/grouping/groups/edit/:id";
const AdminAssetsCreate: string = "admin/grouping/assets/create";
const AdminAssetsEdit: string = "admin/grouping/assets/edit/:id";
const Profile: string = "admin/profile";
const AdminDashboard: string = "admin/dashboard";
const AdminUsers: string = "admin/users";
const AdminCreateUser: string = "admin/users/create";
const AdminEditUser: string = "admin/users/:id";
const AdminMeterData: string = "admin/meterdata";
const AdminHealth: string = "admin/reports/health";
const FaultsCreate: string = "admin/faults/create";
const FaultsList: string = "admin/faults/list";
const FaultsView: string = "admin/faults/view/:building/:id";
const FaultsReport: string = "admin/faults/report";
const AdminMeterReading: string = "admin/meters/readings";
const AdminMeterList: string = "admin/meters/list";
const AdminMetersReading: string = "admin/meters/metering";
const AdminAdvancedView: string = "admin/dataview/advanced";
const AdminMetadataList: string = "admin/dataview/allpoints";
const AdminPointData: string = "admin/dataview/simple";
const AdminDataPrediction: string = "admin/dataview/prediction";
const AdminMetadataCreate: string = "admin/dataview/allpoints/create";
const AdminMetadataEdit: string = "admin/dataview/allpoints/:id";
const AdminAlarmList: string = "admin/alarmdata/list";
const AdminAlarmsNotification: string = "admin/alarmdata/notifications";
const AdminAlarmData: string = "admin/alarmdata/report";
const AdminMetadataVisibility: string = "admin/security/api";
const AdminApiKey: string = "admin/security/accesskey";
const AdminCreateApiKey: string = "admin/security/accesskey/create";
const AdminEditApiKey: string = "admin/security/accesskey/:id";
const AdminTagMaps: string = "admin/tagMaps";
const AdminTagFlagMaps: string = "admin/tagMaps/create";
const AdminIntegrityIssues: string = "admin/integrity";
const AdminBuildings: string = "admin/buildings";
const AdminCreateBuildings: string = "admin/buildings/create";
const AdminEditBuildings: string = "admin/buildings/:id";
const RootCompanies: string = "root/companies";
const RootCreateCompany: string = "root/companies/create";
const RootEditCompany: string = "root/companies/:id";
const PointValueSearch: string = "admin/dataview/pointvalue";
const Mock: string = "admin/mock";
// const RootBuildings: string = "root/companies/:id/buildings";


const userPermissions: any = {
  /**Report */       "-30": [Mock, AdminHealth, AdminMeterData, AdminIntegrityIssues, AdminDashboard, Profile, AdminGroupsList, AdminAssetsList, AdminAssetView, AdminViolin, AdminViolinDetailed],
  /**Maintenance */   "30": [Mock, PointValueSearch, AdminBuildings, AdminIntegrityIssues, AdminAlarmData, AdminAlarmsNotification, AdminAlarmList, AdminDataPrediction, AdminPointData, AdminMetadataList, AdminAdvancedView, AdminMetersReading, AdminMeterList, AdminMeterReading, FaultsReport, FaultsView, FaultsList, FaultsCreate, AdminHealth, AdminMeterData, AdminDashboard, Profile, AdminAssetView, AdminViolin, AdminViolinDetailed],
  /**Viewer */         "0": [Mock, PointValueSearch, AdminBuildings, AdminIntegrityIssues, AdminAlarmData, AdminAlarmList, AdminDataPrediction, AdminPointData, AdminMetadataList, AdminAdvancedView, AdminMetersReading, AdminMeterList, AdminMeterReading, FaultsReport, FaultsView, FaultsList, AdminHealth, AdminMeterData, AdminDashboard, Profile, AdminGroupsList, AdminAssetsList, AdminAssetView, AdminViolin, AdminViolinDetailed],
  /**BuildingAdmin */ "50": [Mock, PointValueSearch, AdminBuildings, AdminIntegrityIssues, AdminTagMaps, AdminTagFlagMaps, AdminEditApiKey, AdminCreateApiKey, AdminApiKey, AdminMetadataVisibility, AdminAlarmData, AdminAlarmsNotification, AdminAlarmList, AdminMetadataEdit, AdminMetadataCreate, AdminDataPrediction, AdminPointData, AdminMetadataList, AdminAdvancedView, AdminMetersReading, AdminMeterList, AdminMeterReading, FaultsReport, FaultsView, FaultsList, FaultsCreate, AdminHealth, AdminMeterData, AdminEditUser, AdminCreateUser, AdminUsers, AdminDashboard, Profile, AdminHistory, AdminServerPoints, AdminDriver, AdminDevices, AdminAutomaticGroups, AdminGroupsList, AdminAssetsList, AdminAssetView, AdminViolin, AdminViolinDetailed, AdminGroupsCreate, AdminGroupsEdit, AdminAssetsEdit, AdminAssetsCreate],
  /**CompanyAdmin */  "80": [Mock, PointValueSearch, AdminGroupsEdit, AdminAssetsEdit, AdminEditBuildings, AdminCreateBuildings, AdminBuildings, AdminIntegrityIssues, AdminTagMaps, AdminTagFlagMaps, AdminEditApiKey, AdminCreateApiKey, AdminApiKey, AdminMetadataVisibility, AdminAlarmData, AdminAlarmsNotification, AdminAlarmList, AdminMetadataEdit, AdminMetadataCreate, AdminDataPrediction, AdminPointData, AdminMetadataList, AdminAdvancedView, AdminMetersReading, AdminMeterList, AdminMeterReading, FaultsReport, FaultsView, FaultsList, FaultsCreate, AdminHealth, AdminMeterData, AdminEditUser, AdminCreateUser, AdminUsers, AdminDashboard, Profile, AdminHistory, AdminServerPoints, AdminDriver, AdminDevices, AdminAutomaticGroups, AdminAssetsList, AdminAssetsList, AdminGroupsList, AdminAssetView, AdminViolin, AdminViolinDetailed, AdminGroupsCreate, AdminAssetsCreate],
  /**Root */         "100": [Mock, PointValueSearch, AdminGroupsEdit, AdminAssetsEdit, RootEditCompany, RootCreateCompany, RootCompanies, AdminEditBuildings, AdminCreateBuildings, AdminBuildings, AdminIntegrityIssues, AdminTagMaps, AdminTagFlagMaps, AdminEditApiKey, AdminCreateApiKey, AdminApiKey, AdminMetadataVisibility, AdminAlarmData, AdminAlarmsNotification, AdminAlarmList, AdminMetadataEdit, AdminMetadataCreate, AdminDataPrediction, AdminPointData, AdminMetadataList, AdminAdvancedView, AdminMetersReading, AdminMeterList, AdminMeterReading, FaultsReport, FaultsView, FaultsList, FaultsCreate, AdminHealth, AdminMeterData, AdminEditUser, AdminCreateUser, AdminUsers, AdminDashboard, Profile, AdminHistory, AdminServerPoints, AdminDriver, AdminDevices, AdminAutomaticGroups, AdminAssetsList, AdminGroupsList, AdminAssetView, AdminViolin, AdminViolinDetailed, AdminGroupsCreate, AdminAssetsCreate]
}

@Injectable()
export class AuthGuard implements CanActivate, CanActivateChild {

  // Root = 100,
  // CompanyAdmin = 80
  // BuildingAdmin = 50,
  // Employee = 30,
  // Client = 0,

  constructor(private router: Router, private loginService: LoginService) {
  }

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> | Promise<boolean> {
    return this.canActivate(childRoute, state);
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return new Observable((observer: Observer<any>) => {

      this.loginService.isAuthenticated().subscribe(
        (res) => {
          // user is logged but in the unauth section
          if (state.url === "/error/unauthorized" || state.url == "/admin/unauthorized") {
            observer.next(true);
            return;
          } else {
            this.checkUserPermission(observer, state, route);
          }
        },
        (error) => {
          console.error(error.error);
          // user is not logged and in the auth section
          if (state.url.startsWith("/auth/")) {
            observer.next(true);
            return;
          } else {
            this.router.navigate(["auth/login"], { queryParams: { returnUrl: encodeURI(state.url) } });
            observer.next(false);
            return;
          }
        }
      );
    });
  }

  private checkUserPermission(observer, state: RouterStateSnapshot, route: ActivatedRouteSnapshot) {
    let userType = this.loginService.userInfo.type;
    // let userType = 80;

    let map = userPermissions[userType.toString()];

    let completeUrl = state.url.split("?")[0].split("/").filter(x => x != "");

    if (map.indexOf("*") >= 0) {
      observer.next(true);
      return;
    }

    for (let c of map) {
      if (state.url.endsWith(c)) {
        observer.next(true);
        return;
      }
      else {
        let found = true;
        let fragments = c.split("/");

        if (fragments.length == completeUrl.length) {
          for (let i = fragments.length; i--; i > 0) {
            if (fragments[i].indexOf(":") >= 0) {
              continue;
            }
            else {
              if (completeUrl[i] != fragments[i]) {
                found = false;
                break;
              }
            }
          }

          if (found) {
            observer.next(true);
            return;
          }
        }
      }
    }

    this.router.navigate(["/admin/unauthorized"]);
    observer.next(true);
  }

  // private checkUrlAndType(observer, state) {

  // the user is logged and we have the user info, so check the url

  // if (state.url.startsWith("/admin/") && this.loginService.userInfo.type == UserType.Admin) {
  //   observer.next(true);
  // } else if (state.url.startsWith("/employee/") && this.loginService.userInfo.type == UserType.Employee) {
  //   observer.next(true);
  // } else if (state.url.startsWith("/root/") && this.loginService.userInfo.type == UserType.Root) {
  //   observer.next(true);
  // } else if (state.url == "auth/logout") {
  //   observer.next(true);
  // } else if (state.url == "auth/login") {
  //   if (this.loginService.userInfo.type == UserType.Admin) {
  //     this.router.navigate(["/admin/"]);
  //   } else if (this.loginService.userInfo.type == UserType.Employee) {
  //     this.router.navigate(["/employee/"]);
  //   } else if (this.loginService.userInfo.type == UserType.Root) {
  //     this.router.navigate(["/root/"]);
  //   }
  //   observer.next(false);
  // } else {
  //   this.router.navigate(["auth/unauthorized"]);
  //   observer.next(false);
  // }
  //   observer.next(true);
  // }
}

