import { Component, OnInit, EventEmitter, Output, Input, ElementRef, ViewChild, AfterViewInit, HostListener } from '@angular/core';
import { SelectItem } from "./select.item.interface";

@Component({
    selector: 'app-select',
    templateUrl: 'select.template.html',
    styleUrls: ['./select.style.css'],
    // host: {
    //     '(document:click)': 'onClick($event)',
    // },
})

export class AppSelectComponent implements OnInit, AfterViewInit {
    @ViewChild("searchField", { static: true }) searchField;

    @Output() onSelect: EventEmitter<any> = new EventEmitter();
    @Output() onTyping: EventEmitter<any> = new EventEmitter();
    @Output() onFocus: EventEmitter<any> = new EventEmitter();
    @Output() onLoseFocus: EventEmitter<any> = new EventEmitter();
    @Output() selectedItemChange: EventEmitter<any> = new EventEmitter();
    @Output() itemsChange: EventEmitter<any> = new EventEmitter();

    @Input() disabled: boolean = false;
    @Input() placeholder: string = "Select a value";
    @Input() empty: string = "Empty";
    @Input() useStartswith: boolean = false;

    private privateitems: SelectItem[] = [];
    @Input()
    get items() {
        return this.privateitems;
    }
    set items(val) {
        this.activatedItem = null;
        this.privateitems = val;
        this.itemsChange.emit(this.privateitems);
    }

    private activatedItem: SelectItem = null;
    public search: string = "";
    public isActive: boolean = false;

    @Input()
    get selectedItem() {
        return this.activatedItem;
    }
    set selectedItem(val) {
        this.activatedItem = val;
        this.selectedItemChange.emit(this.activatedItem);
    }

    @HostListener('click', ['$event'])
    public onClick(event) {
        if (this.isActive) {
            if (!this._eref.nativeElement.contains(event.target)) {
                this.setInactive();
                this.onLoseFocus.emit();
                this.resetAll();
            }
        }
        // else {
        //     this.setActive();
        // }
    }

    public resetAll() {
        this.search = "";
        this.items.forEach(x => x.hide = false);
    }

    public select(item) {
        if (item) {
            this.selectedItem = item
        }
        this.setInactive();
        this.onSelect.emit(item);
    }

    public typing(event) {
        this.search = event;
        this.items.forEach(x => x.hide = true)
        if (this.useStartswith)
            this.items.filter(x => x.text.toLowerCase().startsWith(this.search.toLowerCase())).forEach(x => x.hide = false);
        else
            this.items.filter(x => x.text.toLowerCase().indexOf(this.search.toLowerCase()) >= 0).forEach(x => x.hide = false);
        this.onTyping.emit(event);
    }

    public setActive() {
        if (!this.disabled) {
            this.isActive = true;
            this.onFocus.emit();

            this.searchField.nativeElement.focus();
        }
    }

    public setInactive() {
        this.isActive = false;
    }

    public searchCount() {
        if(this.items.length > 0)
            return this.items.filter(x => !x.hide).length;
        return 0;
    }

    constructor(private _eref: ElementRef) { }

    ngOnInit() { }

    ngAfterViewInit() { }
}
