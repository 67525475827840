import { AfterViewInit, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AppHighstockComponent } from 'app/components/common/highcharts/highstock/highstock.component';
import { BuildingService } from 'app/services/building.service';
import { AdminPointService } from 'app/services/data.service';
import { SpinnerService } from 'app/services/spinner.service';
import * as moment from 'moment';

@Component({
  selector: 'app-all-chart',
  templateUrl: './all-chart.component.html',
  styleUrls: ['./all-chart.component.css']
})
export class AllChartComponent implements AfterViewInit {

  constructor(private pointsService: AdminPointService, private spinner: SpinnerService, public dialogRef: MatDialogRef<AllChartComponent>, @Inject(MAT_DIALOG_DATA) public data) { }

  public pointsEnabled: string[] = [];

  public options = {
    plotOptions: {
      series: {
        events: {
          legendItemClick: (event) => {
            if (!this.pointsEnabled.find(x => x == event.target.userOptions.id)) {
              this.spinner.activate();
              this.pointsService.GetDataForPoint({ building: this.data.building, point: event.target.userOptions.id, from: this.data.from, to: this.data.to }).subscribe(x => {
                this.spinner.desactivate();

                this.pointsEnabled.push(event.target.userOptions.id);

                let d = x.data[0];
                let start = moment(d.timestamp);
                let s = []

                if (d) {
                  for (let i = 1; i <= 7; i++) {
                    for (let j = 0; j < 1440; j++) {
                      if (d.weekPointData[i.toString()] && d.weekPointData[i.toString()][j] != null) {
                        s.push([start.valueOf(), d.weekPointData[i.toString()][j]]);
                      }
                      // else {
                      //   s.push([start.valueOf(), null]);
                      // }
                      start.add(1, "minute");
                    }
                  }
                  this.chart.updateSeries({ id: event.target.userOptions.id, data: s }, event.target.userOptions.id);
                }

              })
            }
          }
        }
      }
    }
  }

  @ViewChild("chart") chart: AppHighstockComponent;

  ngAfterViewInit(): void {
    for (let s of this.data.values.data) {
      this.chart.updateSeries(s, s.id, false, false)
      if (s.visible) {
        this.pointsEnabled.push(s.id);
      }
    }

    this.chart.redraw();
  }


}
