import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { isArray } from 'app/app.helpers';
import { SearchPopupComponent } from 'app/components/common/search-popup/search-popup.component';
import { BuildingService } from 'app/services/building.service';
import { FaultsService } from 'app/services/faults.service';
import { PointgroupsService } from 'app/services/pointgroups.service';
import { SessionService } from 'app/services/session.service';
import { SpinnerService } from 'app/services/spinner.service';
import { SweetalertService } from 'app/services/sweetalert.service';
import { UserService } from 'app/services/user.service';


import * as moment from "moment-timezone";
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

@Component({
  selector: 'app-faults-create',
  templateUrl: './faults-create.component.html',
  styleUrls: ['./faults-create.component.css']
})



export class FaultsCreateComponent implements OnInit {

  public issueenum = [1, -1];
  public severityenum = [1, 5, 10]

  public form: FormGroup = new FormGroup({
    date: new FormControl(moment.now(), [Validators.required]),
    subject: new FormControl("", [Validators.required, Validators.maxLength(60)]),
    severity: new FormControl(1, [Validators.required]),
    issue: new FormControl('asset', [Validators.required]),
    asset: new FormControl(''),
    description: new FormControl("", [Validators.required, Validators.maxLength(1500)]),
    assignTo: new FormControl("", []),
    assignToId: new FormControl("", []),
    building: new FormControl("", [Validators.required])
  })

  public buildings: any[] = [];

  public today = moment();

  public contacts: Observable<{ _id: string, name: string, email: string }[]>;
  private allContacts: any[] = [];

  constructor(private sessionService: SessionService, private router: Router, private transloco: TranslocoService, private alert: SweetalertService, private userService: UserService, private spinner: SpinnerService, private pointsGroupService: PointgroupsService, public dialog: MatDialog, private faultsService: FaultsService, private buildingService: BuildingService) { }

  private _filterContacts(value: string): any[] {
    const filterValue = value.toLowerCase();

    this.form.get('assignToId').setValue("");

    return this.allContacts.filter(x => x.email.toLowerCase().indexOf(filterValue) >= 0 || x.person.name.toLowerCase().indexOf(filterValue) >= 0);
  }

  public openAssets() {
    if (this.form.get("building").value != "" && this.form.get("issue").value == 1) {
      const dialogRef = this.dialog.open(SearchPopupComponent, {
        width: '600px',
        data: {
          buttons: { ok: "Clear", cancel: "Cancel" },
          single: true,
          searchCallback: this.refreshPoints.bind(this),
          displayField: "name",
          valueField: "_id"
        }
      })

      dialogRef.afterClosed().subscribe(x => {

        console.log(x);
        if (x) {
          if (isArray(x)) {
            this.form.get("asset").setValue("");
            this.assetName = "";
          }
          else {
            this.form.get("asset").setValue(x._id);
            this.assetName = x.name;
          }
        }

      })
    }
  }

  public refreshPoints(filter: string, pageOptions: { page: number, pageSize: number }): Observable<any> {
    return new Observable(observer => {
      this.spinner.activate();
      this.pointsGroupService.pointGroups(1, pageOptions.page, pageOptions.pageSize, filter, this.form.get("building").value).subscribe(res => {
        this.spinner.desactivate();

        observer.next(res.data);
        observer.complete();
      })
    })
  }


  public assetName = "";

  public issueChange() {
    let asset = this.form.get('asset');
    let assetName = this.form.get('assetName')

    if (this.form.get("issue").value == 1) {
      asset.setValidators([Validators.required]);
      asset.setValue("");
      this.assetName = "Select asset"
      // assetName.enable();
      console.log(assetName);
    }
    else {
      asset.setValidators([]);
      asset.setValue("");
      this.assetName = "";
      // assetName.disable();
    }
  }

  public assignTo(value) {
    this.form.get('assignToId').setValue(this.allContacts.find(x => x.email == value.option.value.split("(")[1].split(")")[0])._id);

    console.log(this.form.value);
  }

  public buildingChange() {
    this.spinner.activate();
    this.buildingService.getMaintenanceUsers(this.form.get("building").value).subscribe(x => {
      this.allContacts = x.data;
      this.spinner.desactivate();
    })
  }

  ngOnInit(): void {
    this.spinner.activate();
    this.buildingService.getAll().subscribe(x => {
      this.spinner.desactivate();
      this.buildings = x.data;

      this.form.get("building").setValue(this.sessionService.building);
    })



    this.contacts = this.form.get("assignTo").valueChanges.pipe(
      startWith(''),
      map(value => this._filterContacts(value))
    );
  }


  public save() {
    if (this.form.valid) {
      this.spinner.activate();

      let values = this.form.value;

      let data = {
        description: values.description,
        subject: values.subject,
        assign: values.assignToId,
        severity: values.severity,
        type: values.issue,
        date: values.date.valueOf(),
        asset: values.asset
      }

      this.sessionService.building = values.building;

      this.buildingService.createFault(values.building, data).subscribe(x => {
        this.spinner.desactivate();

        this.alert.success(this.transloco.translate('messages.success'), this.transloco.translate('messages.created', { entity: this.transloco.translate('entities.fault') }));
        this.router.navigate(['admin', 'faults']);
      })
    }
  }

}
