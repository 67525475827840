import { Component, OnInit } from "@angular/core";
import { UserService } from "app/services/user.service";
import { SpinnerService } from "app/services/spinner.service";
import { Router } from "@angular/router";
import { SweetalertService } from "app/services/sweetalert.service";
import { User } from "app/interfaces/user";
import { LoginService } from "app/services/login.service";
import { TranslocoService } from "@ngneat/transloco";
import { HelpPageService } from "app/services/help-page.service";
import { AppService } from "app/services/app.service";

@Component({
  selector: "app-admin-create-user",
  templateUrl: "./admin-create-user.component.html",
  styleUrls: ["./admin-create-user.component.css"]
})
export class AdminCreateUserComponent implements OnInit {

  constructor(private appService: AppService, private helppageService: HelpPageService, private userService: UserService, private spinnerService: SpinnerService, private router: Router, private translocoService: TranslocoService,
    private alert: SweetalertService, private loginService: LoginService) {
  }

  ngOnInit() {
    this.helppageService.changeUrl("./assets/help-pages/dashboard.html?language=" + this.appService.getLanguage());
  }

  public save(event) {
    event.value = event.getRawValue()
    let user: User = {
      company: this.loginService.userInfo.company,
      email: event.value.primaryEmail,
      person: {
        country: event.value.country,
        city: event.value.city,
        landline: event.value.landline,
        contacts: {
          emails: event.value.emails,
          telephones: event.value.telephones
        },
        primaryEmail: event.value.primaryEmail,
        name: event.value.name,
        mobilePhone: event.value.mobilephone
      },
      type: event.value.type,
      username: event.value.primaryEmail
    };

    this.spinnerService.activate();

    this.userService.create(user).subscribe(x => {
      this.spinnerService.desactivate();
      this.alert.success(this.translocoService.translate('messages.success'),
        this.translocoService.translate('messages.created', { entities: this.translocoService.translate("entities.user") }));
      this.router.navigate(["/admin/users"]);
    },
      err => {
        console.log(err);
      });
  }

}
