import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { BuildingService } from 'app/services/building.service';
import { SweetalertService } from 'app/services/sweetalert.service';
import { AppService } from 'app/services/app.service';
import { SpinnerService } from 'app/services/spinner.service';
import { ActivatedRoute } from '@angular/router';
import { TagMapsComponent } from 'app/components/common/tag-maps-form/tag-maps.component';
import { deepCompareObj } from 'app/app.helpers';
import { TranslocoService } from '@ngneat/transloco';

@Component({
  selector: 'app-admin-tag-maps',
  templateUrl: './admin-tag-maps.component.html',
  styleUrls: ['./admin-tag-maps.component.css']
})
export class AdminTagMapsComponent implements OnInit, AfterViewInit {

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  private tagMapToEdit = null;

  public dataSource = new MatTableDataSource();
  public buildingId: string;
  public displayedColumns = ["name", "type", "condition", "lookup", "tags", "flag", "operations"];

  constructor(private transloco: TranslocoService, private buildingService: BuildingService, public appService: AppService, private spinnerService: SpinnerService,
    private alert: SweetalertService, private route: ActivatedRoute, public dialog: MatDialog) { }

  ngOnInit() {
    this.spinnerService.activate();
    this.buildingService.getAll().subscribe(b => {
      this.buildings = b.data;

      this.route.queryParams.subscribe(x => {
        this.buildingId = x["building"];

        this.spinnerService.desactivate();
        this.refresh();
      })
    })
  }

  public buildings: any[] = [];

  public deleteUndo(id) {
    this.alert.custom({
      title: "Warning", text: "Do you want to delete this rule and remove all tags and flags applied?", buttons: {
        yes: {
          text: "Yes",
          value: "yes"
        },
        exit: {
          text: "Cancel",
          value: "cancel"
        },
      },
      icon: "warning",

    }).then(x => {
      console.log(x);
      if (x == "cancel") {

      }
      else if (x == "yes") {
        this.spinnerService.activate();
        this.buildingService.deleteTagMap(this.buildingId, id, true).subscribe(x => {
          this.spinnerService.desactivate();
          this.refresh();
        })
      }
    })
  }

  public pagination: { hasNext: boolean, page: number, pageSize: number } = { hasNext: false, page: 1, pageSize: 100 }
  public changePage(event) {

    this.pagination.page = event.page;
    this.pagination.pageSize = event.pageSize;

    this.refresh();
  }

  private filter: string = "";
  public applyFilter(value) {
    this.filter = value;
    this.pagination.page = 1;

    this.refresh();
  }

  public refresh() {
    this.spinnerService.activate();

    this.buildingService.getTagMaps(this.buildingId, this.filter, this.pagination.page, this.pagination.pageSize).subscribe(
      results => {
        this.pagination.hasNext = results.data.length > this.pagination.pageSize;
        this.dataSource.data = results.data.map;
        this.spinnerService.desactivate();
      },
      err => {
        this.spinnerService.desactivate();
      }
    );
  }

  public delete(id) {
    this.alert.custom({
      title: this.transloco.translate("messages.warning"), text: this.transloco.translate("messages.doyouwanttocontinue"), buttons: {
        yes: {
          text: this.transloco.translate("messages.yes"),
          value: "yes"
        },
        exit: {
          text: this.transloco.translate("messages.cancel"),
          value: "cancel"
        },
      },
      icon: "warning",

    }).then(x => {
      console.log(x);
      if (x == "cancel") {

      }
      else if (x == "yes") {
        this.spinnerService.activate();
        this.buildingService.deleteTagMap(this.buildingId, id, false).subscribe(x => {
          this.spinnerService.desactivate();
          this.refresh();
        })
      }
    })
  }

  openCreateDialog(): void {
    const dialogRef = this.dialog.open(TagMapsComponent, {
      width: '500px',
      data: {}
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.spinnerService.activate();
        this.buildingService.createTagMap(this.buildingId, result.value).subscribe(response => {

          this.spinnerService.desactivate();

          this.alert.success(this.transloco.translate("messages.success"),
            this.transloco.translate("messages.created", { entity: this.transloco.translate("entities.rule") }));

          this.refresh();
        })
      }
    });
  }

  openEditDialog(index: number): void {
    this.tagMapToEdit = this.dataSource.data.filter((x: any) => x._id == index)[0];
    const dialogRef = this.dialog.open(TagMapsComponent, {
      width: '500px',
      data: { tagMap: this.tagMapToEdit }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        let t = deepCompareObj(this.tagMapToEdit, result.value.entity);

        this.spinnerService.activate();
        this.buildingService.updateTagMap(this.buildingId, { entity: t, applyToExistingPoints: result.value.applyToExistingPoints }, this.tagMapToEdit._id).subscribe(x => {
          this.spinnerService.desactivate();

          this.alert.success(this.transloco.translate("messages.success"), this.transloco.translate("messages.updated", { entity: this.transloco.translate("entities.rule") }));

          this.refresh();
        });
      }
    });
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  public deleteAll() {
    this.alert.custom({
      title: this.transloco.translate("messages.warning"), text: this.transloco.translate("messages.doyouwanttocontinue"), buttons: {
        yes: {
          text: this.transloco.translate("messages.yes"),
          value: "yes"
        },
        exit: {
          text: this.transloco.translate("messages.cancel"),
          value: "cancel"
        },
      },
      icon: "warning",

    }).then(x => {
      console.log(x);
      if (x == "cancel") {

      }
      else if (x == "yes") {

        this.spinnerService.activate();
        this.buildingService.deleteAllTagMaps(this.buildingId, false).subscribe(x => {
          this.spinnerService.desactivate();
          this.refresh();
        })
      }
    })
  }

}
