import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, ObservableLike } from 'rxjs';
import { take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class QueryparamsService {

  constructor(private router: Router, private location: Location, private activatedRoute: ActivatedRoute) { }

  public AddParam(param: string, value: any): Observable<any> {
    return new Observable(res => {
      this.activatedRoute.queryParams.subscribe(params => {
        var newParams = Object.assign({}, params);
        newParams[param] = value;
        const url = this.router.createUrlTree([], { relativeTo: this.activatedRoute, queryParams: newParams }).toString()

        // this.location.replaceState(url);

        res.next();
        res.complete();
      })
    })
  }

  public ResetParams() {
    const url = this.router.createUrlTree([], { relativeTo: this.activatedRoute, queryParams: {} }).toString()

    this.location.replaceState(url);
  }

  public ReplaceParams(otherParams: any) {
    return new Observable(res => {
      let sanitizedParams = {};
      for (let key of Object.keys(otherParams)) {
        if (otherParams[key] != undefined || otherParams[key] != null) {
          sanitizedParams[key] = otherParams[key];
        }
      }
      //Angular bug: no complete here, use ALWAYS take(1) or face query params being passed around routes
      this.activatedRoute.queryParams.pipe(take(1)).subscribe(params => {
        var oldParams = Object.assign({}, params);

        var newParams = Object.assign(oldParams, sanitizedParams);

        let tree = this.router.createUrlTree([], { relativeTo: this.activatedRoute, queryParams: newParams });
        const url = this.router.createUrlTree([], { relativeTo: this.activatedRoute, queryParams: newParams }).toString()

        // this.location.replaceState(url);

        res.next();
        res.complete();

      });
    });
  }

  public hasParams(): Observable<any> {
    return new Observable(res => {
      this.activatedRoute.queryParams.subscribe(params => {
        res.next(Object.keys(params).length > 0);
        res.complete();
      })
    })
  }

  public params(): Observable<any> {
    return new Observable(res => {
      this.activatedRoute.queryParams.subscribe(params => {
        res.next(params);
        res.complete();
      });
    })
  }
}
