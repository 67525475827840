import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogData } from 'app/components/common/map-popup/map-popup.component';

@Component({
  selector: 'app-faults-resolved',
  templateUrl: './faults-resolved.component.html',
  styleUrls: ['./faults-resolved.component.css']
})
export class FaultsResolvedComponent implements OnInit {

  public form: FormGroup = new FormGroup({
    description: new FormControl("", [Validators.required])
  })

  constructor(@Inject(MAT_DIALOG_DATA) public data: DialogData, public dialogRef: MatDialogRef<FaultsResolvedComponent>) { }

  ngOnInit(): void {
  }

  public save(){
    this.dialogRef.close(this.form.value);
  }

}
