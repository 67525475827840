import { Component, OnInit, Input, ViewChild } from '@angular/core';

import * as moment from "moment-timezone";
import { AppHighstockComponent } from '../highcharts/highstock/highstock.component';

@Component({
  selector: 'app-advanced-chart',
  templateUrl: './advanced-chart.component.html',
  styleUrls: ['./advanced-chart.component.css']
})
export class AdvancedChartComponent implements OnInit {


  public offsetSeriesTime: any[] = [];
  public offsetSeriesValue: any[] = [];

  public originalData: any[] = [];

  public selection: { name: string, to: string, from: string, yAxis: string, id: string } = {
    name: "new band",
    id: "",
    from: "",
    to: "",
    yAxis: "",
  }

  public savedAxis: { name: string, id: string }[] = [];

  constructor() { }

  ngOnInit() {
  }

  public xAxis: { type: string, plotLines: any[], plotBands: any[] } = {
    type: 'datetime',
    plotBands: [],
    plotLines: []
  }

  public labelValue: string = "";


  public chartOptions = {
    chart: {
      type: 'xrange',
      zoomType: "x",
      height: 600,
      events: {
        click: (event) => {
          this.cleanLines();

          if (this.enablePlotline) {
            if (this.newPlotLine.yAxis == "") {
              let date = moment.utc(event.xAxis[0].value);
              this.newPlotLine.value = date.valueOf()
              this.labelValue = moment.utc(event.xAxis[0].value).format("DD/MM/YYYY HH:mm Z");

              this.xAxis.plotLines.push(this.newPlotLine);

              this.chart.updateXaxis(this.xAxis);
            }
            else {
              let ax = event.yAxis.find(x => x.axis.options.id == this.newPlotLine.yAxis);
              let v = Math.round(ax.value * 100) / 100;

              this.labelValue = v + "";

              this.newPlotLine.value = v;

              ax = this.axis.find(x => x.id == this.newPlotLine.yAxis);
              ax.plotLines.push(this.newPlotLine);

              this.chart.updateYaxis(ax, ax.id);
            }
          }
        },
        selection: (event) => {

          this.clenaSelection();

          if (this.selectionEnabled != "") {

            if (this.selection.yAxis == "") {
              this.selection.to = moment.utc(event.xAxis[0].max).format("DD/MM/YYYY HH:mm Z");
              this.selection.from = moment.utc(event.xAxis[0].min).format("DD/MM/YYYY HH:mm Z");

              this.bandSelection.from = event.xAxis[0].min;
              this.bandSelection.to = event.xAxis[0].max;


              this.xAxis.plotBands.push(this.bandSelection);

              this.chart.updateXaxis(this.xAxis);
            }
            else {
              let ax = event.yAxis.find(x => x.axis.options.id == this.selection.yAxis);

              this.selection.to = Math.round(ax.max * 100) / 100 + "";
              this.selection.from = Math.round(ax.min * 100) / 100 + "";

              this.bandSelection.from = Math.round(ax.min * 100) / 100;
              this.bandSelection.to = Math.round(ax.max * 100) / 100;

              ax = this.axis.find(x => x.id == this.selection.yAxis);
              ax.plotBands.push(this.bandSelection);

              this.chart.updateYaxis(ax, ax.id);
            }

            return false;
          }
        }
      }
    },
    xAxis: this.xAxis,
    title: {
      text: ""
    },
    tooltip: {
      shared: true,
      split: false,
    },
    navigator: {
      enabled: true
    },
    rangeSelector: {
      buttons: [{
        type: 'hour',
        count: 1,
        text: '1h'
      }, {
        type: 'day',
        count: 1,
        text: '1d'
      }, {
        type: 'month',
        count: 1,
        text: '1m'
      }, {
        type: 'year',
        count: 1,
        text: '1y'
      }, {
        type: 'all',
        text: 'All'
      }],
      buttonPosition: {
        align: 'right'
      },
      inputEnabled: false, // it supports only days
      selected: 4 // all
    },
    plotOptions: {
      xrange: {
        minPointLength: "5"
      },
      series: {
        events: {
          click: (event) => {
            console.log(event);
          }
        }
      }
    },
    yAxis: [],
    series: []
  }

  public bandSelection = {
    color: "#CECECE",
    id: "bandSelection",
    from: 0,
    to: 0,
    label: {
      align: "left",
      text: "new band"
    }
  }

  @ViewChild("chart", { static: true }) chart: AppHighstockComponent;

  public changeSelectionType() {
    this.clenaSelection();

    this.selection.from = "";
    this.selection.to = "";

    if (this.selection.yAxis == "") {
      this.chart.updateChart({ chart: { zoomType: "x" } });
    }
    else {
      this.chart.updateChart({ chart: { zoomType: "y" } });
    }
  }

  public clenaSelection() {
    let t = this.xAxis.plotBands.find(x => x.id == this.bandSelection.id);
    if (t) {
      this.xAxis.plotBands.splice(this.xAxis.plotBands.indexOf(t), 1);

      this.chart.updateXaxis(this.xAxis);
      return;
    }


    for (let ax of this.axis) {
      let x = ax.plotBands.find(x => x.id == this.bandSelection.id);
      if (x) {
        ax.plotBands.splice(x, 1);

        this.chart.updateYaxis(ax, ax.id);
        return;
      }
    }
  }


  public steps: any[] = [];
  public axisStep: any[] = [];

  public _axis: { plotBands: any[], plotLines: any[], showTitle: boolean, id: string, name: string, title: { text: string }, min: number, max: number, opposite: boolean, reversed: boolean, visible: boolean }[] = [];
  get axis() {
    return this._axis;
  }

  private _plotbands: { zIndex: number, id: string, yAxis: string, color: string, from: any, to: any, label: { text: string, align: string } }[] = []
  get plotbands() {
    return this._plotbands;
  }

  private _plotlines: { zIndex: number, id: string, yAxis: string, color: string, dashStyle: string, value: any, width: number, label: { text: string, align: string } }[] = [];
  get plotlines() {
    return this._plotlines;
  }

  private _series: Series[] = [];
  get series() {
    return this._series;
  }

  public saveAxis() {
    this.savedAxis = [];

    for (let ax of this.axis) {
      this.savedAxis.push({ name: ax.name, id: ax.id });

      if (ax.showTitle) {
        ax.title.text = ax.name;
      }
      else {
        ax.title.text = null;
      }
      this.chart.updateYaxis(ax, ax.id)
    }
  }

  public saveSeries() {
    for (let s of this.series) {

      s.data = [];

      let t = [];

      for (let d of this.originalData[s.id]) {
        t.push([d[0], d[1]]);
      }

      if (this.offsetSeriesTime[s.id] != 0) {
        for (let d of t) {
          d[0] = d[0] + 1000 * 60 * this.offsetSeriesTime[s.id];
        }
      }


      if (this.offsetSeriesValue[s.id] != 0) {
        for (let d of t) {
          if (d[1] != null) {
            d[1] = d[1] + this.offsetSeriesValue[s.id];
          }
        }
      }

      s.data = t;

      this.chart.updateSeries(s, s.id)
    }
  }


  public removeAxis(id) {
    this.savedAxis.splice(this.savedAxis.indexOf(this.savedAxis.find(x => x.id == id)), 1);
    this.axis.splice(this.axis.indexOf(this.axis.find(x => x.id == id)), 1);
    this.chart.removeYAxis(id);
  }

  public removePoint(pointId: string) {

    if (this.chart.getSeries(pointId)) {
      this.chart.getSeries(pointId).remove();

      this.offsetSeriesTime[pointId] = undefined;
      this.offsetSeriesValue[pointId] = undefined;

      let t = this.series.find(x => x.id == pointId);
      this.series.splice(this.series.indexOf(t), 1);
      // this.saveSeries();
    }

    return true;
  }

  public addAxis() {
    let id = Math.random().toString().substr(2, 10);
    this._axis.push({
      id: id,
      showTitle: false,
      plotLines: [],
      plotBands: [],
      title: {
        text: ""
      },
      name: "New Axis(" + (this.axis.length + 1) + ")",
      min: null,
      max: null,
      opposite: false,
      reversed: false,
      visible: true
    })

    this.axisStep[id] = 0;
  }


  public addPoint(point: Series): boolean {
    if (this.series.find(x => x.id == point.id)) {
      this.updatePoint(point);

      return true;
    }
    if (this.axis.length == 0) {
      this.addAxis();
      this.saveAxis();
    }
    point.yAxis = this.axis[0].id;

    this.offsetSeriesTime[point.id] = 0;
    this.offsetSeriesValue[point.id] = 0;

    this.originalData[point.id] = point.data;

    this._series.push(point);
    this.steps[point.id] = 0;

    this.saveSeries();


    return true;
  }

  public updatePoint(point: Series): boolean {
    let t = this.series.find(x => x.id == point.id);
    if (t == undefined || t == null) {
      return false;
    }
    t.data = point.data;
    this.chart.updateSeries(t, t.id);
    return true;
  }

  //Plot bands
  public savePlotbands() {
    for (let plot of this.plotbands) {
      //remove plotband from any yAxis and xAxys
      let saveY = false
      for (let ax of this.axis) {
        let t = ax.plotBands.find(x => x.id == plot.id);
        if (t) {
          ax.plotBands.splice(ax.plotBands.indexOf(t), 1);
          saveY = true;
        }
      }

      let saveX = false;
      let t = this.xAxis.plotBands.find(x => x.id == plot.id);
      if (t) {
        this.xAxis.plotBands.splice(this.xAxis.plotBands.indexOf(t), 1);
        saveX = true;
      }

      //add to any yAxis
      if (plot.yAxis != "") {
        this.axis.find(x => x.id == plot.yAxis).plotBands.push(plot);
        saveY = true;
      }
      //add to xAxis
      else {
        this.xAxis.plotBands.push({
          color: plot.color,
          id: plot.id,
          from: moment.utc(plot.from).valueOf(),
          label: { align: plot.label.align, text: plot.label.text },
          to: moment.utc(plot.to).valueOf(),
          zIndex: plot.zIndex
        });
        saveX = true;
      }


      if (saveY) {
        this.saveAxis();
      }
      if (saveX) {
        this.chart.updateXaxis(this.xAxis);
      }
    }
  }

  private focusChart() {
    document.getElementById('chart').scrollIntoView();
  }

  public selectionEnabled: string = "";

  public selectBand(plotID) {
    this.focusChart();
    this.selectionEnabled = plotID;
  }

  public addPlotband() {
    let tid = Math.random().toString().substr(2, 10);
    this.selectBand(tid);
  }

  public createNewBand(id) {

    this._plotbands.push({
      color: this.getRandomColor(),
      id: this.selectionEnabled,
      from: this.bandSelection.from,
      label: {
        align: "left",
        text: "new band"
      },
      to: this.bandSelection.to,
      yAxis: this.selection.yAxis,
      zIndex: 0
    })

    this.savePlotbands();

    this.clenaSelection();
    this.selectionEnabled = "";
  }


  public removePlotband(plotband, clenaSelection = false) {
    this._plotbands.splice(this.plotbands.indexOf(plotband), 1);

    if (clenaSelection) {
      this.clenaSelection();
      this.selectionEnabled = "";
    }

    let t = this._plotbands.find(x => x.id == plotband);
    if (t) {
      this.selectionEnabled = "";
    }

    let saveY = false
    for (let ax of this.axis) {
      let t = ax.plotBands.find(x => x.id == plotband.id);
      if (t) {
        ax.plotBands.splice(ax.plotBands.indexOf(t), 1);
        saveY = true;
      }
    }

    let saveX = false;
    t = this.xAxis.plotBands.find(x => x.id == plotband.id);
    if (t) {
      this.xAxis.plotBands.splice(this.xAxis.plotBands.indexOf(t), 1);
      saveX = true;
    }

    if (saveY) {
      this.saveAxis();
    }
    if (saveX) {
      this.chart.updateXaxis(this.xAxis);
    }
  }

  //Plot lines
  public savePlotlines() {
    for (let plot of this.plotlines) {
      //remove plotline from any yAxis and xAxys
      let saveY = false
      for (let ax of this.axis) {
        let t = ax.plotLines.find(x => x.id == plot.id);
        if (t) {
          ax.plotLines.splice(ax.plotLines.indexOf(t), 1);
          saveY = true;
        }
      }

      let saveX = false;
      let t = this.xAxis.plotLines.find(x => x.id == plot.id);
      if (t) {
        this.xAxis.plotLines.splice(this.xAxis.plotLines.indexOf(t), 1);
        saveX = true;
      }

      //add to any yAxis
      if (plot.yAxis != "") {
        this.axis.find(x => x.id == plot.yAxis).plotLines.push(plot);
        saveY = true;
      }
      //add to xAxis
      else {
        this.xAxis.plotLines.push({
          color: plot.color,
          id: plot.id,
          value: moment.utc(plot.value).valueOf(),
          label: { align: plot.label.align, text: plot.label.text },
          zIndex: plot.zIndex,
          width: plot.width,
          dashStyle: plot.dashStyle
        });
        saveX = true;
      }


      if (saveY) {
        this.saveAxis();
      }
      if (saveX) {
        this.chart.updateXaxis(this.xAxis);
      }
    }
  }

  public cleanLines() {
    let t = this.xAxis.plotLines.find(x => x.id == this.newPlotLine.id);
    if (t) {
      this.xAxis.plotLines.splice(this.xAxis.plotLines.indexOf(t), 1);

      this.chart.updateXaxis(this.xAxis);
      return;
    }


    for (let ax of this.axis) {
      let x = ax.plotLines.find(x => x.id == this.newPlotLine.id);
      if (x) {
        ax.plotLines.splice(x, 1);

        this.chart.updateYaxis(ax, ax.id);
        return;
      }
    }
  }

  public newPlotLine: { label: any, color: string, id: string, value: number, width: number, yAxis: string } = {
    id: "newPlotline",
    color: "#ff0000",
    value: 0,
    yAxis: "",
    width: 2,
    label: {
      align: "left",
      text: "new line"
    }
  }

  public changeLineType() {
    this.cleanLines();

    this.newPlotLine.value = 0;
    this.labelValue = "";
  }

  public enablePlotline: boolean = false;

  public addPlotline() {
    this.focusChart();
    this.enablePlotline = true;
  }

  public createPlotline() {
    this._plotlines.push({
      color: this.getRandomColor(),
      id: Math.random().toString().substr(2, 10),
      dashStyle: "solid",
      label: {
        align: "left",
        text: "new label"
      },
      value: this.newPlotLine.value,
      width: 2,
      yAxis: this.newPlotLine.yAxis,
      zIndex: 0
    })

    this.savePlotlines();

    this.cleanLines();
    this.enablePlotline = false;
  }

  public removePlotline(plotline, cleanLine: boolean = false) {
    this.plotlines.splice(this.plotlines.indexOf(plotline), 1);

    if (cleanLine) {
      this.cleanLines();
      this.enablePlotline = false;
    }

    let saveY = false
    for (let ax of this.axis) {
      let t = ax.plotLines.find(x => x.id == plotline.id);
      if (t) {
        ax.plotLines.splice(ax.plotLines.indexOf(t), 1);
        saveY = true;
      }
    }

    let saveX = false;
    let t = this.xAxis.plotLines.find(x => x.id == plotline.id);
    if (t) {
      this.xAxis.plotLines.splice(this.xAxis.plotLines.indexOf(t), 1);
      saveX = true;
    }

    if (saveY) {
      this.saveAxis();
    }
    if (saveX) {
      this.chart.updateXaxis(this.xAxis);
    }
  }

  public getRandomColor() {
    let letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }

  public types = [
    {
      key: "Area",
      value: "area"
    }, {
      key: "AreaSpline",
      value: "areaspline"
    }, {
      key: "Bar",
      value: "bar"
    }, {
      key: "Line",
      value: "line"
    }, {
      key: "Spline",
      value: "spline"
    }
  ];

}

export interface Series {
  id: string,
  dataGrouping: {
    enabled: boolean
  },
  name: string,
  data: any[],
  type: string,
  color: string,
  connectNulls: boolean,
  yAxis: string,
  zIndex: number
  tooltip: {
    valueDecimals: number,
    valuePrefix: string,
    valueSuffix: string
  }

}

export enum ChartType {
  Area = "area",
  AreaSpline = "areaspline",
  Bar = "bar",
  Line = "line",
  Spline = "spline",

}
