import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { AppI18nService } from 'app/services/app.i18n.service';
import { LoginService } from 'app/services/login.service';
import { UserType } from 'app/interfaces/baseinterface';

@Component( {
  selector: 'app-unauthorized',
  templateUrl: './unauthorized.component.html',
  styleUrls: [ './unauthorized.component.css' ],
  encapsulation: ViewEncapsulation.None
} )
export class UnauthorizedComponent implements OnInit {

  constructor( public i18n: AppI18nService, private loginService: LoginService ) {
  }

  ngOnInit() {
  }

  // public generateLink(): string {
  //   return UserType.Admin.toString();
  // }
}
