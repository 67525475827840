import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslocoService } from '@ngneat/transloco';
import * as moment from 'moment-timezone';
import { Observable } from 'rxjs';
import { AppService } from './app.service';
import { BasicRequestService } from './basic-request.service';
import { CompanyViewService } from './company.view.service';
import { LoginService } from './login.service';
import { SpinnerService } from './spinner.service';

@Injectable({
  providedIn: 'root'
})
export class PointgroupsService extends BasicRequestService {

  constructor(public transloc: TranslocoService, public snack: MatSnackBar, public companyView: CompanyViewService, public http: HttpClient, public appService: AppService, public loginService: LoginService, public spinner: SpinnerService) {
    super(transloc, snack, companyView, http, appService, loginService, spinner)
  }

  //[GET] pointGroups/assetTypes
  public assetTypes(): Observable<any> {
    return this.doGet(`pointGroups/assetTypes`);
  }

  //[GET] pointGroups/{pointGroupId}/weekViolin
  public weekViolin(pointGroupId, date: string = moment().toISOString()): Observable<any> {
    return this.doGet(`pointGroups/${pointGroupId}/weekViolin?date=${date}`);
  }

  //[GET] pointGroups/{pointGroupId}/weekPolar
  public weekPolar(pointGroupId, date: string = moment().toISOString()): Observable<any> {
    return this.doGet(`pointGroups/${pointGroupId}/weekPolar?date=${date}`);
  }

  //[DELETE] /pointGroups
  public deleteAll(assets: number = 0): Observable<any> {
    return this.doDelete(`pointGroups?assets=${assets}`)
  }

  public delete(pointGroupId, deleteFromGroups: number = 0): Observable<any> {
    return this.doDelete(`pointGroups/${pointGroupId}?deleteFromGroups=${deleteFromGroups}`);
  }

  public allPointGroups(type = 1) {
    return this.doGet(`pointGroups?type=${type}`);
  }

  public create(data): Observable<any> {
    return this.doPost(`pointGroups`, { entity: data });
  }

  public update(originalEntity, newEntity): Observable<any> {
    return this.doPatch(`pointGroups/${originalEntity._id}`, { entity: newEntity })
  }

  //[POST] pointGroups/createFromRules
  public createFromRules(building: string, conditions: any, options: { key: string, value: string }[] = [], type: number = undefined, criteriaAt = undefined): Observable<any> {
    let entity = {
      assetAt: criteriaAt,
      assetType: type,
      building: building,
      condition: conditions,
    };

    for (let option of options) {
      if (option.value)
        entity[option.key] = option.value;
    }

    return this.doPost(`/pointGroups/createFromRules`, { entity: entity })
  }

  //[POST] /pointGroups/getFromRules
  public getFromRules(building: string, conditions: any, page: number = 1, pageSize: number = 50, fullText: string = "", assetAtSummary: number = -1, options: { key: string, value: string }[] = [], type: number = undefined, criteriaAt = undefined): Observable<any> {
    let entity =
    {
      assetAtSummary: (assetAtSummary >= 0) ? assetAtSummary : undefined,
      assetAt: criteriaAt ? criteriaAt : undefined,
      assetType: type,
      building: building,
      condition: conditions,
      page: page,
      pageSize: pageSize,
      fullText: fullText
    };

    for (let option of options) {
      if (option.value)
        entity[option.key] = option.value;
    }

    return this.doPost(`/pointGroups/getFromRules`, { entity: entity });
  }

  public pointGroups(type = 1, page: number = 1, pageSize: number = 100, fullText: string = null, building: string = "", qrCodeLocked: string = "", points?: string[]): Observable<any> {

    return this.doGet(`pointGroups?type=${type}&page=${page}&pageSize=${pageSize}&building=${building}&fullText=${fullText}&qrCodeLocked=${qrCodeLocked}&points=${points.join("&points=")}`);
  }

  public getPointGroup(pointGroupId: string, pointFromSubGroup?: number, pointFilter?: string): Observable<any> {
    if (pointFromSubGroup) {
      return this.doGet(`pointGroups/${pointGroupId}?pointFromSubGroup=${pointFromSubGroup}&pointFilter=${pointFilter}`);
    }
    else {
      return this.doGet(`pointGroups/${pointGroupId}`);
    }
  }

  public violin(pointGroupId: string, date: string, threshold: number = 1.5): Observable<any> {
    return this.doGet(`pointGroups/${pointGroupId}/violin?threshold=${threshold}&date=${date}`);
  }
}
