import { Component, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HighchartsComponent } from 'app/components/common/highcharts/highcharts/highcharts.component';
import { AppHighstockComponent } from 'app/components/common/highcharts/highstock/highstock.component';
import { PointgroupsService } from 'app/services/pointgroups.service';
import { SpinnerService } from 'app/services/spinner.service';
import { SweetalertService } from 'app/services/sweetalert.service';
import * as moment from 'moment-timezone';
import { Observable, timer } from 'rxjs';

@Component({
  selector: 'app-admin-violin',
  templateUrl: './admin-violin.component.html',
  styleUrls: ['./admin-violin.component.css']
})
export class AdminViolinComponent implements OnInit {

  @ViewChildren("chart") chart: QueryList<HighchartsComponent>;

  constructor(private alert: SweetalertService, private spinner: SpinnerService, private route: ActivatedRoute, private groupService: PointgroupsService) { }

  public violins: number[] = [];

  public date: any = moment.utc().subtract(1, "day");
  public maxDate = moment.utc().subtract(1, "day");
  public threshold: number = 1.5;
  public data: any;

  public id: string = "";

  public metadata = null;

  public colors: { code: string, name: string }[] = [
    { code: "#770606", name: "Group Worst" },
    { code: "#777706", name: "Outside of Group Average" },
    { code: "#0606CD", name: "Group Average" },
    { code: "#0ABB0A", name: "Better then Group Average" },
    { code: "#067706", name: "Group Best" },
  ]

  private colorFromSD(sd: number, sdQ2: number, limit: number) {
    if (sd > limit || sdQ2 > limit) {
      return { fromRange: sd > limit, fromMedian: sdQ2 > limit, color: "#770606" };
    }
    if (sd > 1 || sdQ2 > 1) {
      return { fromRange: sd > 1, fromMedian: sdQ2 > 1, color: "#777706" };
    }
    if (sd < -limit || sdQ2 < -limit) {
      return { fromRange: sd < -limit, fromMedian: sdQ2 < -limit, color: "#067706" };
    }
    if (sd < -1 || sdQ2 < -1) {
      return { fromRange: sd < -1, fromMedian: sdQ2 < -1, color: "#0ABB0A" };
    }
    return { fromRange: false, fromMedian: false, color: "#0606CD" };
  }

  public updateColors() {

  }

  public refresh() {

    this.spinner.activate();

    this.groupService.violin(this.id, moment(this.date).toISOString(), this.threshold).subscribe(d => {

      this.spinner.desactivate();
      if (d.data.length == 0) {
        this.alert.info("Information", "Failed to generate violins plots due to insufficient data.");
      }
      else {

        if (d.data.length >= 10) {
          this.metadata = d.metadata;
          this.metadata.meanMetadata.avg = Math.floor(this.metadata.meanMetadata.avg * 100) / 100;
          this.metadata.total = 0;
        }

        this.data = d;
        this.violins = [];

        for (let i = 0; i < d.data.length; i++) {
          this.violins.push(i);

          // console.log(d.data[i].series);
          if (d.data.length >= 10)
            this.metadata.total += d.data[i].series.length / 2;
        }

        this.fcus = [];

        let timer = Observable.timer(200).subscribe(t => {

          for (let i = 0; i < d.data.length; i++) {
            let arrayCharts = this.chart.toArray()[i];

            while (arrayCharts.Chart.series.length) {
              arrayCharts.Chart.series[0].remove(false);
            }


            arrayCharts.updateChart({
              title: {
                enabled: false,
                text: ''
              },
              xAxis: {
                gridLineWidth: 2,
                tickInterval: 0.5
              },
              tooltip: {
                shared: true
              },
              yAxis: {
                title: { enabled: false },
                tickInterval: 1,
                "min": this.data.yAxisRange.min,
                "max": this.data.yAxisRange.max,
                gridLineWidth: 0,
                labels: {
                  step: 1,
                  formatter: (value) => {
                    return this.data.data[i].axisLabel[value.value];
                  }
                }
              },
              plotOptions: {
                series: {

                  // point: {
                  //   events: {
                  //     click: function (event) {
                  //       console.log("click");
                  //       console.log(event);
                  //     }
                  //   }
                  // },

                  enableMouseTracking: false,
                  marker: { enabled: false },
                  zoneAxis: "x",

                  borderWidth: 1,
                  lineColor: "#000"

                }
              },
              legend: { enabled: false },
            })


            for (let s of this.data.data[i].series) {
              arrayCharts.addSeries(s, s.id, false, false);

              if (s.fromMedian != undefined && s.fromRange != undefined) {

                this.fcus.push({
                  name: s.name,
                  id: s._id,
                  color: s.color
                })

                if (s.fromMedian || s.fromRange) {
                  this.outliersIdentification.push({ name: s.name, fromMedian: s.fromMedian, fromRange: s.fromRange })
                }
              }
            }
            arrayCharts.redraw();

          }

          this.spinner.desactivate();
          timer.unsubscribe();
        });

      }
    },
      err => {
        this.spinner.desactivate();
        this.alert.info("Information", "Failed to generate violins plots due to insufficient data.");
      })

  }

  public fcus: { name: string, id: string, color: string }[] = [];
  public outliersIdentification: { name: string, fromRange: boolean, fromMedian: boolean }[] = [];


  ngOnInit(): void {
    this.route.params.subscribe(p => {
      this.id = p["id"];

      this.refresh();
    })
  }

}
