import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';

import { HttpClient, HttpEvent, HttpErrorResponse, HttpEventType } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { timer, Observable } from 'rxjs';

import * as moment from "moment-timezone";
import { AppService } from './app.service';
import { LoginService } from './login.service';

@Injectable({
  providedIn: 'root'
})
export class FileUploadService {

  public updateHeader() {
    return new Promise((resolve, reject) => {
      this.loginService.isAuthenticated().subscribe(
        () => {
          let session: any = JSON.parse(localStorage.getItem(this.appService.productKey + ".loggedUser"));
          resolve({ Authorization: session.token });
        },
        (error) => {
          resolve({ Authorization: "" });
        }
      );
    });
  };

  constructor(public loginService: LoginService, private httpClient: HttpClient, private appService: AppService) { }

  public currentUploads: { name: string, progress: number, startAt: string, status: UploadStatus }[] = [];


  // uploadFile(file) {
  //   const contentType = file.type;
  //   const bucket = new S3(
  //     {
  //       accessKeyId: 'YOUR-ACCESS-KEY-ID',
  //       secretAccessKey: 'YOUR-SECRET-ACCESS-KEY',
  //       region: 'YOUR-REGION'
  //     }
  //   );
  //   const params = {
  //     Bucket: 'YOUR-BUCKET-NAME',
  //     Key: this.FOLDER + file.name,
  //     Body: file,
  //     ACL: 'public-read',
  //     ContentType: contentType
  //   };
  //   bucket.upload(params, function (err, data) {
  //     if (err) {
  //       console.log('There was an error uploading your file: ', err);
  //       return false;
  //     }
  //     console.log('Successfully uploaded file.', data);
  //     return true;
  //   });
  //   //for upload progress
  //   bucket.upload(params).on('httpUploadProgress', function (evt) {
  //     console.log(evt.loaded + ' of ' + evt.total + ' Bytes');
  //   }).send(function (err, data) {
  //     if (err) {
  //       console.log('There was an error uploading your file: ', err);
  //       return false;
  //     }
  //     console.log('Successfully uploaded file.', data);
  //     return true;
  //   });
  // }

  public upload(data, url) {
    let uploadURL = `${url}`;
    console.log(uploadURL);
    return this.httpClient.put<any>(uploadURL, data)
    //  {
    //   // headers: header,
    //   reportProgress: true,
    //   observe: 'body'
    // })
    // .pipe(map((event) => {

    //   switch (event) {

    //     case HttpEventType.UploadProgress:
    //       const progress = Math.round(100 * event.loaded / event.total);
    //       return { status: 'progress', message: progress };

    //     case HttpEventType.Response:
    //       return event.body;
    //     default:
    //       return `Unhandled event: ${event.type}`;
    //   }
    // }));
  }
}


export enum UploadStatus {
  Uploading = 0,
  Error = -1,
  Done = 1
}
