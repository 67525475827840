import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { FutureDecisionsDate } from 'app/components/classes/futuredecisions.date';
import { PointTagsComponent } from 'app/components/common/point-tags/point-tags.component';
import { QrPopupComponent } from 'app/components/common/qr-popup/qr-popup.component';
import { BuildingService } from 'app/services/building.service';
import { AdminPointService } from 'app/services/data.service';
import { QueryparamsService } from 'app/services/queryparams.service';
import { SessionService } from 'app/services/session.service';
import { SpinnerService } from 'app/services/spinner.service';


import * as moment from "moment-timezone";

@Component({
  selector: 'app-point-value-search',
  templateUrl: './point-value-search.component.html',
  styleUrls: ['./point-value-search.component.css']
})
export class PointValueSearchComponent implements OnInit {

  constructor(private queryService: QueryparamsService, private sessionService: SessionService, private dataService: AdminPointService, public dialog: MatDialog, private spinner: SpinnerService, private buildingService: BuildingService) { }

  public dataSource = new MatTableDataSource();

  public displayedColumns = ["index", "icons", "parent", "lastreceived", "lastReceivedAt", "meta", "tags"];

  public selectedBuilding = "";
  public selectedType = 2;
  public selectdUnit = "";
  public valuestring = "";
  public valueboolean = true;
  public valuemin = 0;
  public valuemax = 1;
  public numberMinutes = 2;
  public filter = "";

  public form: FormGroup = new FormGroup({
    selectedBuilding: new FormControl("", [Validators.required]),
    selectedType: new FormControl(2, [Validators.required]),
    valuestring: new FormControl(""),
    valueboolean: new FormControl(true),
    valuemin: new FormControl(0, [Validators.required, this.customValidator()]),
    valuemax: new FormControl(1, [Validators.required, this.customValidator()]),
    numberMinutes: new FormControl(2, [Validators.required, Validators.min(2), Validators.max(30)]),
    filter: new FormControl("")
  })

  public noResults: boolean = true;

  public points: any[] = [];
  public buildings: any[] = [];

  public types = [{ label: "boolean", value: 1 }, { label: "numeric", value: 2 }, { label: "string", value: 3 }];
  public units = [];

  public nvalues = 10;

  public date = new FutureDecisionsDate();
  public timewindow = "Day";

  public typeChange() {
    let t = this.form.get("selectedType").value;

    if (t == 3) {
      this.form.get("valueboolean").setValidators([]);
      this.form.get("valuemin").setValidators([]);
      this.form.get("valuemax").setValidators([]);
      this.form.get("valuestring").setValidators([Validators.required]);
    }
    else if (t == 1) {
      this.form.get("valueboolean").setValidators([]);
      this.form.get("valuemin").setValidators([]);
      this.form.get("valuemax").setValidators([]);
      this.form.get("valuestring").setValidators([]);

    }
    else if (t == 2 || t == undefined) {
      this.form.get("valueboolean").setValidators([]);
      this.form.get("valuemin").setValidators([Validators.required, this.customValidator()]);
      this.form.get("valuemax").setValidators([Validators.required, this.customValidator()]);
      this.form.get("valuestring").setValidators([]);
    }


    this.form.get("valueboolean").reset();
    this.form.get("valuemin").reset();
    this.form.get("valuemin").setValue(0);
    this.form.get("valuemax").reset();
    this.form.get("valuemax").setValue(1);
    this.form.get("valuestring").reset();

    console.log(this.form);
    this.selectedType = t;
  }

  public pagination: { hasNext: boolean, page: number, pageSize: number } = { hasNext: false, page: 1, pageSize: 100 }


  public possibleWindows = ["Day", "Week"];
  public possibleReadings = [];

  public openFacets(id: string) {
    let p = this.data.find(x => x._id == id);
    const dialogRef = this.dialog.open(PointTagsComponent, {
      width: '600px',
      data: {
        list: p.meta,
        title: "Meta for " + p.point
      }
    });
  }
  public openTags(id: string) {
    let p = this.data.find(x => x._id == id);
    const dialogRef = this.dialog.open(PointTagsComponent, {
      width: '600px',
      data: {
        list: p.tags,
        title: "Tags for " + p.point
      }
    });
  }

  public openQR(value, label) {
    const dialogRef = this.dialog.open(QrPopupComponent, {
      width: '460px',
      data: {
        qr: [{ inputString: value, label: "Sensor token for " + label }],
        qrSize: 400
      }
    });
  }

  public pointsWithoutData = 0;

  ngOnInit(): void {
    for (let i = 2; i <= 30; i++) {
      this.possibleReadings.push(i);
    }

    this.spinner.activate();
    this.buildingService.getAll().subscribe(x => {
      this.buildings = x.data;

      this.spinner.desactivate();

    })

    this.spinner.activate();//getUnits
    this.dataService.GetUnits().subscribe(
      resp => {
        for (let type of resp["data"]["UnitList"]) {
          this.units = this.units.concat(type["units"]);
        }

        console.log(this.units);

        this.spinner.desactivate();
      },
      error => {
        this.spinner.desactivate();
      }
    );

    this.queryService.hasParams().subscribe(check => {
      if (check) {
        this.queryService.params().subscribe(params => {

          this.form.get("selectedType").setValue(parseInt(params["selectedType"]));
          this.typeChange();

          this.pagination.pageSize = params["pageSize"] ? parseInt(params["pageSize"]) : 100;
          this.pagination.page = params["page"] ? parseInt(params["page"]) : 1;
          this.form.get("filter").setValue(params["filter"]);

          this.form.get("selectedBuilding").setValue(params["selectedBuilding"])
          this.form.get("valuestring").setValue(params["valuestring"])
          this.form.get("valueboolean").setValue(params["valueboolean"])
          this.form.get("valuemin").setValue(parseInt(params["valuemin"]))
          this.form.get("valuemax").setValue(parseInt(params["valuemax"]))
          this.form.get("numberMinutes").setValue(parseInt(params["numberMinutes"]))
          this.form.get("filter").setValue(params["filter"])


          this.search();
        })
      }
      else if(this.sessionService.building){
        this.form.get("selectedBuilding").setValue(this.sessionService.building);
      }
    })

    console.log(this.form);
  }

  private data;

  public changePage(pagination){
    this.pagination.page = pagination.page;
    this.pagination.pageSize = pagination.pageSize;
    this.search();
  }

  public search() {
    this.spinner.activate();
    let values = this.form.value;
    this.queryService.ReplaceParams({
      selectedBuilding: values.selectedBuilding,
      selectedType: values.selectedType,
      valuestring: values.valuestring,
      valueboolean: values.valueboolean,
      valuemin: values.valuemin,
      valuemax: values.valuemax,
      numberMinutes: values.numberMinutes,
      filter: values.filter,
      page: this.pagination.page,
      pageSize: this.pagination.pageSize
    }).subscribe(x => {

    });
    this.dataService.metadatasFromValue(values.selectedBuilding, values.selectedType, values.valuestring, values.valueboolean, values.valuemin, values.valuemax, values.numberMinutes, values.filter, this.pagination.page, this.pagination.pageSize).subscribe(results => {
      this.spinner.desactivate();

      this.noResults = results.data.length == 0;
      this.pointsWithoutData = results.noData;

      // console.log(results);
      this.data = [];
      let c = 1;
      for (let d of results.data) {
        let lastValue = "";

        if (d.lastReceivedData == null) {
          lastValue = "No readings";
        }
        else if (d.dataType == 8) {
          lastValue = d.lastReceivedDataValue;
        } else {
          if (d.dataTypeDesc && d.dataTypeDesc[d.lastReceivedDataValue]) {
            lastValue = d.dataTypeDesc[d.lastReceivedDataValue];
          }
          else {
            if (typeof (d.lastReceivedDataValue) == "number") {
              lastValue = (Math.floor(d.lastReceivedDataValue * 100) / 100) + (d.unit ? (" " + d.unit) : "")
            }
            else {
              lastValue = d.lastReceivedDataValue;
            }
          }
        }


        this.data.push({
          index: (this.pagination.page - 1) * this.pagination.pageSize + c,
          point: d.displayOrd.slice(d.displayOrd.lastIndexOf("/") + 1, d.displayOrd.length),
          // parent: d.pointName.slice(0, d.pointName.lastIndexOf("/") + 1),
          parent: d.displayOrd ? d.displayOrd : d.friendlyname,
          building: d.building,
          friendlyname: d.friendlyName ? d.friendlyName : "Undefined",
          lastreceived: lastValue,
          tooltip: d.tags ? "Tags: " + d.tags.join(", ") + "\nFirst Reading at " + moment(d.firstReceivedData).format("DD/MM/YYYY HH:mm Z") : "First Reading at " + moment(d.firstReceivedData).format("DD/MM/YYYY HH:mm Z"),
          device: d.device,
          lastReceivedAt: moment(d.lastReceivedData).format("DD/MM/YYYY HH:mm"),
          lastReceivedTooltip: moment(d.lastReceivedData).format("DD/MM/YYYY HH:mm Z"),
          hasTooltipValue: null,
          tooltipLastValue: d.dataType == 8 ? d.lastReceivedDataValue : "",
          dataType: d.dataType,
          _id: d._id,
          serialNumber: d.serialNumber,
          tags: d.tags ? d.tags : [],
          meta: d.facetsObj ? Object.keys(d.facetsObj).map(x => {
            return x + " : " + JSON.stringify(d.facetsObj[x]);
          }) : []
        })

        c++;
      }

      this.pagination.hasNext = this.data.length > this.pagination.pageSize;

      this.dataSource.data = this.data;
    })
  }
  customValidator() {
    return (control: FormControl) => {
      const form = control.parent
      if (form) {
        const min = form.get('valuemin');
        const max = form.get('valuemax');

        min.setErrors
        if (min.value && max.value && +max.value < +min.value) {
          min.setErrors({ error: 'min malue' });
          max.setErrors({ error: 'min malue' });
        }
        else {
          min.setErrors(null);
          max.setErrors(null);
        }
        return min.value && max.value && +max.value < +min.value ? { error: 'min malue' } : null
      }
    }
  }
}
