import { AfterViewInit, Component } from "@angular/core";
import { Router } from "@angular/router";
import { UserType } from "app/interfaces/baseinterface";
import { LoginService } from "app/services/login.service";
import { Md5 } from "ts-md5/dist/md5";
import { VersionCheckService } from 'app/services/version-checker.service';
import { PlatformLocation } from '@angular/common';

// import * as jQuery  from 'jquery';

// import "jquery-slimscroll";
// import "metismenu";

declare var jQuery: any;

@Component({
  selector: "app-navigation",
  templateUrl: "navigation.template.html",
  styleUrls: ["navigation.component.css"]
})

export class NavigationComponent implements AfterViewInit {
  public enums = UserType;
  public gravatarUrl = "https://www.gravatar.com/avatar/";


  constructor(private router: Router, public login: LoginService) {
    this.gravatarUrl += Md5.hashStr(this.login.userInfo.primaryEmail) + "?s=48";


  }

  ngAfterViewInit() {
    jQuery("#side-menu").metisMenu();

    if (jQuery("body").hasClass("fixed-sidebar")) {
      (<any>jQuery(".sidebar-collapse")).slimscroll({
        height: "100%"
      });
    }
  }

  activeRoute(routeName: string): boolean {
    return this.router.url.indexOf(routeName) > -1;
  }
}
