import { Component, OnInit } from '@angular/core';
import { Company } from 'app/interfaces/company';
import { CompanyService } from 'app/services/company.service';
import { Router } from '@angular/router';
import { AppI18nService } from 'app/services/app.i18n.service';
import { SpinnerService } from 'app/services/spinner.service';
import { SweetalertService } from 'app/services/sweetalert.service';
import { IReturnedError } from 'app/services/base.service';
import { UserType } from 'app/interfaces/baseinterface';
import { PhoneType } from 'app/interfaces/contact';
import { User } from 'app/interfaces/user';

@Component({
  selector: 'app-root-create-company',
  templateUrl: './root-create-company.component.html',
  styleUrls: ['./root-create-company.component.css']
})
export class RootCreateCompanyComponent implements OnInit {

  constructor(private router: Router, private companyService: CompanyService, public i18n: AppI18nService, private spinnerService: SpinnerService, private alertService: SweetalertService) {
  }

  ngOnInit() {
  }


  public save(event) {
    let company: Company = {
      contacts: {
        telephones: event.value.otherTelephones,
        emails: event.value.otherEmails
      },
      name: event.value.name,
      responsibles: (<User[]>[
        {
          person: {
            name: event.value.responsible.name,
            country: event.value.responsible.country,
            mobilePhone: event.value.responsible.mobilephone,
            contacts: {
              emails: event.value.responsible.emails,
              telephones: event.value.responsible.telephones
            },
            landline:event.value.responsible.landline
          },
          username: event.value.responsible.primaryEmail,
          email: event.value.responsible.primaryEmail,
          type: event.value.type,
        }
      ]),
      primaryEmail: event.value.primaryEmail,
      primaryTelephone: event.value.primaryTelephone,
      address: {
        number: event.value.address.number,
        city: event.value.address.city,
        country: event.value.address.country,
        neighborhood: event.value.address.neighborhood,
        street: event.value.address.street,
        zipCode: event.value.address.zipcode,
        complement: event.value.address.complement,
        lat: 1,
        lng: 1
      }
    };

    this.spinnerService.activate();
    this.companyService.create(company).subscribe(
      res => {
        this.spinnerService.desactivate();
        this.alertService.success(
          this.i18n.translate('general.messages.success'),
          this.i18n.translate('general.messages.created', this.i18n.translate('interfaces.company.company'))
        );
        this.router.navigate(['/root/companies']);
      },
      err => {
        console.log(err);

        let errors: IReturnedError = {
          errors: []
        };

        for (let x of err.errors) {
          errors.errors.push({ field: '', message: x });
        }

        this.spinnerService.desactivate();
        this.alertService.error(this.i18n.translate('general.messages.error'),
          this.i18n.translate('general.messages.invalidForm', this.companyService.generateHtmlListOfErrors(errors))
        );
      });
  }
}
