import { Injectable } from "@angular/core";
import * as yaml from "js-yaml";
import { HttpClient, HttpEvent } from "@angular/common/http";

@Injectable()
export class AppI18nService {
  private _jsonObj: any;
  private _locale: LocaleId;
  private _availableLocales: LocaleId[] = [
    {
      code: "enUs",
      description: "English from United States of America",
      fileName: "en-us.yml",
      flagName: "United-States.png"
    }
  ];

  public translate( label: string, ...params: string[] ): string {

    try {
      const indexes = label.split( "." );

      let current = this._jsonObj;
      for( const index of indexes ) {
        current = current[ index ];
      }

      for( let i = 0; i < params.length; i++ ) {
        current = current.replace( "{" + i + "}", params[ i ] );
      }

      return current === undefined ? (label.indexOf(".") === -1 ? label : "NT_" + label) : current;

    } catch {

      return "NT_" + label;
    }
  }

  get availableLocales(): LocaleId[] {
    return this._availableLocales;
  }

  get translation(): any {
    return this._jsonObj;
  }

  set locale( value: LocaleId ) {
    this._locale = value;
    this.setLocate( value );
  }

  get locale(): LocaleId {
    return this._locale;
  }

  constructor( private http: HttpClient ) {
    this.locale = this._availableLocales.filter( x => x.code === "enUs" )[ 0 ];
  }

  private setLocate( value: LocaleId ) {

    this.http.request("GET", "assets/locale/" + value.fileName, { responseType: "text"} ).subscribe( x => {

      this._jsonObj = yaml.safeLoad( x );

    } );
  }
}

export interface LocaleId {
  description: string;
  code: string;
  fileName: string;
  flagName: string;
}
