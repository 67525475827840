import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { PointgroupsService } from 'app/services/pointgroups.service';
import { SpinnerService } from 'app/services/spinner.service';
import { SweetalertService } from 'app/services/sweetalert.service';
import * as moment from 'moment-timezone';

@Component({
  selector: 'app-admin-assets-edit',
  templateUrl: './admin-assets-edit.component.html',
  styleUrls: ['./admin-assets-edit.component.css']
})
export class AdminAssetsEditComponent implements OnInit {

  constructor(private alert: SweetalertService, private router: Router, private route: ActivatedRoute, private spinner: SpinnerService, private pointGroupService: PointgroupsService) { }

  public save(data) {
    data.subGroups = data.subGroups.concat(data.assets);
    data.assets = undefined;
    console.log(data);
    console.log(this.originalData.data);

    this.spinner.activate();
    this.pointGroupService.update(this.originalData.data, data).subscribe(
      x => {
        this.spinner.desactivate();
        this.router.navigate(["/admin/grouping/assets"]);

        this.alert.success("Success!", "Asset updated.")
      },
      err => {
        this.spinner.desactivate();

        this.alert.warning("Info!", err.error.errors.map(x => x.message).join(". "));
      })
  }

  public originalData;

  ngOnInit(): void {
    this.route.params.subscribe(p => {

      this.spinner.activate();
      this.pointGroupService.getPointGroup(p["id"]).subscribe(x => {

        if (x.points)
          for (let i = 0; i < x.points.length; i++) {
            let p = x.points[i];

            p.index = i + 1;
            p.lastReceivedData = moment(p.lastReceivedData).format("DD/MM/YYYY HH:mm")
            p.timezoneTooltip = moment(p.lastReceivedData).format("Z z");
            p.lastValue = Math.round(p.lastReceivedDataValue * 100) / 100 + p.unit;
            p.meta = [];

            if (p.facetsObj)
              for (let key of Object.keys(p.facetsObj)) {
                if (typeof p.facetsObj[key] === 'object' && p.facetsObj[key] !== null) {
                  for (let nested of Object.keys(p.facetsObj[key])) {
                    p.meta.push(key + ": {" + nested + ":" + p.facetsObj[key][nested] + "}");

                  }
                }
                else if (Array.isArray(p.facetsObj[key])) {
                  p.meta.push(key + ": " + p.facetsObj[key].join(", "));
                }
                else {
                  p.meta.push(key + ": " + p.facetsObj[key]);
                }
              }
          }

        this.originalData = x;

        this.spinner.desactivate();
      })
    })
  }

}
