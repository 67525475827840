import { Component, OnInit, ViewEncapsulation, Input } from '@angular/core';
// import { SpinnerType } from 'app/components/common/spinner/spinner.component';
// import { SpinnerService } from 'app/services/spinner.service';

@Component({
  selector: 'app-ibox',
  templateUrl: './ibox.component.html',
  styleUrls: ['./ibox.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class IboxComponent implements OnInit {

  // @Input() spinner: SpinnerType = SpinnerType.Plane;

  // constructor(public spinnerService: SpinnerService) { }
  constructor() { }

  ngOnInit() {
  }

}
