import { AfterViewInit, Component, ViewChild, ViewEncapsulation } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { SpinnerService } from "../../../services/spinner.service";
import { AdminPointService } from "../../../services/data.service";
import { AppHighstockComponent } from "../../../components/common/highcharts/highstock/highstock.component";
import * as moment from "moment-timezone";
import { AppI18nService } from "../../../services/app.i18n.service";
import { deepClone } from "../../../services/app.service";
import { IBuilding } from "../../../interfaces/building";
import { BuildingService } from "../../../services/building.service";
import { CompanyService } from "../../../services/company.service";
import { LoginService } from "app/services/login.service";
import { getMaxArray, getMinArray } from "../../../app.helpers";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { MAT_DATE_FORMATS } from "@angular/material/core";
import { MatCheckboxModule } from '@angular/material/checkbox';
import { DATEPICKER_FORMAT } from "app/components/adapters/DatepickerFormats";
import { FutureDecisionsDate } from "app/components/classes/futuredecisions.date";
import { SessionService } from "app/services/session.service";
import { MatDialog } from "@angular/material/dialog";
import { SearchPopupComponent } from "app/components/common/search-popup/search-popup.component";
import { Observable } from "rxjs";

moment.locale("en-gb");

Array.prototype["sum"] = Array.prototype["sum"] || function () {
  return this.reduce(function (sum, a) {
    return sum + Number(a);
  }, 0);
};

Array.prototype["avg"] = Array.prototype["avg"] || function () {
  return this.sum() / (this.length || 1);
};

let comp: AdminMeterDataComponent;



@Component({
  selector: "app-admin-meter-data",
  templateUrl: "./admin-meter-data.component.html",
  styleUrls: ["./admin-meter-data.component.css"],
  encapsulation: ViewEncapsulation.None,
  providers: [
    { provide: MAT_DATE_FORMATS, useValue: DATEPICKER_FORMAT }
  ]
})

export class AdminMeterDataComponent implements AfterViewInit {

  @ViewChild("chartStock", { static: true }) chart: AppHighstockComponent;
  private colors = [
    "rgba(124, 181, 236, 1)",
    "rgba(67, 67, 72, 1)",
    "rgba(144, 237, 125, 1)",
    "rgba(247, 163, 92, 1)",
    "rgba(128, 133, 233, 1)",
    "rgba(241, 92, 128, 1)",
    "rgba(228, 211, 84, 1)",
    "rgba(43, 144, 143, 1)",
    "rgba(244, 91, 91, 1)",
    "rgba(145, 232, 225, 1)"
  ];

  public max = new FutureDecisionsDate(moment().endOf("day").format());

  public changedDate(event) {
    console.log(event.momentObj.format());
    this.form.get('start').setValue(event)
  }

  public possibleWindows = ["Day", "Month", "Week"];

  private today = moment.utc();

  public form = new FormGroup({
    start: new FormControl(new FutureDecisionsDate().startOf("day").subtract(1, "day"), [Validators.required]),
    end: new FormControl(this.today.clone(), [Validators.required]),
    timeSelection: new FormControl("Month", [Validators.required]),
    tags: new FormControl(),
    building: new FormControl(null, [Validators.required]),
    point: new FormControl(null, [Validators.required])
  });

  private series: any = {};
  private seriesOriginalData: any = {};
  public groupingInfo: any = [];


  private startDate = this.today.clone().add(-1, "d");
  private endDate = this.today.clone();

  public viewStartDate = this.startDate.format("YYYY-MM-DD");
  public viewEndDate = this.endDate.format("YYYY-MM-DD");

  public listOfPoints: any[] = [];
  public filteredPoints: any[] = [];
  public point: any = undefined;
  private initialPoint: any = undefined;

  public filteredBuildings: IBuilding[];
  private listOfBuildings: IBuilding[];
  private building: IBuilding;
  public buildingFilter: any = "";

  public listOfTags = [];
  public filteredTags = [];
  public tags: any[] = [];

  public groupingOptions: any[] = [
    {
      name: "30 minutes",
      tooltipFormat: "LLLL z",
      detailGroupType: "day",
      detailGroupFormat: "DD/MM/YYYY",
      xAxisOptions: {
        tickInterval: 1800000,
        labels: {
          rotation: -30,
          style: {
            textOverflow: false, //I changed these two lines
            whiteSpace: "nowrap"
          }
        },
        type: "datetime",
        // minTickInterval: 18e5,
        dateTimeLabelFormats: {
          minute: "%H:%M",
          day: "%e. %b"
        }
      }
    },
    {
      name: "Day",
      type: "day",
      tooltipFormat: "LL z",
      detailGroupType: "week",
      detailGroupFormat: "[week] WW/YYYY",
      units: [["day", [1]]],
      xAxisOptions: {
        tickInterval: 86400000,
        labels: {
          rotation: -30,
          style: {
            textOverflow: false, //I changed these two lines
            whiteSpace: "nowrap"
          }
        },
        type: "datetime",
        // minTickInterval: moment.duration( 1, "day" ).asMilliseconds(),
        dateTimeLabelFormats: {
          day: "%e. %b"
        }
      }
    },
    {
      name: "Week",
      type: "isoweek",
      tooltipFormat: "LL z",
      detailGroupType: "month",
      detailGroupFormat: "[month] MM/YYYY",
      units: [["week", [1]]],
      xAxisOptions: {
        tickInterval: 604800000,
        labels: {
          rotation: -30,
          style: {
            textOverflow: false, //I changed these two lines
            whiteSpace: "nowrap"
          }
        },
        type: "datetime",
        // minTickInterval: moment.duration( 1, "week" ).asMilliseconds(),
        dateTimeLabelFormats: {
          day: "Week %W of %Y",
          week: "Week %W of %Y"
        }
      }
    },
    {
      name: "Month",
      type: "month",
      tooltipFormat: "[month] MM/YYYY",
      detailGroupType: "year",
      detailGroupFormat: "YYYY",
      units: [["month", [1]]],
      xAxisOptions: {
        tickInterval: 2592000000,
        labels: {
          rotation: -30,
          style: {
            textOverflow: false, //I changed these two lines
            whiteSpace: "nowrap"
          }
        },
        type: "datetime",
        // minTickInterval: moment.duration( 1, "month" ).asMilliseconds(),
        dateTimeLabelFormats: {
          day: "%b of %Y",
          week: "%b of %Y",
          month: "%b of %Y"
        }
      }
    },
    {
      name: "Year",
      type: "year",
      tooltipFormat: "YYYY",
      units: [["year", [1]]],
      xAxisOptions: {
        tickInterval: 31536000000,
        labels: {
          rotation: -30,
          style: {
            textOverflow: false, //I changed these two lines
            whiteSpace: "nowrap"
          }
        },
        type: "datetime",
        // minTickInterval: moment.duration( 1, "year" ).asMilliseconds(),
        dateTimeLabelFormats: {
          day: "%Y",
          week: "%Y",
          month: "%Y",
          year: "%Y"
        }
      }
    }
  ];

  public groupingBy: any = this.groupingOptions[0];
  public includeWeekend: boolean = true;

  public chartOptions: any = {
    chart: {
      zoomType: "x",
      height: 600
    },
    navigator: {
      series: {
        includeInCSVExport: false
      }
    },
    xAxis: {
      startOnTick: true,
      endOnTick: true,
      tickInterval: 1800000
    },
    legend: {
      floating: false,
      enabled: true,
      align: "center",
      shadow: true,
      y: 50,
      verticalAlign: "top"
    },
    tooltip: {
      enabled: true,
      useHTML: true,
      shared: true,
      crosshairs: true,
      formatter: function () {
        let ret = `<b>${moment.utc(this.x).format(comp.groupingBy.tooltipFormat)}</b></br>`;
        for (let i = 0; i < this.points.length; i++) {
          ret += `<span style="padding-left: 10px; color:${this.points[i].series.color}">\u25CF</span><b>${this.points[i].series.name}:</b>${this.points[i].y}` +
            (!!this.points[i].point.dataMissing ? " | <b>Data Missing:</b> " + this.points[i].point.dataMissing : "");
          ret += "</br>";
        }
        return ret;
      }
    },
    plotOptions: {
      series: {
        dataGrouping: {
          enabled: false
        }
      }
    },
    rangeSelector: {
      inputEnabled: false,
      buttons: [
        /*{
         type: "day",
         count: 1,
         text: "Day"
         }, {
         type: "week",
         count: 1,
         text: "Week"
         }, {
         type: "month",
         count: 1,
         text: "Month"
         }, {
         type: "year",
         count: 1,
         text: "Year"
         },*/ {
          type: "all",
          text: "All"
        }
      ],
      selected: 1, // all
      buttonTheme: {
        width: null
      }
    }
  };

  ngAfterViewInit() {
    this.route.queryParams.subscribe(async (query) => {
      try {

        this.spinner.activate();
        let companyRes = await this.companyService.getCompanyBuildingsTags().toPromise();
        this.spinner.desactivate();
        this.listOfTags = companyRes;
        this.filterTags("");
      } catch (err) {
        console.log(err);
      }

      try {
        this.spinner.activate();
        let buildings = await this.buildingService.getAll().toPromise();
        this.spinner.desactivate();
        this.listOfBuildings = buildings.data;
        this.filteredBuildings = this.listOfBuildings.slice(0);
        if (query["building"]) {
          for (let b of this.filteredBuildings) {
            if (b._id === query["building"]) {
              this.buildingFilter = b;
              this.building = b;

              this.form.get('building').setValue(b);
              // this.onBuildingChange( { option: { value: b } }, undefined );
            }
          }
        }
        else if (this.session.building != "") {
          this.building = this.filteredBuildings.find(x => x._id == this.session.building)
          this.buildingFilter = this.building;

          this.form.get('building').setValue(this.building);
        }
      } catch (error) {
        console.log(error);
      }

      // if (this.building) {

      //   let data = [];
      //   try {

      //     this.spinner.activate();
      //     this.dataService.GetListOfPoints(this.building._id, true).subscribe(resp => {
      //       this.spinner.desactivate();
      //       for (let point of resp["data"]) {
      //         if (point.id === query["point"]) {
      //           this.initialPoint = point;
      //           this.form.get('point').setValue(point);
      //         }
      //         data.push(point);
      //       }
      //       this.listOfPoints = data;
      //       if (this.initialPoint) {
      //         this.point = this.initialPoint;
      //         this.GetDataForPoint(this.initialPoint);
      //       }
      //     });
      //   } catch (error) {
      //     console.log(error);
      //   }
      // }
    });
  }

  constructor(public dialog: MatDialog, private session: SessionService, public router: Router, private dataService: AdminPointService, private spinner: SpinnerService,
    private route: ActivatedRoute, public i18n: AppI18nService, private buildingService: BuildingService,
    private companyService: CompanyService, private loginService: LoginService) {
    comp = this;
  }

  public openDialog() {
    const dialogRef = this.dialog.open(SearchPopupComponent, {
      width: '960px',
      data: {
        single: true,
        assetName: "Meters",
        displayField: "name",
        valueField: "id",
        disableSelect: false,
        searchCallback: this.searchPoints.bind(this),
      }
    });

    dialogRef.afterClosed().subscribe(x => {
      if(x){
        this.form.get("point").setValue(x.id);
        this.point = x;

        this.pointName = x.name;

        this.changeDate();
      }
    })
  }

  public searchPoints(filter: string, pageOptions: { page: number, pageSize: number, hasNext: boolean }): Observable<any> {
    return new Observable(observer => {
      this.spinner.activate();
      this.dataService.GetAllPointsWithMetadata(this.building._id, true, filter, pageOptions.page, pageOptions.pageSize, true).subscribe(x => {
        this.spinner.desactivate();

        observer.next(x.data.map(x => { return { name: x.displayOrd, id: x._id } }));
        observer.complete();
      })

    })
  }

  public dateChanged(dateStr, dateObj) {
    if (dateObj === 1) {
      this.startDate = moment.utc(dateStr);
    } else {
      this.endDate = moment.utc(dateStr);
    }
  }

  public changeDate() {
    if (this.building && this.point && this.point !== "") {
      this.GetDataForPoint(this.point);
    }
  }

  public pointName;

  private GetDataForPoint(point) {


    let value = this.form.value;

    this.spinner.activate();
    this.point = point;
    let start = value.start.dateObject.clone();
    let end = start.clone();

    switch (value.timeSelection) {
      case "Month":
        start = start.startOf("month");
        end = end.endOf("month");
        break;
      case "Day":
        start = start.startOf("day");
        end = end.endOf("day");
        break;
      case "Week":
        start = start.startOf("week");
        end = end.endOf("week");
        break;
    }

    let startLastWeek = start.clone().subtract(1, "weeks");
    let endLastWeek = end.clone().subtract(1, "weeks");

    let startLastYear = start.clone().subtract(1, "year").isoWeek(start.isoWeek()).isoWeekday(start.isoWeekday());
    let endLastYear = end.clone().subtract(1, "year").isoWeek(end.isoWeek()).isoWeekday(end.isoWeekday());

    let weekDisplacement = start.diff(startLastWeek, "milliseconds");
    let yearDisplacement = start.diff(startLastYear, "milliseconds");

    let tmpName = "TempId";

    this.dataService.GetDataForPoint({
      building: value.building._id,
      point: point.id,
      from: start.format("YYYY-MM-DD"),
      to: end.format("YYYY-MM-DD"),
      addHistoricData: "1"
    }).subscribe(
      resp => {
        let currentData = [];
        let lastWeekData = [];
        let lastYearData = [];
        let serieColor = (this.seriesOriginalData[tmpName/*point.id*/] ?
          this.seriesOriginalData[tmpName/*point.id*/].color :
          this.colors[Object.keys(this.seriesOriginalData).length]);

        let lastValidValue: any = undefined;
        let missingDataEndPrev = 0;

        for (let d of resp["data"]) {
          for (let wd of Object.keys(d.weekPointData)) {
            let date = moment.utc(d["timestamp"]).startOf("day");
            let wday = parseInt(wd);

            if (wday - 1 > 0) {
              date.add(wday - 1, "days");
            }
            let dt = date.valueOf();
            let dataArray = d.weekPointData[wday];
            for (let slice = 1; slice <= 48; slice++) {
              let value = undefined;
              let sliceStart: any = 30 * (slice - 1);
              let sliceEnd: any = (30 * slice) - 1;
              let dataMissing = 0;

              if (!lastValidValue || missingDataEndPrev > 0) {
                for (; (sliceStart <= sliceEnd) && !dataArray[sliceStart]; sliceStart++) {
                  dataMissing++;
                }
                if (dataMissing <= missingDataEndPrev && !!dataArray[sliceStart]) {
                  lastValidValue = Number(dataArray[sliceStart]);
                  missingDataEndPrev = 0;
                } else {
                  dataMissing = missingDataEndPrev;
                }
              }

              if (lastValidValue) {
                missingDataEndPrev = 0;
                for (; (sliceEnd >= sliceStart) && !dataArray[sliceEnd]; sliceEnd--) {
                  missingDataEndPrev++;
                  dataMissing++;
                }
                if (!dataArray[sliceEnd]) {
                  lastValidValue = undefined;
                } else {
                  if (point.delta) {
                    value = Number(dataArray[sliceEnd]) - lastValidValue;
                  } else {
                    value = Number(dataArray[sliceEnd]);
                  }

                  lastValidValue = Number(dataArray[sliceEnd]);

                  if (value !== undefined) {
                    if (date >= start && date <= end) {
                      // if( value > 100 || value < -100 ) {
                      //   console.log( "OUT OF BOUND" );
                      //   console.log( date.toISOString() );
                      //   console.log( value );
                      //   console.log( slice );
                      //   console.log( dataArray.slice( 30 * ( slice - 1 ), 30 * slice ) );
                      // }
                      currentData.push({
                        x: dt,
                        y: value,
                        color: {
                          pattern: {
                            path: {
                              d: "M 0 0 L 10 10 M 9 -1 L 11 1 M -1 9 L 1 11",
                              strokeWidth: 3
                            },
                            width: 10,
                            height: 10,
                            color: serieColor
                          }
                        },
                        dataMissing: dataMissing
                      });
                    }
                    if (date >= startLastWeek && date <= endLastWeek && dt + weekDisplacement < moment.utc().valueOf()) {
                      lastWeekData.push({
                        x: dt + weekDisplacement,
                        y: value,
                        color: {
                          pattern: {
                            path: {
                              d: "M 0 0 L 10 10 M 9 -1 L 11 1 M -1 9 L 1 11",
                              strokeWidth: 3
                            },
                            width: 10,
                            height: 10,
                            color: serieColor.replace("1)", "0.4)")
                          }
                        },
                        dataMissing: dataMissing
                      });
                    }
                    if (date >= startLastYear && date <= endLastYear && dt + yearDisplacement < moment.utc().valueOf()) {
                      lastYearData.push({
                        x: dt + yearDisplacement,
                        y: value,
                        color: {
                          pattern: {
                            path: {
                              d: "M 0 0 L 10 10 M 9 -1 L 11 1 M -1 9 L 1 11",
                              strokeWidth: 3
                            },
                            width: 10,
                            height: 10,
                            color: serieColor.replace("1)", "0.4)")
                          }
                        },
                        dataMissing: dataMissing
                      });
                    }
                  }
                }
              }
              dt += 1800000;
            }
          }
        }

        currentData.sort((a, b) => {
          return a.x < b.x ? -1 : 1;
        });

        lastWeekData.sort((a, b) => {
          return a.x < b.x ? -1 : 1;
        });

        lastYearData.sort((a, b) => {
          return a.x < b.x ? -1 : 1;
        });

        let pastData: any = [];
        if (lastYearData.length == 0) {
          pastData = lastWeekData;
        } else {
          pastData = lastYearData;
        }

        this.seriesOriginalData[tmpName/*point.id*/] = { current: currentData, past: pastData, color: serieColor };
        let groupResult = this.GroupData("TempId"/*point.id*/, point.name);

        // if( !this.series[ point.id ] ) {
        this.chart.removeById(tmpName);
        this.chart.removeById(tmpName + "_past");

        this.series[tmpName/*point.id*/] = {
          current: {
            turboThreshold: 0/*groupResult.data.length + 10*/,
            id: tmpName/*point.id*/,
            name: point.name,
            data: groupResult.data.map(p => p.dataMissing > 0 ? p : [p.x, p.y]),
            type: "column",
            color: serieColor,
            groupingType: "sum",
            pointDataType: "dataSerie",
            dataGrouping: {
              enabled: false
            },
            showInNavigator: true
          },
          past: {
            turboThreshold: 0/*groupResult.past.length + 10*/,
            id: tmpName/*point.id*/ + "_past",
            name: point.name + "[ Hist. ]",
            data: groupResult.past.map(p => p.dataMissing > 0 ? p : [p.x, p.y]),
            type: "column",
            color: serieColor.replace("1)", "0.4)"),
            groupingType: "sum",
            pointDataType: "dataSerie",
            dataGrouping: {
              enabled: false
            },
            showInNavigator: true
          }
        };
        /*  this.chart.addSeries( this.series[ point.id ] );

         } else {
         this.series[ point.id ][ "data" ] = groupResult.data;*/
        this.chart.updateSeries(this.series[tmpName/*point.id*/].current/*{ data: groupResult.data }*/, tmpName/*point.id*/);
        if (this.series[tmpName/*point.id*/].past.data.length > 0) {
          this.chart.updateSeries(this.series[tmpName/*point.id*/].past/*{ data: groupResult.data }*/, tmpName/*point.id*/ + "_past");
        }
        let xAxis = this.groupingBy.xAxisOptions;
        if (groupResult.past.length > 0 && groupResult.data.length > 0) {
          if (groupResult.data[0].x < groupResult.past[0].x) {
            xAxis.min = groupResult.data[0].x - xAxis.tickInterval;
          } else {
            xAxis.min = groupResult.past[0].x - xAxis.tickInterval;
          }
          if (groupResult.data[groupResult.data.length - 1].x > groupResult.past[groupResult.past.length - 1].x) {
            xAxis.max = groupResult.data[groupResult.data.length - 1].x + xAxis.tickInterval;
          } else {
            xAxis.max = groupResult.past[groupResult.past.length - 1].x + xAxis.tickInterval;
          }
        } else if (groupResult.data.length > 0) {
          xAxis.min = groupResult.data[0].x - xAxis.tickInterval;
          xAxis.max = groupResult.data[groupResult.data.length - 1].x + xAxis.tickInterval;

        } else if (groupResult.past.length > 0) {
          xAxis.min = groupResult.past[0].x - xAxis.tickInterval;
          xAxis.max = groupResult.past[groupResult.past.length - 1].x + xAxis.tickInterval;
        }
        this.chart.updateChart({ xAxis: xAxis });
        // }

        let lines = [
          {
            value: groupResult.peak,
            color: "orange",
            zIndex: 6,
            dashStyle: "shortdash",
            width: 2,
            label: {
              text: "Data Max"
            }
          }
        ];

        if (point.maxValue && point.maxValue != groupResult.peak) {
          lines.push({
            value: point.maxValue,
            color: "red",
            zIndex: 7,
            dashStyle: "shortdash",
            width: 2,
            label: {
              text: "User Max"
            }
          });
        }

        if (groupResult.avg != groupResult.peak) {
          lines.push({
            value: groupResult.avg,
            color: "green",
            zIndex: 6,
            dashStyle: "shortdash",
            width: 2,
            label: {
              text: "Data Avg"
            }
          });
        }

        if (groupResult.min && groupResult.min != groupResult.peak && groupResult.min !=
          groupResult.avg) {
          lines.push({
            value: groupResult.min,
            color: "blue",
            zIndex: 6,
            dashStyle: "shortdash",
            width: 2,
            label: {
              text: "Data Min"
            }
          });
        }

        if (point.minValue && point.minValue != groupResult.peak) {
          lines.push({
            value: point.minValue,
            color: "red",
            zIndex: 7,
            dashStyle: "shortdash",
            width: 2,
            label: {
              text: "User Min"
            }
          });
        }

        this.chart.updateYaxis({
          plotLines: lines,
          min: groupResult.min - (groupResult.peak - groupResult.min) * 0.1,
          max: groupResult.peak + (groupResult.peak - groupResult.min) * 0.1
        });
        this.spinner.desactivate();
      },
      error => {
        console.log(error);
        this.spinner.desactivate();
      });
  }

  GroupData(serieId, serieName) {
    //Data Grouping
    let groupResult = { data: [], past: [], avg: 0, peak: 0, min: 0 };
    if (this.includeWeekend) {
      groupResult.data = deepClone(this.seriesOriginalData[serieId].current);
      groupResult.past = deepClone(this.seriesOriginalData[serieId].past);
    } else {
      groupResult.data = this.seriesOriginalData[serieId].current.filter((d: any) => moment.utc(d.x).isoWeekday() < 6);
      groupResult.past = this.seriesOriginalData[serieId].past.filter((d: any) => moment.utc(d.x).isoWeekday() < 6);
    }

    // data variable already contains 30min interval data
    if (this.groupingBy.type != undefined) {
      let groups = groupResult.data.reduce((acc, elem) => {
        let dt = moment(elem.x).utc().startOf(this.groupingBy.type).valueOf().toString();
        if (!acc[dt]) {
          acc[dt] = [];
        }
        acc[dt].push(elem);
        return acc;
      }, {});

      let groupsPast = groupResult.past.reduce((acc, elem) => {
        let dt = moment(elem.x).utc().startOf(this.groupingBy.type).valueOf().toString();
        if (!acc[dt]) {
          acc[dt] = [];
        }
        acc[dt].push(elem);
        return acc;
      }, {});

      groupResult.data = [];
      groupResult.past = [];

      for (let group in groups) {
        let point = groups[group].reduce((acc, elem) => {
          if (elem.dataMissing) {
            acc.color = elem.color;
            acc.dataMissing += elem.dataMissing;
          }
          acc.y += elem.y;
          return acc;
        }, { x: parseInt(group), y: 0, color: groups[group][0].color, dataMissing: 0 });
        groupResult.data.push(point);
      }

      for (let group in groupsPast) {
        let point = groupsPast[group].reduce((acc, elem) => {
          if (elem.dataMissing) {
            acc.color = elem.color;
            acc.dataMissing += elem.dataMissing;
          }
          acc.y += elem.y;
          return acc;
        }, { x: parseInt(group), y: 0, color: groupsPast[group][0].color, dataMissing: 0 });
        groupResult.past.push(point);
      }
    }

    let res: any = groupResult.data.reduce((acc, elem) => {
      if (acc.peak < elem.y) {
        acc.peak = elem.y;
      }
      if (acc.min === undefined || acc.min > elem.y) {
        acc.min = elem.y;
      }
      acc.count++;
      acc.sum += elem.y;
      return acc;
    }, { sum: 0, count: 0, peak: 0, min: undefined });

    let resPast: any = groupResult.past.reduce((acc, elem) => {
      if (acc.peak < elem.y) {
        acc.peak = elem.y;
      }
      if (acc.min === undefined || acc.min > elem.y) {
        acc.min = elem.y;
      }
      acc.count++;
      acc.sum += elem.y;
      return acc;
    }, { sum: 0, count: 0, peak: 0, min: undefined });

    if (groupResult.past.length > 0) {
      groupResult.peak = res.peak > resPast.peak ? res.peak : resPast.peak;
      groupResult.avg = (res.sum + resPast.sum) / (res.count + resPast.count);
      groupResult.min = res.min < resPast.min ? res.min : resPast.min;
    } else {
      groupResult.peak = res.peak;
      groupResult.avg = res.sum / res.count;
      groupResult.min = res.min;
    }

    //Detail Grouping
    // TODO: allow multiple meters
    this.groupingInfo = [];//this.groupingInfo.filter((elem) => elem.id !== serieId );
    if (this.groupingBy.detailGroupType) {
      let tmpData = [];
      if (this.includeWeekend) {
        tmpData = this.seriesOriginalData[serieId].current.slice(0);
      } else {
        tmpData = this.seriesOriginalData[serieId].current.filter((d: any) => moment.utc(d.x).isoWeekday() < 6);
      }
      let groups = tmpData.reduce((acc, elem) => {
        let dt = moment(elem.x).utc().startOf(this.groupingBy.detailGroupType).valueOf().toString();
        if (!acc[dt]) {
          acc[dt] = [];
        }
        acc[dt].push(elem);
        return acc;
      }, {});
      for (let group in groups) {
        let info: any = {
          id: moment(group, "x").utc().format(this.groupingBy.detailGroupFormat),
          name: moment(group, "x").utc().format(this.groupingBy.detailGroupFormat),
          groups: []
        };
        groups[group] = groups[group].reduce((acc, elem) => {
          let dt = moment(elem.x).utc().startOf(this.groupingBy.type).valueOf().toString();
          if (!acc[dt]) {
            acc[dt] = [];
          }
          acc[dt].push(elem.y);
          return acc;
        }, {});
        let tmpGroup = [];
        for (let g in groups[group]) {
          tmpGroup.push(groups[group][g].sum());
        }
        groups[group] = tmpGroup;
        info.groups.push({
          tag: serieName,
          avg: groups[group].avg(),
          peak: getMaxArray(groups[group]),
          min: getMinArray(groups[group])
        });
        this.groupingInfo.push(info);
      }
    }

    return groupResult;
  }

  onPointChange(event): void {
    if (this.point !== event.option.value) {
      this.point = event.option.value;
    }
  }

  filterPoint(event) {
    let filter = event instanceof Object ? event.name : event;
    this.filteredPoints = this.listOfPoints.filter(point => {
      return point.name.toLowerCase().indexOf(filter.toLowerCase()) >= 0 &&
        this.tags.filter(x => point.tags != null && point.tags.indexOf(x) !== -1).length === this.tags.length;
    });
  }

  onGroupingChange(): void {
    for (let serie in this.seriesOriginalData) {
      let groupResult = this.GroupData(serie, this.series[serie].current.name);
      this.series[serie].current["data"] = groupResult.data;
      this.series[serie].past["data"] = groupResult.past;

      let xAxis = this.groupingBy.xAxisOptions;
      if (groupResult.past.length > 0 && groupResult.data.length > 0) {
        if (groupResult.data[0].x < groupResult.past[0].x) {
          xAxis.min = groupResult.data[0].x - xAxis.tickInterval;
        } else {
          xAxis.min = groupResult.past[0].x - xAxis.tickInterval;
        }
        if (groupResult.data[groupResult.data.length - 1].x > groupResult.past[groupResult.data.length - 1].x) {
          xAxis.max = groupResult.data[groupResult.data.length - 1].x + xAxis.tickInterval;
        } else {
          xAxis.max = groupResult.past[groupResult.data.length - 1].x + xAxis.tickInterval;
        }
      } else if (groupResult.data.length > 0) {
        xAxis.min = groupResult.data[0].x - xAxis.tickInterval;
        xAxis.max = groupResult.data[groupResult.data.length - 1].x + xAxis.tickInterval;

      } else if (groupResult.past.length > 0) {
        xAxis.min = groupResult.past[0].x - xAxis.tickInterval;
        xAxis.max = groupResult.past[groupResult.past.length - 1].x + xAxis.tickInterval;
      }
      this.chart.updateChart({ xAxis: xAxis });
      this.chart.updateSeries({ data: groupResult.data.map(p => p.dataMissing > 0 ? p : [p.x, p.y]) }, serie);
      if (groupResult.past.length > 0) {
        this.chart.updateSeries({ data: groupResult.past.map(p => p.dataMissing > 0 ? p : [p.x, p.y]) }, serie + "_past");
      } else {
        this.chart.removeById(serie + "_past");
      }
      this.chart.zoomOut();
      let lines = [
        {
          value: groupResult.peak,
          color: "red",
          zIndex: 6,
          dashStyle: "shortdash",
          width: 2,
          label: {
            text: "Data Max"
          }
        }
      ];

      if (this.point.maxValue && this.point.maxValue != groupResult.peak) {
        lines.push({
          value: this.point.maxValue,
          color: "red",
          zIndex: 7,
          dashStyle: "shortdash",
          width: 2,
          label: {
            text: "User Max"
          }
        });
      }

      if (groupResult.avg != groupResult.peak) {
        lines.push({
          value: groupResult.avg,
          color: "green",
          zIndex: 6,
          dashStyle: "shortdash",
          width: 2,
          label: {
            text: "Data Avg"
          }
        });
      }

      if (groupResult.min && groupResult.min != groupResult.peak && groupResult.min != groupResult.avg) {
        lines.push({
          value: groupResult.min,
          color: "blue",
          zIndex: 6,
          dashStyle: "shortdash",
          width: 2,
          label: {
            text: "Data Min"
          }
        });
      }

      if (this.point.minValue && this.point.minValue != groupResult.peak) {
        lines.push({
          value: this.point.minValue,
          color: "red",
          zIndex: 7,
          dashStyle: "shortdash",
          width: 2,
          label: {
            text: "User Min"
          }
        });
      }

      this.chart.updateYaxis({
        plotLines: lines,
        min: groupResult.min - (groupResult.peak - groupResult.min) * 0.1,
        max: groupResult.peak + (groupResult.peak - groupResult.min) * 0.1
      });
    }
  }

  filterBuildings(event) {
    let filter = event instanceof Object ? event.name : event;
    this.filteredBuildings = this.listOfBuildings.filter(building => {
      let add = building.name.toLowerCase().indexOf(filter.toLowerCase()) >= 0 ||
        building.description.toLowerCase().indexOf(filter.toLowerCase()) >= 0;
      if (building.address) {
        add = add || building.address.street.toLowerCase().indexOf(filter.toLowerCase()) >= 0;
      }
      return add;
    });
  }

  onBuildingChange(event) {
    if (this.building !== event.option.value) {
      this.building = event.option.value;

      this.session.building = this.building._id;

      // this.spinner.activate();

      let data = [];
      this.point = "";
      this.pointName = "";
      this.form.get('point').setValue('');
      // this.dataService.GetListOfPoints(this.building._id, true).subscribe(
      //   resp => {
      //     data = data.concat(resp["data"]);
      //     this.listOfPoints = data;
      //     this.filterTags("");
      //     this.tags = this.tags.filter((t) => this.filteredTags.indexOf(t) != -1);

      //     this.filterPoint(this.point);
      //     this.spinner.desactivate();
      //   },
      //   error => {
      //     this.building = undefined;
      //     this.buildingFilter = "";
      //     console.log(error);
      //     this.spinner.desactivate();
      //   });
    }
  }

  displayByName(obj) {
    return obj ? obj.name : obj;
  }

  tagsChanged(event) {
    this.filterPoint(this.point);
  }

  filterTags(filter) {
    if (this.building) {
      this.filteredTags = (this.listOfTags[this.building._id.toString()] || []).
        filter(tag => tag != undefined ? tag.toLowerCase().indexOf(filter.toLowerCase()) >= 0 : false);
    } else {
      this.filteredTags = [];
    }
  }
}
