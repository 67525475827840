import { Component, OnInit } from '@angular/core';
import { CompanyService } from 'app/services/company.service';
import { ActivatedRoute, Router } from '@angular/router';
import { SpinnerService } from 'app/services/spinner.service';
import { Company } from 'app/interfaces/company';
import { SweetalertService } from 'app/services/sweetalert.service';
import { AppI18nService } from 'app/services/app.i18n.service';
import { IReturnedError } from 'app/services/base.service';
import { UserType } from 'app/interfaces/baseinterface';

@Component({
  selector: 'app-root-edit-company',
  templateUrl: './root-edit-company.component.html',
  styleUrls: ['./root-edit-company.component.css']
})
export class RootEditCompanyComponent implements OnInit {

  private companyId = '';
  public company: Company = undefined;
  private originalCompany: Company;

  constructor(private router: Router, private companyService: CompanyService, public i18n: AppI18nService,
    private spinnerService: SpinnerService, private alertService: SweetalertService, private route: ActivatedRoute) {
  }

  ngOnInit() {
    this.spinnerService.activate();
    this.route.params.subscribe(params => {
      this.companyId = params['id'];

      this.companyService.getOne(this.companyId).subscribe(company => {
        this.spinnerService.desactivate();
        this.originalCompany = company.data;
        this.company = company.data;
      });
    });
  }

  public save(event) {
    let newCompany: Company = {
      contacts: {
        telephones: event.value.telephones,
        emails: event.value.emails
      },
      name: event.value.name,
      responsibles: event.value.responsibles,
      primaryEmail: event.value.primaryEmail,
      primaryTelephone: event.value.primaryTelephone,
      address: {
        number: event.value.address.number,
        city: event.value.address.city,
        country: event.value.address.country,
        neighborhood: event.value.address.neighborhood,
        street: event.value.address.street,
        zipCode: event.value.address.zipcode,
        complement: event.value.address.complement,
        lat: 1,
        lng: 1
      }
    }



    // console.log(newCompany);

    this.spinnerService.activate();
    this.companyService.update(this.originalCompany, newCompany).subscribe(
      res => {
        this.spinnerService.desactivate();
        this.alertService.success(this.i18n.translate('general.messages.success'),
          this.i18n.translate('general.messages.updated')
        );
        this.router.navigate(['/root/companies']);
      },
      err => {
        let errors: IReturnedError = {
          errors: []
        };

        for (let x of err.errors) {
          errors.errors.push({ field: '', message: x });
        }

        this.spinnerService.desactivate();
        this.alertService.error(this.i18n.translate('general.messages.error'),
          this.i18n.translate('general.messages.invalidForm', this.companyService.generateHtmlListOfErrors(errors))
        );
      });
  }
}



