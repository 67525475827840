import { Component, OnInit } from "@angular/core";
import { User } from "app/interfaces/user";
import { UserService } from "app/services/user.service";
import { SpinnerService } from "app/services/spinner.service";
import { Router } from "@angular/router";
import { SweetalertService } from "app/services/sweetalert.service";
import { AppI18nService } from "app/services/app.i18n.service";

@Component( {
  selector: "app-root-create-user",
  templateUrl: "./root-create-user.component.html",
  styleUrls: [ "./root-create-user.component.css" ]
} )
export class RootCreateUserComponent implements OnInit {

  constructor( private userService: UserService, private spinnerService: SpinnerService, private router: Router,
    private alert: SweetalertService, private i18n: AppI18nService ) {
  }

  ngOnInit() {
  }

  public save( event ) {
    let user: User = {
      company: event.value.company,
      email: event.value.primaryEmail,
      person: {
        city: event.value.city,
        country: event.value.country,
        landline: event.value.landline,
        contacts: {
          emails: event.value.emails,
          telephones: event.value.telephones
        },
        primaryEmail: event.value.primaryEmail,
        name: event.value.name,
        mobilePhone: event.value.mobilephone
      },
      type: event.value.type,
      username: event.value.primaryEmail
    };

    this.spinnerService.activate();

    this.userService.create( user ).subscribe( x => {
        this.spinnerService.desactivate();
        this.alert.success( this.i18n.translate( "general.messages.success" ),
          this.i18n.translate( "general.messages.created", this.i18n.translate( "interfaces.user.user" ) ) );
        this.router.navigate( [ "/root/users" ] );
      },
      err => {
        this.spinnerService.desactivate();
        // this.alert.error(this.i18n.translate("general.errors.error"), this.i18n.translate("general.errors.unkwonError", err));
      }
    );
  }

}
