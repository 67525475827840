import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GroupsAssetsFormComponent } from './groups-assets-form.component';
import { IboxModule } from '../ibox/ibox.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatSelectModule } from '@angular/material/select';
import { MatIconModule } from '@angular/material/icon';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslocoModule } from '@ngneat/transloco';



@NgModule({
  declarations: [GroupsAssetsFormComponent],
  imports: [
    CommonModule,
    IboxModule,
    ReactiveFormsModule,
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatAutocompleteModule,
    MatSelectModule,
    MatIconModule,
    MatTableModule,
    MatTabsModule,
    MatTooltipModule,
    TranslocoModule
  ],
  exports:[GroupsAssetsFormComponent]
})
export class GroupsAssetsFormModule { }
