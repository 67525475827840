import { Component, Input, OnInit } from '@angular/core';
import { PointgroupsService } from 'app/services/pointgroups.service';
import { SpinnerService } from 'app/services/spinner.service';
import { AssetGroupList } from '../admin-asset-view.component';

@Component({
  selector: 'app-asset-item',
  templateUrl: './asset-item.component.html',
  styleUrls: ['./asset-item.component.css']
})
export class AssetItemComponent implements OnInit {

  @Input() item: AssetGroupList = null;

  constructor(private spinner:SpinnerService, private groupService: PointgroupsService) { }

  ngOnInit(): void {
  }

  public open(){
    this.item.open = !this.item.open;
  }
}
