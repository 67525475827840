import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SweetalertService } from './sweetalert.service';

import * as moment from "moment-timezone";

@Injectable()
export class VersionCheckService {
  // this will be replaced by actual hash post-build.js
  private currentHash = '{{POST_BUILD_ENTERS_HASH_HERE}}';
  private currentVersion = '{{POST_BUILD_ENTERS_VERSION_HERE}}'
  private lastCheck = moment().format("DD/MM/YY HH:mm");
  private isOld: boolean = false;

  get lastUpdate() {
    return this.lastCheck;
  }

  get needsRefresh() {
    return this.isOld;
  }

  get hash() {
    return this.currentHash;
  }

  get version() {
    return this.currentVersion;
  }

  constructor(private http: HttpClient, private alertService: SweetalertService) { }

  /**
   * Checks in every set frequency the version of frontend application
   * @param url
   * @param {number} frequency - in milliseconds, defaults to 30 minutes
   */
  public initVersionCheck(url, frequency = 1000 * 10 * 10) {
    setInterval(() => {
      this.checkVersion(url);
    }, frequency);
  }

  /**
   * Will do the call and check if the hash has changed or not
   * @param url
   */
  private checkVersion(url) {
    // timestamp these requests to invalidate caches
    this.http.get(url + '?t=' + new Date().getTime())
      .subscribe(
        (response: any) => {
          const hash = response.hash;
          const hashChanged = this.hasHashChanged(this.currentHash, hash);

          // If new version, do something
          if (hashChanged) {
            this.alertService.info("Attention!", "A new version of the website has been launched. Please press [ Ctrl + F5 ] to refresh the browser cache.").then(x => {
              this.isOld = true;
            })

            this.currentHash = hash;
            this.currentVersion = response.version;
          }
          this.lastCheck = moment().format("DD/MM/YY HH:mm");
        },
        (err) => {
          console.error(err, 'Could not get version');
        }
      );
  }

  /**
   * Checks if hash has changed.
   * This file has the JS hash, if it is a different one than in the version.json
   * we are dealing with version change
   * @param currentHash
   * @param newHash
   * @returns {boolean}
   */
  private hasHashChanged(currentHash, newHash) {
    return currentHash !== newHash;
  }
}
