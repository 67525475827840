import { Component, OnInit, ViewChild } from '@angular/core';
import { detectBody } from '../../../app.helpers';
import { SpinnerService } from 'app/services/spinner.service';
import { Router, RouterOutlet } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { NgxCaptureService } from 'ngx-capture';
import { HelpPageService } from 'app/services/help-page.service';
import { MatDialog } from '@angular/material/dialog';
import { HelpPopupComponent } from 'app/views/admin/help/help-popup/help-popup.component';

declare var jQuery: any;

@Component({
  selector: 'app-basic',
  templateUrl: 'basicLayout.template.html',
  host: {
    '(window:resize)': 'onResize()'
  },
  styleUrls: ["basicLayout.component.css"]
})
export class BasicLayoutComponent implements OnInit {

  @ViewChild(RouterOutlet, { static: true }) outlet;

  constructor(public dialog: MatDialog, public helpPageService: HelpPageService,  private captureService: NgxCaptureService, private spinner: SpinnerService, private router: Router, private translocoService: TranslocoService) {
    router.events.subscribe((val) => {
      if (this.spinner.isBusy) {
        this.spinner.fullStop();
      }
    });
  }


  public takeScreenshot() {
    this.captureService.getImage(this.outlet.activated.location.nativeElement, true).subscribe(img => {
      var a = document.createElement("a"); //Create <a>
      a.href = img; //Image Base64 Goes here
      a.download = "Image.png"; //File name Here
      a.click(); //Downloaded file
    })
  }

  public loadHelp(){
    const dialogRef = this.dialog.open(HelpPopupComponent, {
      width: '1080px',
      data: {
        url: this.helpPageService.pageUrl
      }
    })
  }

  DataURIToBlob(dataURI: string) {
    const splitDataURI = dataURI.split(',')
    const byteString = splitDataURI[0].indexOf('base64') >= 0 ? atob(splitDataURI[1]) : decodeURI(splitDataURI[1])
    const mimeString = splitDataURI[0].split(':')[1].split(';')[0]

    const ia = new Uint8Array(byteString.length)
    for (let i = 0; i < byteString.length; i++)
      ia[i] = byteString.charCodeAt(i)

    return new Blob([ia], { type: mimeString })
  }

  public ngOnInit(): any {
    detectBody();
  }

  public onResize() {
    detectBody();
  }
}
