import { AfterViewInit, Component, OnInit, ViewChild } from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { AppService } from "app/services/app.service";
import { AppI18nService } from "app/services/app.i18n.service";
import { SpinnerService } from "app/services/spinner.service";
import { SweetalertService } from "app/services/sweetalert.service";
import { BuildingService } from "app/services/building.service";
import { ActivatedRoute } from "@angular/router";

@Component( {
  selector: "app-authorise-point",
  templateUrl: "./authorise-points.component.html",
  styleUrls: [ "./authorise-points.component.css" ]
} )
export class AuthorisePointComponent implements OnInit, AfterViewInit {

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  public dataSource = new MatTableDataSource();

  public displayedColumns: string[];

  private id: string;
  public normalView = false;

  constructor( private route: ActivatedRoute, public appService: AppService, public i18n: AppI18nService,
    private spinnerService: SpinnerService, private alert: SweetalertService, private buildingService: BuildingService ) {
    this.route.queryParams.subscribe( query => {
      this.normalView = !query["unAuth"];
    });
  }

  ngOnInit() {
    this.appService.sectionName = this.i18n.translate( "general.sections.root.authorise.authorise" );

    this.displayedColumns = [ "deviceId", "deviceHash", "secretKey", "numberOfPoints", "operations" ];

    this.spinnerService.activate();
    this.route.params.subscribe( params => {
      this.id = params[ "building" ];
      this.buildingService.getNewDevices( this.id, !this.normalView ).subscribe(
        results => {
          this.dataSource.data = results.data;
          this.spinnerService.desactivate();
        },
        err => {
          this.spinnerService.desactivate();
          this.alert.error(this.i18n.translate("general.errors.error"), this.i18n.translate("general.errors.unkwonError", err));
        }
      );
    } );
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  public applyFilter( filterValue: string ) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
    this.dataSource.filter = filterValue;
  }

  public authorisePoint( point, rejected ) {
    this.spinnerService.activate();
    this.buildingService.setNewDeviceAuth( this.id, point, rejected ).subscribe(
      () => {
        let index = this.dataSource.data.findIndex(obj => obj["_id"] === point );
        if( index !== -1 ) {
          this.dataSource.data.splice( index, 1 );
          this.dataSource.paginator = this.paginator;
        }
        this.spinnerService.desactivate();
      },
      err => {
        this.spinnerService.desactivate();
        this.alert.error(this.i18n.translate("general.errors.error"), this.i18n.translate("general.errors.unkwonError", err));
      }
    );
  }


  public stringifyObj(data){
    if( !data ){
      return "";
    }
    return Object.keys(data).map( (k) => k + ": " + data[k]).join(", ")
  }
}
