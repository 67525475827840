import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { AppService } from "app/services/app.service";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { LoginService } from "app/services/login.service";
import { SweetalertService } from "app/services/sweetalert.service";
import { SpinnerService } from "../../../services/spinner.service";
import { ActivatedRoute, Router } from "@angular/router";
import { TranslocoService } from "@ngneat/transloco";

@Component({
  selector: "app-recovery",
  templateUrl: "./recovery.component.html",
  styleUrls: ["./recovery.component.css"],
  encapsulation: ViewEncapsulation.None
})
export class RecoveryComponent implements OnInit {

  constructor(private route: ActivatedRoute, private router: Router, private spinner: SpinnerService,
    private loginService: LoginService, public appService: AppService, private alert: SweetalertService, private translocoService: TranslocoService) {
    this.route.queryParams.subscribe(query => {
      if (query["username"]) {
        this.usernameForm = new FormGroup({
          username: new FormControl(query["username"], Validators.required)
        });
        this.typingUsername = false;
        if (query["code"]) {
          this.verifyForm = new FormGroup({
            newPassword: new FormControl("", [Validators.required]),
            newPasswordConfirmation: new FormControl("", [Validators.required, validatePassword]),
            code: new FormControl(query["code"], Validators.required)
          });
        }
      }
    });
  }

  public typingUsername: boolean = true;

  public usernameForm: FormGroup = new FormGroup({
    username: new FormControl("", Validators.required)
  });

  public verifyForm: FormGroup = new FormGroup({
    newPassword: new FormControl("", [Validators.required]),
    newPasswordConfirmation: new FormControl("", [Validators.required, validatePassword]),
    code: new FormControl("", Validators.required)
  });

  public onSumitUsername() {
    let that = this;
    this.spinner.activate();
    this.loginService.forgotPassword(this.usernameForm.value.username).subscribe(
      (result) => {
        that.spinner.desactivate();
        that.alert.info(that.translocoService.translate("auth.recovery.alerts.warning"), that.translocoService.translate("auth.recovery.alerts.verifyCode"));
        that.typingUsername = false;
      },
      (error) => {
        console.error(error);
        that.spinner.desactivate();
        that.alert.info(that.translocoService.translate("auth.recovery.alerts.warning"), that.translocoService.translate("auth.recovery.alerts.verifyCode"));
        that.typingUsername = false;
      }
    );
  }

  ngOnInit() {
    this.verifyForm.controls["newPassword"].valueChanges.subscribe!({
      next: (value) => {
        this.verifyForm.controls["newPasswordConfirmation"].updateValueAndValidity();
      }
    });
  }

  public onSubmitPassword() {
    this.spinner.activate();
    let that = this;
    this.loginService.confirmNewPassword(this.usernameForm.value.username, this.verifyForm.value.code, this.verifyForm.value.newPassword).
      subscribe(
        (res) => {
          that.spinner.desactivate();
          that.alert.success(that.translocoService.translate("auth.recovery.alerts.success"),
            that.translocoService.translate("auth.recovery.alerts.passwordReseted"));
          that.router.navigate(["/auth/login"]);
        },
        (error) => {
          console.error(error);
          that.spinner.desactivate();
          that.alert.info(that.translocoService.translate("auth.recovery.alerts.error"), that.translocoService.translate("auth.recovery.form.passwordTooltip"));
        });
  }
}

export function validatePassword(f: FormControl) {
  if (f.parent) {
    return f.parent.controls["newPassword"].value == f.parent.controls["newPasswordConfirmation"].value ?
      null :
      { validatePassword: { valid: false } };
  }
  return undefined;
}
