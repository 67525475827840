import { DateWrapperService } from "app/services/date-wrapper.service";
import * as moment from "moment-timezone";
import { Moment } from "moment-timezone";

import { ChangeDetectorRef, Injector } from "@angular/core";

const injector = Injector.create({
  providers: [
    { provide: DateWrapperService },
  ]
});

export class FutureDecisionsDate {
  private momentObj: Moment;

  private dateWrapperService: DateWrapperService;

  private _format: string = "";

  constructor(dateString: string = undefined) {

    this.dateWrapperService = injector.get(DateWrapperService);

    this.initDate(dateString);

    this.dateWrapperService.timezoneChanged.subscribe(x => {
      this.momentObj.tz(x);
    })
  }

  public clone(){
    return new FutureDecisionsDate(this.momentObj.toISOString());
  }

  get dateObject() {
    return this.momentObj;
  }

  set dateObject(value) {
    this.momentObj = value;
  }


  private initDate(dateString: string) {
    if (this.dateWrapperService.tz == "")
      this.momentObj = moment.utc(dateString);
    else {
      this.momentObj = moment(dateString);
      this.momentObj.tz(this.dateWrapperService.tz);
    }
  }

  public fromValue(value: number) {
    this.initDate(value.toString());
  }

  public add(value: number, unit): FutureDecisionsDate {
    this.momentObj.add(value, unit);
    return this;
  }

  public subtract(value: number, unit): FutureDecisionsDate {
    this.momentObj.subtract(value, unit);
    return this;
  }

  public format(format: string) {
    this._format = format;
  }

  public startOf(unit: TimeUnit): FutureDecisionsDate {
    this.momentObj.startOf(unit);
    return this;
  }

  public endOf(unit: TimeUnit): FutureDecisionsDate {
    this.momentObj.endOf(unit);
    return this;
  }

  public toString(format: string = this._format) {
    return this.momentObj.format(format);
  }

  get valueOf(): number {
    return this.momentObj.valueOf();
  }
}


type TimeUnit = (
  "year" |
  "month" |
  "week" |
  "day" |
  "hour" |
  "minute" |
  "second" |
  "millisecond"
)
