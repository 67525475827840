import { Component, OnInit, ViewChild } from '@angular/core';
import { UserService } from 'app/services/user.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { AppService } from 'app/services/app.service';
import { SpinnerService } from 'app/services/spinner.service';
import { SweetalertService } from 'app/services/sweetalert.service';
import { LoginService } from 'app/services/login.service';
import { TranslocoService } from '@ngneat/transloco';
import { BuildingService } from 'app/services/building.service';
import { MatDialog } from '@angular/material/dialog';
import { UsersBuildingsComponent } from 'app/components/common/users-buildings/users-buildings.component';
import { UserType } from 'app/interfaces/baseinterface';
import { Location } from '@angular/common';
import { QueryparamsService } from 'app/services/queryparams.service';
import { HelpPageService } from 'app/services/help-page.service';

@Component({
  selector: 'app-admin-users',
  templateUrl: './admin-users.component.html',
  styleUrls: ['./admin-users.component.css']
})
export class AdminUsersComponent implements OnInit {

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  public dataSource = new MatTableDataSource();

  public displayedColumns: string[];

  constructor(private helppageService: HelpPageService,private queryService: QueryparamsService, public dialog: MatDialog, private buildingService: BuildingService, private transloco: TranslocoService, private loginService: LoginService, public userService: UserService, private appService: AppService, private spinnerService: SpinnerService, private alert: SweetalertService) { }
  private buildings = [];
  private allUsers = [];

  public currentUser = this.loginService.userInfo._id;

  ngOnInit() {

    this.helppageService.changeUrl("./assets/help-pages/users.html?language=" + this.appService.getLanguage());

    this.spinnerService.activate();
    this.buildingService.getAll().subscribe(x => {
      this.buildings = x.data;
      this.spinnerService.desactivate();

      if (this.loginService.userInfo.type >= 80) {
        this.displayedColumns = ['name', 'contact_email', 'buildings', 'type', "delete"];
      }
      else {
        this.displayedColumns = ['name', 'contact_email', 'type', "delete"];
      }

      this.queryService.params().subscribe(params => {
        this.pagination.pageSize = params["pageSize"] ? parseInt(params["pageSize"]) : 100;
        this.pagination.page = params["page"] ? parseInt(params["page"]) : 1;
        this.filter = params["filter"];


        this.updateData();
      })
    })

  }

  public pagination: { hasNext: boolean, page: number, pageSize: number } = { hasNext: false, page: 1, pageSize: 100 }
  public changePage(event) {

    this.pagination.page = event.page;
    this.pagination.pageSize = event.pageSize;

    this.updateData();
  }

  public filter: string = "";
  public applyFilter(value) {
    this.filter = value;
    this.pagination.page = 1;
    this.updateData();
  }

  private updateData() {
    this.spinnerService.activate();

    this.queryService.ReplaceParams({
      filter: this.filter,
      page: this.pagination.page,
      pageSize: this.pagination.pageSize
    }).subscribe(x => {

    });

    this.userService.getAll(undefined, this.pagination, this.filter, ["company"]).subscribe(
      results => {
        // console.log(results);
        this.pagination.hasNext = results.data.length > this.pagination.pageSize;
        this.allUsers = results.data;

        this.dataSource.data = results.data.map(x => {
          let contactInfo = [];
          if (x.person.contacts.emails.length > 0) {
            for (let email of x.person.contacts.emails) {
              contactInfo.push(email);
            }
          }

          if (x.person.contacts.telephones) {
            for (let telephone of x.person.contacts.telephones) {
              contactInfo.push(telephone.number + (x.internalExtension ? (" " + x.interalExtension) : ""))
            }
          }

          // console.log(x);

          return {
            id: x._id,
            username: x.username,
            company: (x.company ? x.company.name : ""),
            buildings: `${x.type == UserType.CompanyAdmin ? this.buildings.length : x.buildings.length}/${this.buildings.length}`,
            type: this.transloco.translate("usertype." + x.type.toString()),
            landline: (x.person.landline ? x.person.landline.number : "") + " " + (x.person.landline ? x.person.landline.internalExtension : ""),
            mobile: x.person.mobilePhone ? x.person.mobilePhone : "",
            email: x.email,
            name: x.person.name,
            contactInfo: contactInfo.join(",")
          }
        });
        this.spinnerService.desactivate();
      },
      err => {
        this.spinnerService.desactivate();
      }
    );
  }

  public opentEditBuidlings(id) {
    let user = this.allUsers.find((x: any) => x._id == id);
    let userBuildings = user.type == UserType.CompanyAdmin ? this.buildings.map(x => { return { id: x._id, permission: UserType.CompanyAdmin } }) : user.buildings;

    const dialogRef = this.dialog.open(UsersBuildingsComponent, {
      disableClose: true,
      width: '960px',
      data: {
        user: user.type,
        buildings: this.buildings,
        userBuildings: userBuildings
      }
    });

    dialogRef.afterClosed().subscribe(x => {
      if (x) {
        this.spinnerService.activate();
        user.buildings = x;
        this.userService.update(user, user).subscribe(res => {
          console.log(x);

          this.spinnerService.desactivate();

          this.alert.success(this.transloco.translate('messages.success'), this.transloco.translate('components.userbuildings.updatepermission'));

          this.updateData();
        })
      }

    })
  }

  public deleteUser(id) {
    this.spinnerService.activate();
    this.userService.delete(id).subscribe(x => {
      this.spinnerService.desactivate();

      this.updateData();
    })
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }



}
