import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UserType } from 'app/interfaces/baseinterface';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { AppService } from './app.service';

@Injectable({
  providedIn: 'root'
})
export class CompanyViewService {

  constructor(public appService: AppService, private http: HttpClient) { }

  //[POST] auth/mimicUserType
  public mimicUserType(type: UserType, company: string, buildings?: string[]): Observable<any> {
    let session: any = JSON.parse(localStorage.getItem(this.appService.productKey + ".loggedUser"));
    return new Observable(observer => {
      this.http.post(environment.apiUrl + "auth/mimicUserType", {
        type: type,
        company: company,
        buildings: buildings
      }, {
        headers: { Authorization: session.token }
      }).subscribe(
        t => {
          observer.next(t);
          observer.complete();
        },
        err => {
          console.log(err);

          observer.error(err);
          observer.complete();
        }
      );
    })

  }

  public getCurrentCompany() {
    return JSON.parse(localStorage.getItem(this.appService.productKey + ".loggedCompany"));
  }

  public setCurrentCompany(company: any) {
    localStorage.setItem(this.appService.productKey + ".loggedCompany", JSON.stringify(company))
  }

  public onLogout() {
    localStorage.removeItem(this.appService.productKey + ".loggedCompany")
  }
}
