import { Component, OnInit } from '@angular/core';
import { BuildingService } from 'app/services/building.service';
import { ActivatedRoute } from '@angular/router';
import { SpinnerService } from 'app/services/spinner.service';

import * as moment from "moment-timezone";
import * as humanizeDuration from "humanize-duration"

import { ReportService } from 'app/services/report.service';
import { TranslocoService } from '@ngneat/transloco';
import { timeStamp } from 'console';

@Component({
  selector: 'app-admin-integrity-issues',
  templateUrl: './admin-integrity-issues.component.html',
  styleUrls: ['./admin-integrity-issues.component.css']
})
export class AdminIntegrityIssuesComponent implements OnInit {

  constructor(private transloco: TranslocoService, private reportService: ReportService, private buildingService: BuildingService, private route: ActivatedRoute, private spinner: SpinnerService) {

  }

  public activeOnly: boolean = true;

  public devices: { name: string, id: string }[] = []

  public data: {
    id: string,
    name: string,
    active: boolean,
    activeCount: number,
    ords: {
      active: boolean
      id: string,
      name: string,
      issues: {
        active: boolean,
        start: string,
        end?: string
        type: string,
        duration: string
      }[]
    }[]
  }[] = [];

  public buildings: { id: string, name: string }[] = [];
  public selectedBuilding: string = "";

  ngOnInit() {
    this.spinner.activate();
    this.buildingService.getAll().subscribe(x => {
      this.spinner.desactivate();
      this.buildings = x.data.map(t => {
        return {
          id: t._id,
          name: t.name
        }
      })

      this.route.queryParams.subscribe(p => {
        if (p["building"]) {
          this.selectedBuilding = p["building"];

          this.onBuildingChange();
        }
      })
    });
  }

  public toDisplay: {
    id: string,
    name: string,
    active: boolean
    ords: {
      active: boolean
      id: string,
      name: string,
      issues: {
        active: boolean,
        start: string,
        end?: string
        type: string,
        duration: string
      }[]
    }[]
  }[] = [];
  ;

  public hasNoIssue: boolean = false;
  public onBuildingChange() {
    this.spinner.activate();

    this.buildingService.getIntegrityIssues(this.selectedBuilding).subscribe(x => {
      this.spinner.desactivate();


      let names = this.checkCommandsName(x.data.map(t => { return t.ord }));

      this.data = [];

      this.data = x.devices.map(t => {
        return {
          id: t._id,
          name: t.friendlyName,
          ords: []
        }
      })

      for (let i = 0; i < x.data.length; i++) {

        let temp = x.data[i];
        let device = this.data.find(d => d.id == temp.device);
        device.active = false;
        device.activeCount = 0;

        let tempEntity: {
          name: string,
          id: string,
          active: boolean,
          issues: {
            active: boolean,
            start: string,
            type: string,
            end?: string,
            duration: string
          }[]
        } = {
          active: false,
          id: temp.point,
          name: names[i],
          issues: []
        };

        for (let key of Object.keys(temp.integrityIssues)) {
          let issue = temp.integrityIssues[key];

          // if (issue.active) {
          let detailed = issue.detailed//.find(is => is.active == true);

          if (detailed)
            for (let iss of detailed) {
              if (iss.active) {
                tempEntity.active = true;
                device.active = true;
              }


              tempEntity.issues.push({
                active: iss.active,
                start: moment(iss.start).format("DD/MM/YYYY HH:mm Z"),
                type: this.reportService.getErrorType(key),
                end: !iss.active ? moment(iss.end).format("DD/MM/YYYY HH:mm Z") : "Issue active",
                duration: iss.active ? humanizeDuration(moment().diff(moment(iss.start)), { round: true }) : humanizeDuration(moment(iss.end).diff(moment(iss.start)), { round: true, units: ["d", "h"] })
              })
            }
          // }
        }
        device.ords.push(tempEntity);
      }

      for (let d of this.data) {
        this.devices.push({
          id: d.id,
          name: d.name + " " + this.transloco.translate('admin.integrity.issues.status', { issues: d.ords.length, active: d.activeCount })
        })
      }
      // this.optionsGroups = this.data.map(d => {
      //   return {
      //     name: d.name,
      //     ords: d.ords.map(o => {
      //       this.selectedOrds.push(o.name);

      //       return o.name
      //     })
      //   }
      // })
      // this.toDisplay = this.data.map(x => x);

      // this.hasNoIssue = this.optionsGroups.length == 0;

    })
  }

  public availableOrds: { id: string, name: string }[] = [];
  public addOrd(event) {
    let ord = this.data.find(x => x.id == this.selectedDevice).ords.find(x => x.id == event.value);

    if(this.selectedOrds.find(x => x.id == ord.id)) return;

    this.selectedOrds.push(ord);

    let original = this.data.find(x => x.id == this.selectedDevice);

    let device = this.toDisplay.find(x => x.id == this.selectedDevice);
    if(!device){
      this.toDisplay.push({
        active: original.active,
        id: original.id,
        name: original.name,
        ords: []
      })
    }

    this.toDisplay.find(x => x.id == this.selectedDevice).ords.push(ord);
  }

  public removeOrd(ord){
    this.selectedOrds.splice(this.selectedOrds.indexOf(ord), 1);

    let device = this.toDisplay.find(x => x.id == this.selectedDevice);
    device.ords.splice(device.ords.indexOf(ord), 1);
    if(device.ords.length == 0){
      this.toDisplay.splice(this.toDisplay.indexOf(device), 1);
    }
  }

  public selectedDevice: string = "";
  public onDeviceChange() {

    this.availableOrds = []
    for (let x of this.data.find(x => x.id == this.selectedDevice).ords) {

      if (this.activeOnly && x.active || !this.activeOnly) {

        let name = x.name.split("/");
        let endName = "";
        if (name.length >= 3) {
          endName = name[name.length - 3] + "/" + name[name.length - 2] + "/" + name[name.length - 1];
        }
        else if (name.length == 2) {
          endName = name[name.length - 2] + "/" + name[name.length - 1];
        }
        else
          endName = name[name.length - 1];

        this.availableOrds.push({
          id: x.id,
          name: endName + (x.active ? " " + this.transloco.translate('admin.integrity.issues.active') : "")
        })
      }
    }
  }

  public changeOrds(event) {
    console.log(this.selectedOrds);
    this.toDisplay.map(x => {
      x.ords.find(t => this.selectedOrds.indexOf(t) > 0)
    })
  }

  public selectedOrds = [];
  public optionsGroups: { name: string, ords: string[] }[] = [];

  private checkCommandsName(ords: string[]) {
    if (ords.length > 0) {
      let inAll = ords[0].split("/");

      for (let ord of ords) {
        let parts = ord.split("/");

        for (let i = 0; i < inAll.length; i++) {
          if (i > parts.length) {
            continue;
          }
          else {
            if (parts[i] != inAll[i]) {
              inAll = inAll.slice(0, i);
              break;
            }
          }
        }
      }

      let t = inAll.join('/');
      let ret = [];
      for (let ord of ords) {
        ret.push(ord.replace(t, ""));
      }

      return ret;
    }
  }


}
