import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LoginService } from 'app/services/login.service';

@Component({
  selector: 'app-note-popup',
  templateUrl: './note-popup.component.html',
  styleUrls: ['./note-popup.component.css']
})
export class NotePopupComponent implements OnInit {

  public required = true;

  public note = "";
  public user;

  constructor(@Inject(MAT_DIALOG_DATA) public data, private loginService: LoginService, public dialogRef: MatDialogRef<NotePopupComponent>) { }

  ngOnInit(): void {
    this.required = this.data.required;
    console.log(this.required);

    this.user = this.loginService.userInfo.name
  }

  get canSubmit() {
    if (this.required && this.note != "") {
      return true;
    }
    else if (!this.required) {
      return true;
    }

    return false;
  }

  public save() {
    this.dialogRef.close({ note: this.note });
  }
}
