import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-employeet-dashboard',
  templateUrl: './employeet-dashboard.component.html',
  styleUrls: ['./employeet-dashboard.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class EmployeetDashboardComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
