import { Component, OnInit, Input, OnChanges, EventEmitter, Output } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-alarms-notification',
  templateUrl: './alarms-notification.component.html',
  styleUrls: ['./alarms-notification.component.css']
})
export class AlarmsNotificationComponent implements OnInit, OnChanges {

  @Input() types: { value: string, label: string, number: number, tooltip: string }[] = [];
  @Input() contacts: { email: string, types: number[] }[] = [];

  @Output() onSave: EventEmitter<any> = new EventEmitter<any>();

  public forms: FormGroup[] = [];

  public addNewContac() {
    let n = {
      email: new FormControl("", [Validators.required, Validators.email])
    };

    for (let t of this.types) {
      n[t.value] = new FormControl(false);
    }

    this.forms.push(new FormGroup(n));
  }


  public save() {
    let data: { email: string, types: number[] }[] = [];

    for (let f of this.forms) {
      let ts: number[] = [];

      for (let type of this.types) {
        if (f.get(type.value).value) {
          ts.push(type.number);
        }
      }

      data.push({
        email: f.get("email").value,
        types: ts
      })
    }

    this.onSave.emit(data);
  }

  public removeContact(i) {
    this.forms.splice(i, 1);
  }

  public cleanContacts() {
    this.forms = [];
  }

  constructor() { }

  ngOnChanges(changes: import("@angular/core").SimpleChanges): void {
    if (changes.contacts) {
      this.cleanContacts();

      for (let c of this.contacts) {
        let t = {
          email: new FormControl(c.email, [Validators.required])
        };

        for (let type of this.types) {
          if (c.types.indexOf(type.number) >= 0) {
            t[type.value] = new FormControl(true)
          }
          else {
            t[type.value] = new FormControl(false)
          }
        }

        let ng = new FormGroup(t);
        this.forms.push(ng);
      }
    }
  }

  ngOnInit() {

  }

  get valid() {
    for (let form of this.forms) {
      if (form.invalid) return false;
    }
    return true;
  }

}
