import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatFormField, MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatSelectModule } from "@angular/material/select";
import { BrowserModule } from '@angular/platform-browser';
import { TranslocoModule } from "@ngneat/transloco";
import { AppHighstockComponent } from "./highstock.component";

@NgModule( {
  imports: [
    BrowserModule,
    MatFormFieldModule,
    MatSelectModule,
    MatAutocompleteModule,
    MatInputModule,
    FormsModule,
    TranslocoModule
  ],
  declarations: [ AppHighstockComponent ],
  exports: [ AppHighstockComponent ]
} )
export class AppHighstockModule {
}
