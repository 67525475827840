import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DateSelectorComponent, FormatMonth, FormatMonthYear, FormatYear } from './date-selector.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { FormsModule } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';



@NgModule({
  declarations: [DateSelectorComponent, FormatMonthYear, FormatYear, FormatMonth],
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatDatepickerModule,
    MatInputModule,
    FormsModule,
    MatSelectModule
  ],
  exports:[DateSelectorComponent, FormatMonthYear, FormatYear, FormatMonth]
})
export class DateSelectorModule { }
