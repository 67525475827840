declare var jQuery: any;

export function correctHeight() {

  const pageWrapper = jQuery("#page-wrapper");
  const navbarHeight = jQuery("nav.navbar-default").height();
  const wrapperHeight = pageWrapper.height();

  if (navbarHeight > wrapperHeight) {
    pageWrapper.css("min-height", navbarHeight + "px");
  }

  if (navbarHeight <= wrapperHeight) {
    if (navbarHeight < jQuery(window).height()) {
      pageWrapper.css("min-height", jQuery(window).height() + "px");
    } else {
      pageWrapper.css("min-height", navbarHeight + "px");
    }
  }

  if (jQuery("body").hasClass("fixed-nav")) {
    if (navbarHeight > wrapperHeight) {
      pageWrapper.css("min-height", navbarHeight + "px");
    } else {
      pageWrapper.css("min-height", jQuery(window).height() - 60 + "px");
    }
  }
}

export function detectBody() {
  if (jQuery(document).width() < 769) {
    jQuery("body").addClass("body-small");
  } else {
    jQuery("body").removeClass("body-small");
  }
}

export function smoothlyMenu() {
  if (!jQuery("body").hasClass("mini-navbar") || jQuery("body").hasClass("body-small")) {
    // Hide menu in order to smoothly turn on when maximize menu
    jQuery("#side-menu").hide();
    // For smoothly turn on menu
    setTimeout(
      function () {
        jQuery("#side-menu").fadeIn(400);
      }, 200);
  } else if (jQuery("body").hasClass("fixed-sidebar")) {
    jQuery("#side-menu").hide();
    setTimeout(
      function () {
        jQuery("#side-menu").fadeIn(400);
      }, 100);
  } else {
    // Remove all inline style from jquery fadeIn function to reset menu state
    jQuery("#side-menu").removeAttr("style");
  }
}

let compareType = {
  VALUE_CREATED: "created",
  VALUE_UPDATED: "updated",
  VALUE_DELETED: "deleted",
  VALUE_UNCHANGED: "unchanged"
};

export { compareType };

export function deepCompareObj(obj1: any, obj2: any) {
  if (isFunction(obj1) || isFunction(obj2)) {
    throw new Error("Invalid argument. Function given, object expected.");
  }
  if (isValue(obj2)) {
    return {
      type: compareValues(obj1, obj2),
      data: (obj2 === undefined) ? obj1 : obj2
    };
  }

  let diff = {};
  for (let key in obj1) {
    if (isFunction(obj1[key])) {
      continue;
    }

    let value2 = undefined;
    if ("undefined" !== typeof (obj2[key])) {
      value2 = obj2[key];
    }

    if (isValue(obj2[key])) {
      diff[key] = deepCompareObj(obj1[key], value2);
    } else {
      diff[key] = { data_type: isArray(value2) ? "array" : "object", data: deepCompareObj(obj1[key], value2) };
    }
  }

  for (let key in obj2) {
    if (isFunction(obj2[key]) || ("undefined" !== typeof (diff[key]))) {
      continue;
    }

    if (isValue(obj2[key])) {
      diff[key] = deepCompareObj(undefined, obj2[key]);
    } else {
      diff[key] = { data_type: isArray(obj2[key]) ? "array" : "object", data: deepCompareObj(undefined, obj2[key]) };
    }
  }

  return diff;

}

export function compareValues(value1, value2) {
  if (value1 === value2) {
    return compareType.VALUE_UNCHANGED;
  }
  if (isDate(value1) && isDate(value2) && value1.getTime() === value2.getTime()) {
    return compareType.VALUE_UNCHANGED;
  }
  if ("undefined" === typeof (value1)) {
    return compareType.VALUE_CREATED;
  }
  if ("undefined" === typeof (value2)) {
    return compareType.VALUE_DELETED;
  }

  return compareType.VALUE_UPDATED;
}

export function isFunction(obj) {
  return {}.toString.apply(obj) === "[object Function]";
}

export function isArray(obj) {
  return {}.toString.apply(obj) === "[object Array]";
}

export function isDate(obj) {
  return {}.toString.apply(obj) === "[object Date]";
}

export function isObject(obj) {
  return {}.toString.apply(obj) === "[object Object]";
}

export function isValue(obj) {
  return !isObject(obj) && !isArray(obj);
}

export function getMinArrayObj(array: any[], key: any) {
  let min = null;

  for (let i = 0; i < array.length; i++) {
    let t = array[i][key];
    if (t != null)
      if (t < min || min == null) {
        min = t;
      }
  }

  return min;
}

export function getMaxArrayObj(array: any[], key: any) {
  let max = null;

  for (let i = 0; i < array.length; i++) {
    let t = array[i][key];
    if (t != null)
      if (t > max || max == null) {
        max = t;
      }
  }

  return max;
}

export function getMinArray(arary: any[]) {
  return arary.reduce((min, p) => p < min ? p : min, arary[0]);
}

export function getMaxArray(arary: any[]) {
  return arary.reduce((max, p) => p > max ? p : max, arary[0]);
}
