import { Component, OnInit, EventEmitter, Output, Input, SimpleChanges } from '@angular/core';
import { Location } from '@angular/common';
import { IBuilding } from 'app/interfaces/building';
import { Router } from '@angular/router';
import { CompanyService } from 'app/services/company.service';
import { SpinnerService } from 'app/services/spinner.service';
import { SweetalertService } from 'app/services/sweetalert.service';
import { FormGroup, FormControl, Validators, FormArray } from '@angular/forms';
import { AppService } from 'app/services/app.service';
import { PhoneType } from 'app/interfaces/contact';
import { Subscription, Observable } from 'rxjs';

import * as moment from "moment-timezone";
import { map, startWith } from 'rxjs/operators';
@Component({
  selector: 'app-building-form',
  templateUrl: './building-form.component.html',
  styleUrls: ['./building-form.component.css']
})
export class BuildingFormComponent implements OnInit {

  @Output() onsubmit: EventEmitter<any> = new EventEmitter<any>();
  @Input() building: any;

  @Input() enabledEdit = true;

  public countriesFiltered: Observable<string[]>;

  // name: string;
  // address: string;
  // company: string;
  // description: string;
  // contact: Contact;
  // geographicalPosition: Vector2;

  constructor(public appService: AppService, private router: Router, private buildingService: CompanyService, private _location: Location, private spinnerService: SpinnerService, private alertService: SweetalertService) { }


  private keyPressedTimer: Subscription = new Subscription();

  public search() {
    if (this.buildingForm.get("address").valid) {
      this.keyPressedTimer.unsubscribe();
      this.keyPressedTimer = Observable.timer(2000).subscribe(t => {
        this.spinnerService.activate();

        let temp = this.buildingForm.get("address").value;
        let address = temp.country + " " + temp.state + " " + temp.city + " " + temp.street + " " + temp.number + " " + temp.zipCode;

        this.appService.getGeolocation(address).subscribe(result => {
          this.spinnerService.desactivate();

          this.location_name = result.results[0].formatted_address;
          this.location_lat = result.results[0].geometry.location.lat;
          this.location_long = result.results[0].geometry.location.lng;

          this.buildingForm.get("address").get("lat").setValue(this.location_lat);
          this.buildingForm.get("address").get("lng").setValue(this.location_long);

        });
      });
    }
  }

  public doubleClick(event) {
    this.spinnerService.activate();
    this.appService.getGeolocation(event.coords.lat + " " + event.coords.lng).subscribe(result => {
      this.spinnerService.desactivate();

      this.location_name = result.results[0].formatted_address;
      this.location_lat = result.results[0].geometry.location.lat;
      this.location_long = result.results[0].geometry.location.lng;

      this.buildingForm.get("address").get("lat").setValue(this.location_lat);
      this.buildingForm.get("address").get("lng").setValue(this.location_long);

      // console.log(result.results.find(x => {
      //   if (x.types.find(t => t == "country")) return true;
      // }));
      // this.buildingForm.get("address").get("country").setValue(result.results.find(x => {
      //   if (x.types.find(t => t == "country")) return true;
      // }).address_components[0].short_name);

      let r = result.results[0].address_components;

      let postal = r.find(t => {
        if (t.types.find(x => x == "postal_code")) return true;
      })
      this.buildingForm.get("address").get("zipCode").setValue(postal ? postal.long_name : "")

      let city = r.find(t => {
        if (t.types.find(x => x == "political" || x == "postal_town" || x == "administrative_area_level_2")) return true;
      });
      this.buildingForm.get("address").get("city").setValue(city ? city.long_name : "")

      let country = r.find(t => {
        if (t.types.find(x => x == "country")) return true;
      });
      this.buildingForm.get("address").get("country").setValue(country ? country.short_name : "")

      let route = r.find(t => {
        if (t.types.find(x => x == "route")) return true;
      });
      this.buildingForm.get("address").get("street").setValue(route ? route.short_name : "")

      let street_number = r.find(t => {
        if (t.types.find(x => x == "street_number")) return true;
      });
      this.buildingForm.get("address").get("number").setValue(street_number ? street_number.short_name : "")

      let state = r.find(t => {
        if (t.types.find(x => x == "administrative_area_level_1")) return true;
      });
      this.buildingForm.get("address").get("state").setValue(state ? state.long_name : "")
    });
  }


  public location_name: string = "";
  public location_lat: number = 51.5074086;
  public location_long: number = -0.128555;

  public buildingForm: FormGroup = new FormGroup({
    name: new FormControl('', [Validators.required]),
    timeZone: new FormControl('', [Validators.required]),
    butterWorthRecoveryMethod: new FormControl(1, Validators.required),
    address: new FormGroup({
      complement: new FormControl(''),
      lat: new FormControl(null),
      lng: new FormControl(null),
      zipCode: new FormControl(null, [Validators.required]),
      street: new FormControl(null, [Validators.required]),
      number: new FormControl(null, [Validators.required]),
      state: new FormControl(null, [Validators.required]),
      city: new FormControl(null, [Validators.required]),
      country: new FormControl(null, [Validators.required]),
    }),
    photo: new FormControl(),
    description: new FormControl(''),
    contact: new FormGroup({
      emails: new FormArray([]),
      telephones: new FormArray([]),
    }),
  });

  ngOnChanges(changes: SimpleChanges) {
    if (this.building) {
      this.buildingForm = new FormGroup({
        name: new FormControl({ value: this.building.name, disabled: !this.enabledEdit }, [Validators.required]),
        timeZone: new FormControl({ value: this.building.timeZone, disabled: !this.enabledEdit }, [Validators.required]),
        butterWorthRecoveryMethod: new FormControl({ value: this.building.butterWorthRecoveryMethod || 1, disabled: !this.enabledEdit }, Validators.required),
        address: new FormGroup({
          complement: new FormControl({ value: this.building.address.complement, disabled: !this.enabledEdit }),
          lat: new FormControl({ value: this.building.address.lat, disabled: !this.enabledEdit }),
          lng: new FormControl({ value: this.building.address.lng, disabled: !this.enabledEdit }),
          zipCode: new FormControl({ value: this.building.address.zipCode, disabled: !this.enabledEdit }, [Validators.required]),
          street: new FormControl({ value: this.building.address.street, disabled: !this.enabledEdit }, [Validators.required]),
          number: new FormControl({ value: this.building.address.number, disabled: !this.enabledEdit }, [Validators.required]),
          state: new FormControl({ value: this.building.address.state, disabled: !this.enabledEdit }, [Validators.required]),
          city: new FormControl({ value: this.building.address.city, disabled: !this.enabledEdit }, [Validators.required]),
          country: new FormControl({ value: this.building.address.country, disabled: !this.enabledEdit }, [Validators.required]),
        }),
        photo: new FormControl(),
        description: new FormControl({ value: this.building.description, disabled: !this.enabledEdit }),
        contact: new FormGroup({
          emails: new FormArray(this.building.contacts.emails.map(x => { return new FormControl(x, [Validators.required, Validators.email]) })),
          telephones: new FormArray(this.building.contacts.telephones.map(x => {
            return new FormGroup({
              number: new FormControl(x.number, [Validators.required]),
              internalExtension: new FormControl(x.internalExtension),
              type: new FormControl(x.type, Validators.required)
            })
          })),
        }),
      });

    }
  }

  private _filterCountries(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.countries.filter(x => x.toLowerCase().indexOf(filterValue) >= 0);
  }
  public countries: any[] = [];

  public types: { key: string, value: string }[] = [];
  ngOnInit() {

    this.countries = moment.tz.names();

    this.countriesFiltered = this.buildingForm.get("timeZone").valueChanges.pipe(
      startWith(''),
      map(value => this._filterCountries(value))
    );

    for (let value in PhoneType) {
      this.types.push({ key: PhoneType[value] , value: PhoneType[value] });
    }
  }

  public imagePath: any;
  public imgURL: any = null;

  onDrop(event) {
    this.onChangeFile(event.dataTransfer.files);
    event.preventDefault();
  }
  onDragOver(event) {
    event.stopPropagation();
    event.preventDefault();
  }

  public removePhoto() {
    this.buildingForm.get("photo").setValue("");
    this.imagePath = null;
    this.imgURL = null;
  }

  public onChangeFile(files) {
    console.log(files);
    if (files.length === 0)
      return;

    var mimeType = files[0].type;
    if (mimeType.match(/image\/*/) == null) {
      // this.message = "Only images are supported.";
      return;
    }

    var reader = new FileReader();
    this.imagePath = files;
    reader.readAsDataURL(files[0]);
    reader.onload = (_event) => {
      this.imgURL = reader.result;
    }

    this.buildingForm.get("photo").setValue(files[0]);

    console.log(this.buildingForm.value);
  }

  public addContactEmail() {
    (<FormArray>(<FormGroup>this.buildingForm.controls.contact).controls.emails).push(
      new FormControl('', [Validators.required, Validators.email])
    );
  }

  public addContactPhone() {
    (<FormArray>(<FormGroup>this.buildingForm.controls.contact).controls.telephones).push(
      new FormGroup({
        number: new FormControl('', [Validators.required]),
        internalExtension: new FormControl(''),
        type: new FormControl('', Validators.required)
      })
    );
  }

  public removeContactEmail(pos) {
    (<FormArray>(<FormGroup>this.buildingForm.controls.contact).controls.emails).removeAt(pos);
  }

  public removeContactPhone(pos) {
    (<FormArray>(<FormGroup>this.buildingForm.controls.contact).controls.telephones).removeAt(pos);
  }

  public goBack() {
    this._location.back();
  }

  public onSubmit() {
    if (this.buildingForm.valid)
      this.onsubmit.emit(this.buildingForm)
  }

  public reset() {
    this.buildingForm.reset({

    });
  }

  get contacts(): FormGroup {
    return <FormGroup>this.buildingForm.get("contact")
  }
}
