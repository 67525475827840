import { Routes } from "@angular/router";

import { LoginComponent } from "./views/auth/login/login.component";

import { BlankLayoutComponent } from "./components/common/layouts/blankLayout.component";
import { BasicLayoutComponent } from "./components/common/layouts/basicLayout.component";

import { AuthGuard } from "app/guards/auth.guard";

import { RecoveryComponent } from "app/views/auth/recovery/recovery.component";
import { Error404Component } from "app/views/error404/error404.component";
import { RootDashboardComponent } from "app/views/root/root-dashboard/root-dashboard.component";
import { UnauthorizedComponent } from "app/views/unauthorized/unauthorized.component";
import { RootCompaniesComponent } from "app/views/root/root-companies/root-companies.component";
import { RootCreateCompanyComponent } from "app/views/root/root-companies/root-create-company/root-create-company.component";
import { RootEditCompanyComponent } from "app/views/root/root-companies/root-edit-company/root-edit-company.component";
import { RootUsersComponent } from "app/views/root/root-users/root-users.component";
import { RootCreateUserComponent } from "app/views/root/root-users/root-create-user/root-create-user.component";
import { RootEditUserComponent } from "app/views/root/root-users/root-edit-user/root-edit-user.component";
import { AdminCreateUserComponent } from "app/views/admin/admin-users/admin-create-user/admin-create-user.component";
import { AdminUsersComponent } from "app/views/admin/admin-users/admin-users.component";
import { AdminEditUserComponent } from "app/views/admin/admin-users/admin-edit-user/admin-edit-user.component";
import { AdminBuildingsComponent } from "app/views/admin/admin-buildings/admin-buildings.component";
import { AdminCreateBuildingsComponent } from "app/views/admin/admin-buildings/admin-create-buildings/admin-create-buildings.component";
import { AdminEditBuildingsComponent } from "app/views/admin/admin-buildings/admin-edit-buildings/admin-edit-buildings.component";
import { ProfileComponent } from "app/views/profile/profile.component";
import { ContactsComponent } from "app/views/contacts/contacts.component";
import { ChangePasswordComponent } from "app/views/change-password/change-password.component";
import { RootBuildingsComponent } from "./views/root/buildings/root-buildings.component";
import { AuthorisePointComponent } from "./views/root/buildings/authorisePoint/authorise-points.component";
import { AdminMetadataListComponent } from "./views/admin/PointMetaData/list/metadata.list.component";
import { AdminMetadataEditComponent } from "./views/admin/PointMetaData/edit/metadata.edit.component";
import { AdminDashboardComponent } from "./views/admin/admin-dashboard/admin-dashboard.component";
import { AdminPointDataComponent } from "./views/admin/admin-sensor-data/admin-sensor-data.component";
import { AdminMeterListComponent } from "./views/admin/PointMetaData/Meters/meter.list.component";
import { AdminMeterDataComponent } from "./views/admin/admin-meter-data/admin-meter-data.component";
import { AdminAlarmListComponent } from "./views/admin/alarm/alarm.list.component";
import { AdminAlarmDataComponent } from "./views/admin/admin-alarm-data/admin-alarm-data.component";
import { AdminApiKeyComponent } from "./views/admin/admin-apiKey/admin-apiKey.component";
import { AdminEditApiKeyComponent } from "./views/admin/admin-apiKey/admin-edit-apiKey/admin-edit-buildings.component";
import { AdminCreateApiKeyComponent } from "./views/admin/admin-apiKey/admin-create-apiKey/admin-create-buildings.component";
import { AdminMetadataVisibilityComponent } from "./views/admin/PointMetaData/visibility/metadata.visibility.component";
import { AdminMetadataCreateComponent } from "./views/admin/PointMetaData/create/metadata.create.component";
import { MyQRCodeComponent } from "./components/common/QRCode/qr-code.component";
import { AdminMeterReadingComponent } from "./views/admin/meterReading/meter-reading.component";
import { AdminTagMapsComponent } from "./views/admin/admin-tag-maps/admin-tag-maps.component";
import { AdminCreateTagMapsComponent } from "./views/admin/admin-tag-maps/admin-create-tag-maps/admin-create-tag-maps.component";
import { AdminDriverComponent } from './views/admin/admin-driver/admin-driver.component';
import { AdminServerPointsComponent } from './views/admin/admin-server-points/admin-server-points.component';
import { AdminDevicesComponent } from './views/admin/admin-devices/admin-devices.component';
import { AdminHistoryComponent } from './views/admin/admin-history/admin-history.component';
import { AdminAdvancedViewComponent } from './views/admin/admin-advanced-view/admin-advanced-view.component';
import { AdminPredictionComponent } from './views/admin/admin-prediction/admin-prediction.component';
import { AdminAlarmsNotificationComponent } from './views/admin/admin-alarms-notification/admin-alarms-notification.component';
import { AdminHealthComponent } from './views/admin/admin-health/admin-health.component';
import { AdminTagFlagMapsComponent } from './views/admin/admin-tag-flag-maps/admin-tag-flag-maps.component';
import { AdminIntegrityIssuesComponent } from './views/admin/admin-integrity-issues/admin-integrity-issues.component';
import { AdminDataPredictionComponent } from './views/admin/admin-data-prediction/admin-data-prediction.component';
import { AdminGroupsListComponent } from './views/admin/admin-groups-list/admin-groups-list.component';
import { AdminAssetsListComponent } from './views/admin/admin-assets-list/admin-assets-list.component';
import { AdminAssetViewComponent } from './views/admin/admin-asset-view/admin-asset-view.component';
import { AdminViolinComponent } from './views/admin/admin-violin/admin-violin.component';
import { AdminAssetsCreateComponent } from './views/admin/admin-assets-create/admin-assets-create.component';
import { AdminGroupsCreateComponent } from './views/admin/admin-groups-create/admin-groups-create.component';
import { AdminGroupsEditComponent } from './views/admin/admin-groups-edit/admin-groups-edit.component';
import { AdminAssetsEditComponent } from './views/admin/admin-assets-edit/admin-assets-edit.component';
import { AdminAutomaticGroupsComponent } from './views/admin/admin-automatic-groups/admin-automatic-groups.component';
import { AdminViolinDetailedComponent } from './views/admin/admin-violin-detailed/admin-violin-detailed.component';
import { AdminMetersReadingComponent } from "./views/admin/admin-meters-reading/admin-meters-reading.component";
import { QrPrintComponent } from "./components/common/qr-print/qr-print.component";
import { ExtrafilesLoadedGuard } from "./guards/extrafiles-loaded.guard";
import { FaultsCreateComponent } from "./views/admin/faults/faults-create/faults-create.component";
import { FaultsListComponent } from "./views/admin/faults/faults-list/faults-list.component";
import { FaultsViewComponent } from "./views/admin/faults/faults-view/faults-view.component";
import { FaultsReportComponent } from "./views/admin/faults/faults-report/faults-report.component";
import { AdminUnauthorizedComponent } from "./views/admin/unauthorized/unauthorized.component";
import { IntegrityIssuesListComponent } from "./views/admin/integrity-issues-list/integrity-issues-list.component";
import { PointValueSearchComponent } from "./views/admin/point-value-search/point-value-search.component";
import { AlarmsComponent } from "./views/admin/alarms/alarms.component";


export const ROUTES: Routes = [

  // redirect to login
  { path: "", redirectTo: "auth/login", pathMatch: "full" },

  // 'Employee' url are for admins
  { path: "employee", redirectTo: "employee/dashboard", pathMatch: "full" },
  { path: "employee/buildings", redirectTo: "employee/dashboard", pathMatch: "full" },
  {
    path: "employee", component: BasicLayoutComponent,
    canActivateChild: [AuthGuard],
    children: [
      {
        path: "profile", component: ProfileComponent, data: { breadcrumb: "profile" }
      },
      { path: "dashboard", component: AdminDashboardComponent },
      {
        path: "meterdata",
        component: AdminMeterDataComponent,
        data: { breadcrumb: "meterdata" }
      },
      {
        path: "pointdata",
        component: AdminPointDataComponent,
        data: { breadcrumb: "pointdata" }
      },
      {
        path: "alarmdata",
        component: AdminAlarmDataComponent,
        data: { breadcrumb: "alarmdata" }
      },
      {
        path: "buildings", data: { breadcrumb: "building.list" },
        children: [
          {
            path: ":id/alarms",
            component: AdminAlarmListComponent,
            data: { breadcrumb: "building.alarm" }
          },
          // {
          //   path: ":id/meters",
          //   component: AdminMeterListComponent,
          //   data: { breadcrumb: "general.sections.admin.building.meter" }
          // },
          // {
          //   path: ":id/metadatas",
          //   component: AdminMetadataListComponent,
          //   data: { breadcrumb: "Points List" }
          // },
          // {
          //   path: ":id/meterReadings",
          //   component: AdminMeterReadingComponent,
          //   data: { breadcrumb: "general.sections.admin.building.meterReadings" }
          // },
          {
            path: ":id/pointsVisibility",
            component: AdminMetadataVisibilityComponent,
            data: { breadcrumb: "building.pointvisibility" }
          }
        ]
      }
    ]
  },

  { path: "print", component: QrPrintComponent },
  // 'admin' url are for admins
  { path: "admin", redirectTo: "admin/dashboard", pathMatch: "full" },
  {
    path: "admin", component: BasicLayoutComponent,
    canActivateChild: [AuthGuard],
    children: [
      // { path: "mock", component: AlarmsComponent },
      { path: "unauthorized", component: UnauthorizedComponent },
      { path: "prediction", component: AdminPredictionComponent },
      { path: "remote", redirectTo: "remote/points-management", pathMatch: "full" },
      {
        path: "remote", data: { breadcrumb: "remotemanagement.remotemanagement" }, children: [
          { path: "history", data: { breadcrumb: "remotemanagement.logs" }, component: AdminHistoryComponent },
          { path: "server-points", data: { breadcrumb: "remotemanagement.server" }, component: AdminServerPointsComponent },
          { path: "points-management", data: { breadcrumb: "remotemanagement.points" }, component: AdminDriverComponent },
          { path: "devices", data: { breadcrumb: "remotemanagement.devices" }, component: AdminDevicesComponent },
        ]
      },
      {
        path: "grouping", redirectTo: "grouping/groups", pathMatch: "full"
      },
      {
        path: "grouping", data: { breadcrumb: "groupings.grouping" },
        children: [
          { path: "automatic", data: { breadcrumb: "groupings.auto" }, component: AdminAutomaticGroupsComponent },
          { path: "groups", data: { breadcrumb: "groupings.groups" }, component: AdminGroupsListComponent },
          {
            path: "groups",
            data: { breadcrumb: "groupings.groups" },
            children: [
              { path: "create", component: AdminGroupsCreateComponent, data: { breadcrumb: "groupings.creategroup" } },
              { path: "edit/:id", component: AdminGroupsEditComponent, data: { breadcrumb: "groupings.editgroup" } }
            ]
          },
          { path: "assets", data: { breadcrumb: "groupings.assets" }, component: AdminAssetsListComponent },
          {
            path: "assets",
            data: { breadcrumb: "groupings.assets" },
            children: [
              { path: "create", component: AdminAssetsCreateComponent, data: { breadcrumb: "groupings.createasset" } },
              { path: "edit/:id", component: AdminAssetsEditComponent, data: { breadcrumb: "groupings.editgroup" } }
            ]
          },
          { path: "view/:id", data: { breadcrumb: "groupings.view" }, component: AdminAssetViewComponent },
          { path: "violin/:id", data: { breadcrumb: "groupings.analytics" }, component: AdminViolinComponent },
          { path: "detailedviolin/:id/:asset", data: { breadcrumb: "groupings.detailed" }, component: AdminViolinDetailedComponent }
        ]
      },
      { path: "contacts", component: ContactsComponent, data: { breadcrumb: "general.sections.contacts" } },
      {
        path: "profile", component: ProfileComponent, data: { breadcrumb: "profile" }
      },
      { path: "dashboard", component: AdminDashboardComponent },
      { path: "users", component: AdminUsersComponent, data: { breadcrumb: "users.list" } },
      {
        path: "users", data: { breadcrumb: "users.list" },
        children: [
          { path: "create", component: AdminCreateUserComponent, data: { breadcrumb: "users.create" } },
          { path: ":id", component: AdminEditUserComponent, data: { breadcrumb: "users.edit" } }
        ]
      },
      {
        path: "meterdata",
        component: AdminMeterDataComponent,
        data: { breadcrumb: "meters.data" }
      },

      { path: "reports", redirectTo: "reports/health", pathMatch: "full" },
      {
        path: "reports", data: { breadcrumb: "reports.reports" },
        children: [

          {
            path: "health",
            component: AdminHealthComponent,
            data: { breadcrumb: "reports.bms" }
          }
        ]
      },

      { path: "faults", redirectTo: "faults/list", pathMatch: "full"},
      {
        path: "faults", data: { breadcrumb: "faults.list" },
        children: [
          {
            path: "create",
            component: FaultsCreateComponent,
            data: { breadcrumb: "faults.create" }
          },
          {
            path: "list",
            component: FaultsListComponent
          },
          {
            path: "view/:building/:id",
            component: FaultsViewComponent,
            data: { breadcrumb: "faults.view" }
          },
          {
            path: "report",
            component: FaultsReportComponent,
            data: { breadcrumb: "faults.report" }
          },
        ]
      },

      { path: "meters", redirectTo: "meters/list", pathMatch: "full" },
      {
        path: "meters", data: { breacrumb: "meters.list" },
        children: [
          {
            path: "readings",
            component: AdminMeterReadingComponent,
            data: { breadcrumb: "meters.check" }
          },
          {
            path: "list",
            component: AdminMeterListComponent,
            data: { breadcrumb: "meters.list" }
          },

          {
            path: "metering",
            component: AdminMetersReadingComponent,
            data: { breadcrumb: "meters.metering" }
          },
        ]
      },

      { path: "dataview", redirectTo: "dataview/simple", pathMatch: "full" },
      {
        path: "dataview",
        children: [
          {
            path: "pointvalue",
            component: PointValueSearchComponent,
            data: { breadcrumb: "data.pointvalue" }
          },
          {
            path: "advanced",
            component: AdminAdvancedViewComponent,
            data: { breadcrumb: "data.advanced" }
          },
          {
            path: "allpoints",
            component: AdminMetadataListComponent,
            data: { breadcrumb: "data.list" }
          },
          {
            path: "allpoints",
            data: { breadcrumb: "data.list" },
            children: [
              {
                path: "create",
                component: AdminMetadataCreateComponent,
                data: { breadcrumb: "data.createvirtualpoint" }
              },
              {
                path: ":id",
                component: AdminMetadataEditComponent,
                data: { breadcrumb: "data.editpoint" }
              }
            ]
          },
          {
            path: "simple",
            component: AdminPointDataComponent,
            data: { breadcrumb: "data.simple" }
          },
          {
            path: "prediction",
            component: AdminDataPredictionComponent,
            data: { breadcrumb: "Data Prediction" }
          }
        ]
      },

      { path: "alarmdata", redirectTo: "alarmdata/list", pathMatch: "full" },
      {
        path: "alarmdata",
        children: [
          {
            path: "list",
            component: AlarmsComponent,
            data: { breadcrumb: "alarms.list" }
          },
          {
            path: "notifications",
            component: AdminAlarmsNotificationComponent,
            data: { breadcrumb: "alarms.notifications" }
          },
          {
            path: "report",
            component: AdminAlarmDataComponent,
            data: { breadcrumb: "alarms.report" }
          }
        ]
      },

      { path: "security", redirectTo: "security/api", pathMatch: "full" },
      {
        path: "security",
        data: { breadcrumb: "security.security" },
        children: [
          {
            path: "api",
            component: AdminMetadataVisibilityComponent,
            data: { breadcrumb: "security.api" }
          },
          {
            path: "accesskey",
            component: AdminApiKeyComponent,
            data: { breadcrumb: "security.keys.list" }
          },
          {
            path: "accesskey",
            data: { breadcrumb: "security.keys.list" },
            children: [
              {
                path: "create",
                component: AdminCreateApiKeyComponent,
                data: { breadcrumb: "security.keys.create" }
              },
              {
                path: ":id",
                component: AdminEditApiKeyComponent,
                data: { breadcrumb: "security.keys.edit" }
              }
            ]
          }
        ]
      },

      // {
      //   path: "alarmdata",
      //   component: AdminAlarmDataComponent,
      //   data: { breadcrumb: "general.sections.admin.building.alarmData" }
      // },

      { path: "tagMaps", component: AdminTagMapsComponent, data: { breadcrumb: "tagmaps.tags" } },
      {
        path: "tagMaps", data: { breadcrumb: "tagmaps.tags" },
        children: [
          { path: "create", component: AdminTagFlagMapsComponent, data: { breadcrumb: "tagmaps.create" } }
        ]
      },
      {
        path: "integrity",
        // component: AdminIntegrityIssuesComponent,
        component: IntegrityIssuesListComponent,
        data: { breadcrumb: "integrity.issues" }
      },
      { path: "buildings", component: AdminBuildingsComponent, data: { breadcrumb: "buildings.list" } },
      {
        path: "buildings", data: { breadcrumb: "buildings.list" },
        children: [

          { path: "create", component: AdminCreateBuildingsComponent, data: { breadcrumb: "buildings.create" } },
          { path: ":id", component: AdminEditBuildingsComponent, data: { breadcrumb: "buildings.edit" } },
        ]
      }
    ]
  },

  // 'root' url is for the root user
  { path: "root", redirectTo: "root/companies", pathMatch: "full" },
  {
    path: "root", component: BasicLayoutComponent,
    canActivateChild: [AuthGuard],
    children: [
      {
        path: "profile",
        component: ProfileComponent,
        data: { breadcrumb: "profile" }
      },
      {
        path: "dashboard",
        component: RootDashboardComponent
      },
      {
        path: "companies",
        component: RootCompaniesComponent,
        data: { breadcrumb: "companies.list" }
      },
      {
        path: "companies",
        data: { breadcrumb: "companies.list" },
        children: [
          { path: "create", component: RootCreateCompanyComponent, data: { breadcrumb: "companies.create" } },
          { path: ":id", component: RootEditCompanyComponent, data: { breadcrumb: "companies.edit" } },
          { path: ":id/buildings", component: RootBuildingsComponent, data: { breadcrumb: "companies.buildings.list" } },
          {
            path: ":id/buildings", data: { breadcrumb: "companies.buildings.list" },
            children: [
              {
                path: ":building/newpoints",
                component: AuthorisePointComponent,
                data: { breadcrumb: "companies.buildings.auth" }
              }
            ]
          }
        ]
      },
      {
        path: "users",
        component: RootUsersComponent,
        data: { breadcrumb: "general.sections.root.user.users" }
      },
      {
        path: "users",
        data: { breadcrumb: "general.sections.root.user.users" },
        children: [
          { path: "create", component: RootCreateUserComponent, data: { breadcrumb: "general.sections.root.user.create" } },
          { path: ":id", component: RootEditUserComponent, data: { breadcrumb: "general.sections.root.user.edit" } }
        ]
      }
    ]
  },

  {
    path: "auth", component: BlankLayoutComponent,
    // canActivateChild: [ExtrafilesLoadedGuard],
    children: [
      { path: "login", component: LoginComponent },
      { path: "recovery", component: RecoveryComponent },
      { path: "changePassword", component: ChangePasswordComponent }
    ]
  },
  {
    path: "admin",
    component: BlankLayoutComponent,
    children: [
      {
        path: "qrCode",
        component: MyQRCodeComponent,
        data: { breadcrumb: "general.sections.admin.qrCode" }
      }
    ]
  },

  {
    path: "auth", component: BlankLayoutComponent,
  },

  // Handle all other routes
  {
    path: "error", component: BlankLayoutComponent,
    children: [
      { path: "404", component: Error404Component },
      { path: "unauthorized", component: UnauthorizedComponent }
    ]
  },
  { path: "**", redirectTo: "error/404" }
];
