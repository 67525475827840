import { Injectable } from '@angular/core';
import { Observable, Observer } from 'rxjs';
import { environment } from 'environments/environment';
import { HttpClient } from '@angular/common/http';
import { AppService } from './app.service';
import { LoginService } from './login.service';
import { SpinnerService } from './spinner.service';
import { CompanyViewService } from './company.view.service';
import { UserType } from 'app/interfaces/baseinterface';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslocoService } from '@ngneat/transloco';

@Injectable({
  providedIn: 'root'
})
export class BasicRequestService {

  constructor(public transloco: TranslocoService, public _snackBar: MatSnackBar, public companyView: CompanyViewService, public http: HttpClient, public appService: AppService, public loginService: LoginService, public spinner: SpinnerService) { }


  private updateHeader(useAvailableCompany: boolean = true): Observable<any> {
    this.spinner.activate();
    return new Observable((observer: Observer<any>) => {
      this.loginService.isAuthenticated().subscribe(
        x => {
          this.spinner.desactivate();

          let company = this.companyView.getCurrentCompany();
          if (useAvailableCompany && company) {
            observer.next({ Authorization: company.token });
            observer.complete();
          }
          else {
            let session: any = JSON.parse(localStorage.getItem(this.appService.productKey + ".loggedUser"));
            observer.next({ Authorization: session.token });
            observer.complete();
          }
        },
        (error) => {
          this.spinner.desactivate();

          observer.next({ Authorization: "" });
          observer.complete();
        }
      );
    });
  };

  // Use this method to do something to ALL post request, such as passing tokens/timestamps/headers
  protected doPost(url: string, body: any): Observable<any> {
    return new Observable((observer: Observer<any>) => {
      this.updateHeader().subscribe(
        (header: any) => {
          this.http.post(environment.apiUrl + url, body, {
            headers: header
          }).subscribe(
            t => {
              observer.next(t);
              observer.complete();
            },
            err => {
              if(this.loginService.userInfo.type == UserType.Root && err.status == 403 || err.status == 401){
                this._snackBar.open(this.transloco.translate("messages.nocompanyselected"), 'Dismiss', {
                  duration: 50000,
                  horizontalPosition: "right",
                  verticalPosition: "bottom",
                });
              }
              observer.error(err)
            }
          );
        });
    });
  }

  // Use this method to do something to ALL patch request, such as passing tokens/timestamps/headers
  protected doPatch(url: string, body: any): Observable<Response> {
    return new Observable((observer: Observer<any>) => {
      this.updateHeader().subscribe(
        (header: any) => {
          this.http.patch(environment.apiUrl + url, body, {
            headers: header
          }).subscribe(
            t => {
              observer.next(t);
              observer.complete();
            },
            err => {
              if(this.loginService.userInfo.type == UserType.Root && err.status == 403 || err.status == 401){
                this._snackBar.open(this.transloco.translate("messages.nocompanyselected"), 'Dismiss', {
                  duration: 50000,
                  horizontalPosition: "right",
                  verticalPosition: "bottom",
                });
              }
              observer.error(err)
            }
          );
        });
    });
  }

  // Use this method to do something to ALL get request, such as passing tokens/timestamps/headers
  protected doGet(url: string, id?: string, restOfUlr?: string): Observable<any> {
    let completeUrl = "";

    if (id && restOfUlr) {
      completeUrl = environment.apiUrl + url + "/" + id + "?" + restOfUlr;
    } else if (id) {
      completeUrl = environment.apiUrl + url + "/" + id;
    } else {
      completeUrl = environment.apiUrl + url;
    }

    return new Observable<any>((observer: Observer<any>) => {
      this.updateHeader().subscribe(
        (header: any) => {
          this.http.get(completeUrl, {
            headers: header
          }).subscribe(
            t => {
              observer.next(t);
              observer.complete();
            },
            err => {
              if(this.loginService.userInfo.type == UserType.Root && err.status == 403 || err.status == 401){
                this._snackBar.open(this.transloco.translate("messages.nocompanyselected"), 'Dismiss', {
                  duration: 50000,
                  horizontalPosition: "right",
                  verticalPosition: "bottom",
                });
              }
              observer.error(err)
            }
          );
        });
    });
  }

  // Use this method to do something to ALL delete request, such as passing tokens/timestamps/headers
  protected doDelete(url: string, id?: string): Observable<any> {
    return new Observable<any>((observer: Observer<any>) => {
      this.updateHeader().subscribe(
        (header: any) => {
          this.http.delete(environment.apiUrl + url + (id ? ("/" + id) : ""), {
            headers: header
          }).subscribe(
            t => {
              observer.next(t);
              observer.complete();
            },
            err => {
              if(this.loginService.userInfo.type == UserType.Root && err.status == 403 || err.status == 401){
                this._snackBar.open(this.transloco.translate("messages.nocompanyselected"), 'Dismiss', {
                  duration: 50000,
                  horizontalPosition: "right",
                  verticalPosition: "bottom",
                });
              }
              observer.error(err)
            }
          );
        });
    });
  }
}
