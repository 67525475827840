import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DialogData } from 'app/components/common/map-popup/map-popup.component';
import { FaultsResolvedComponent } from '../faults-resolved/faults-resolved.component';

@Component({
  selector: 'app-faults-action',
  templateUrl: './faults-action.component.html',
  styleUrls: ['./faults-action.component.css']
})
export class FaultsActionComponent implements OnInit {

  public form: FormGroup = new FormGroup({
    description: new FormControl("", [Validators.required])
  })

  constructor(@Inject(MAT_DIALOG_DATA) public data: DialogData, public dialogRef: MatDialogRef<FaultsActionComponent>) { }


  ngOnInit(): void {
  }


  public save(){
    this.dialogRef.close(this.form.value);
  }

}
