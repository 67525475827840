import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PredictionChartComponent } from './prediction-chart.component';
import { AppHighstockModule } from '../highcharts/highstock/highstock.module';
import { IboxModule } from '../ibox/ibox.module';
import { MatCardModule } from '@angular/material/card';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatListModule } from '@angular/material/list';
import { MatSelectModule } from '@angular/material/select';
import { MatTreeModule } from '@angular/material/tree';
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [PredictionChartComponent],
  imports: [
    CommonModule,
    AppHighstockModule,
    IboxModule,
    MatSelectModule,
    FormsModule,
    MatGridListModule,
    MatCardModule,
    MatListModule,
    ],
  exports: [
    PredictionChartComponent
  ]
})
export class PredictionChartModule { }
