import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SearchPopupComponent } from './search-popup.component';
import { PaginatorModule } from '../paginator/paginator.module';
import { MatTableModule } from '@angular/material/table';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { FormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';



@NgModule({
  declarations: [SearchPopupComponent],
  imports: [
    CommonModule,
    PaginatorModule,
    MatTableModule,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    MatIconModule
  ]
})
export class SearchPopupModule { }
