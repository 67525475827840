import { Component } from '@angular/core';
import { smoothlyMenu } from '../../../app.helpers';
import { AppService } from 'app/services/app.service';
import { Router } from '@angular/router';
import { LoginService } from "../../../services/login.service";
declare var jQuery: any;

@Component({
  selector: 'app-topnavigationnavbar',
  templateUrl: 'topnavigationnavbar.template.html'
})
export class TopNavigationNavbarComponent {

  constructor(public appService: AppService, public loginService: LoginService, private router: Router){

  }

  activeRoute( routeName: string ): boolean {
    return this.router.url.indexOf( routeName ) > -1;
  }

  toggleNavigation(): void {
    jQuery('body').toggleClass('mini-navbar');
    smoothlyMenu();
  }

}
