import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TooltipComponent } from './tooltip.component';
import { MatTooltipModule } from '@angular/material/tooltip';

@NgModule({
  imports: [
    CommonModule,
    MatTooltipModule
  ],
  declarations: [TooltipComponent],
  exports: [
    TooltipComponent
  ]
})
export class TooltipModule { }
