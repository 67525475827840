import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatDatepicker } from '@angular/material/datepicker';
import { TranslocoService } from '@ngneat/transloco';
import { AppHighstockComponent } from 'app/components/common/highcharts/highstock/highstock.component';
import { BuildingService } from 'app/services/building.service';
import { SpinnerService } from 'app/services/spinner.service';
import * as moment from 'moment';
import { Moment } from 'moment';

import { jsPDF } from 'jspdf';
import * as canvg from "canvg";

import * as humanizeDuration from "humanize-duration"
import { SessionService } from 'app/services/session.service';

@Component({
  selector: 'app-faults-report',
  templateUrl: './faults-report.component.html',
  styleUrls: ['./faults-report.component.css']
})
export class FaultsReportComponent implements OnInit, AfterViewInit {

  public buildings;

  public canDownload = false;

  public date = moment();

  public max = moment().endOf("month");

  public building;

  public year: number = this.date.year();
  public month: number = this.date.month();

  constructor(private sessionService: SessionService, private buildingService: BuildingService, private spinner: SpinnerService, private transloco: TranslocoService) { }

  ngAfterViewInit() {
    this.updateCategories();
  }

  ngOnInit(): void {

    this.data = moment();
    this.spinner.activate();
    this.buildingService.getAll().subscribe(x => {
      this.buildings = x.data;
      this.spinner.desactivate();

      this.building = this.sessionService.building;

    })
  }

  public currentMonth = "";

  @ViewChild("firstChart") firstChart: AppHighstockComponent;
  public firstchartoptions = {
    chart: {
      height: 500
    },
    xAxis: {
      categories: []
    },
    plotOptions: {
      column: {
        stacking: 'normal'
      }
    },
    credits: {
      enabled: false
    },
    rangeSelector: {
      enabled: false
    },
    scrollbar: {
      enabled: false
    },
    yAxis: [
      {
        title: {
          text: ""
        },

        opposite: true
      },
      {
        title: {
          text: ""
        },
        opposite: false
      }
    ],
    navigator: {
      enabled: false
    },
    tooltip: {
      valueDecimals: 0,
      shared: true,
    },
    legend: {
      enabled: true
    },
    series: [{
      name: 'New Faults',
      data: [],
      id: "newfaults",
      stack: 'newfaults',
      type: 'column',
      yAxis: 0
    }, {
      name: 'Not a fault',
      data: [],
      id: "notfault",
      stack: 'openresolved',
      type: 'column',
      yAxis: 0
    }, {
      name: 'Resolved',
      data: [],
      stack: 'openresolved',
      id: "resolved",
      type: 'column',
      yAxis: 0
    },
    {
      name: "Average resolution duration (days)",
      data: [],
      id: "averageduration",
      type: "spline",
      yAxis: 1,
    },
    {
      name: "Open faults in the last 12 months",
      data: [],
      id: "totalfaults",
      type: "spline",
      yAxis: 1
    }
    ]
  }

  @ViewChild("secondChart") secondChart: AppHighstockComponent;
  public secondchartOptions = {
    chart: {
      height: 500
    },
    xAxis: {
      categories: []
    },
    plotOptions: {
      spline: {
        marker: {
          enabled: true,
          lineColor: "#D1DADE",
          radius: 4,
          lineWidth: 1
        }
      }
    },
    credits: {
      enabled: false
    },
    rangeSelector: {
      enabled: false
    },
    scrollbar: {
      enabled: false
    },
    tooltip: {
      valueDecimals: 0,
      shared: true,
    },
    yAxis: [
      {
        title: {
          text: ""
        },
        opposite: true
      },
      {
        title: {
          text: ""
        },
        opposite: false
      }
    ],
    navigator: {
      enabled: false
    },
    legend: {
      enabled: true
    },
    series: [{
      name: 'High severity',
      data: [],
      type: 'column',
      id: "high",
      yAxis: 0,
      color: "#ED5565"
    }, {
      name: 'Medium severity',
      data: [],
      type: 'column',
      id: "medium",
      yAxis: 0,
      color: "#f8ac59"
    }, {
      name: 'Low severity',
      data: [],
      type: 'column',
      id: "low",
      yAxis: 0,

      color: "#1c84c6"
    },
    {
      name: "Average duration high severity (days)",
      data: [],
      type: "spline",
      yAxis: 1,
      id: "durationhigh",
      color: "#ED5565"
    },
    {
      name: "Average duration medium severity (days)",
      data: [],
      id: "durationmedium",
      type: "spline",
      yAxis: 1,
      color: "#f8ac59"
    },
    {
      name: "Average duration low severity (days)",
      data: [],
      type: "spline",
      yAxis: 1,
      id: "durationlow",
      color: "#1c84c6"
    }
    ]
  }

  public months = [
    "january",
    "february",
    "march",
    "april",
    "may",
    "june",
    "july",
    "august",
    "september",
    "october",
    "november",
    "december"
  ]


  public canvas = document.createElement('canvas');

  public downloadPDF() {
    if (!this.canDownload) return;
    var doc = new jsPDF({ unit: "px", format: "a4" });

    let height: number = 50;
    doc.setFontSize(15);
    doc.text(this.transloco.translate("admin.faults.report.report") + " - " + this.currentMonth + " - " + this.date.format("YYYY"), 150, height);

    height += 25;
    doc.setFontSize(10);
    doc.text(this.transloco.translate("admin.faults.report.summary"), 30, height);

    height += 10;
    doc.setFontSize(7);
    doc.text(this.transloco.translate('admin.faults.report.totalopenfaults') + ": " + this.summary.totalOpen, 50, height);
    doc.text(this.transloco.translate('admin.faults.report.newopenfaults', { month: this.currentMonth }) + ": " + this.summary.newOpen, 130, height);
    doc.text(this.transloco.translate('admin.faults.report.resolvedFaults', { month: this.currentMonth }) + ": " + this.summary.resolved, 210, height);
    doc.text(this.transloco.translate('admin.faults.report.resolvedFaults', { month: this.currentMonth }) + ": " + this.summary.longest, 305, height);

    height += 25;
    doc.setFontSize(10);
    doc.text(this.transloco.translate("admin.faults.report.averageresolution", { month: this.currentMonth }), 30, height);
    doc.text(this.transloco.translate("admin.faults.report.totalnumberfaults", { month: this.currentMonth }), 240, height);

    height += 10;
    doc.setFontSize(7);
    doc.text(this.transloco.translate("admin.faults.report.averagehighseverity") + ": " + this.summary.avgHigh, 50, height);
    doc.text(this.transloco.translate("admin.faults.report.totalhighseverity") + ": " + this.summary.openHigh, 260, height);

    height += 10;
    doc.text(this.transloco.translate("admin.faults.report.averagemediumseverity") + ": " + this.summary.avgMedium, 50, height);
    doc.text(this.transloco.translate("admin.faults.report.totalmediumseverity") + ": " + this.summary.openMedium, 260, height);

    height += 10;
    doc.text(this.transloco.translate("admin.faults.report.averagelowseverity") + ": " + this.summary.avgLow, 50, height);
    doc.text(this.transloco.translate("admin.faults.report.totallowseverity") + ": " + this.summary.openLow, 260, height);


    height += 25;
    doc.setFontSize(10);
    doc.text(this.transloco.translate('admin.faults.report.faultsresolutions'), 30, height);

    height += 20;
    let img = this.firstChart.Chart.getSVG();
    canvg(this.canvas, img);
    doc.addImage(this.canvas.toDataURL("image/png"), 'PNG', 20, height, 400, 280);

    doc.addImage("assets/images/Future_Decisions.png", "PNG", 380, 14, 30, 10);
    doc.text("https://futuredecisions.net || info@futuredecisions.net || +44 (0)2081444917", 30, 620);


    doc.addPage();
    height = 50;

    doc.setFontSize(10);
    doc.text(this.transloco.translate('admin.faults.report.faultsresolutions'), 30, height);

    img = this.secondChart.Chart.getSVG();
    height += 20;
    canvg(this.canvas, img);
    doc.addImage(this.canvas.toDataURL("image/png"), 'PNG', 20, height, 400, 260);

    doc.addImage("assets/images/Future_Decisions.png", "PNG", 380, 14, 30, 10);
    doc.text("https://futuredecisions.net || info@futuredecisions.net || +44 (0)2081444917", 30, 620);
    doc.save(this.buildings.find(x => x._id == this.building).name + "_" + this.date.format("MMYYYY"));
  }

  private data;

  private severity = [1, 5, 10];

  public generateReport() {
    this.updateCategories();
    this.spinner.activate();
    this.buildingService.getFaultReport(this.building, (this.date.month() + 1).toString(), this.date.year().toString()).subscribe(x => {
      this.canDownload = true;
      this.spinner.desactivate();

      console.log(x);

      this.data = x.data;



      let d = this.date.clone();
      d.subtract(1, "year");

      let newFaults = [];
      let notFault = [];
      let resolved = [];
      let averageduration = [];
      let total = [];


      let high = [];
      let medium = [];
      let low = [];
      let avgHigh = [];
      let avgMedium = [];
      let avgLow = [];

      let year = d.year();
      let month = d.month();

      let longest

      for (let i = 0; i <= 12; i++) {
        let t = year + "-" + month;


        // if (i == 12) {
        //   this.summary.newOpen = Math.round(Math.random() * 50);
        //   this.summary.resolved = Math.round(Math.random() * 50);
        //   this.summary.longest = humanizeDuration(Math.round(Math.random() * 10) * 60000)

        //   this.summary.avgHigh = humanizeDuration(Math.round(Math.random() * 1000) * 60000, { units: ["d", "h", "m"] })
        //   this.summary.avgMedium = humanizeDuration(Math.round(Math.random() * 100) * 60000, { units: ["d", "h", "m"] })
        //   this.summary.avgLow = humanizeDuration(Math.round(Math.random() * 10) * 60000, { units: ["d", "h", "m"] })

        //   this.summary.openHigh = Math.round(Math.random() * 50)
        //   this.summary.openMedium = Math.round(Math.random() * 70)
        //   this.summary.openLow = Math.round(Math.random() * 100)
        // }



        // newFaults.push(Math.round(Math.random() * 50));
        // notFault.push(Math.round(Math.random() * 50));
        // resolved.push(Math.round(Math.random() * 50));
        // averageduration.push(Math.round(Math.random() * 10));
        // total.push(Math.round(Math.random() * 50));


        // high.push(Math.round(Math.random() * 50));
        // medium.push(Math.round(Math.random() * 70));
        // low.push(Math.round(Math.random() * 100));

        // avgHigh.push(Math.round(Math.random() * 50));
        // avgMedium.push(Math.round(Math.random() * 70));
        // avgLow.push(Math.round(Math.random() * 100));

        if (this.data.byMonth[t]) {
          let d = this.data.byMonth[t];

          this.summary.totalOpen += d.new;

          if (i == 12) {
            this.summary.newOpen = d.open;
            this.summary.resolved = d.resolved;
            this.summary.longest = humanizeDuration(d.durationSummary.max * 60000, { round: true, units: ["d", "h"] })

            this.summary.avgHigh = humanizeDuration(this.data.bySeverity[10][t].durationSummary.avg * 60000, { round: true, units: ["d", "h"] })
            this.summary.avgMedium = humanizeDuration(this.data.bySeverity[5][t].durationSummary.avg * 60000, { round: true, units: ["d", "h"] })
            this.summary.avgLow = humanizeDuration(this.data.bySeverity[1][t].durationSummary.avg * 60000, { round: true, units: ["d", "h"] })

            this.summary.openHigh = this.data.bySeverity[10][t].open
            this.summary.openMedium = this.data.bySeverity[5][t].open
            this.summary.openLow = this.data.bySeverity[1][t].open
          }

//only day fault occured and the chart

          newFaults.push(d.new);
          notFault.push(d.notFault);
          resolved.push(d.resolved);
          averageduration.push(d.durationSummary.avg ? (d.durationSummary.avg / 60) / 24 : 0);
          total.push(d.open);


          high.push(this.data.bySeverity[10][t].notFault + this.data.bySeverity[10][t].resolved);
          medium.push(this.data.bySeverity[5][t].notFault + this.data.bySeverity[5][t].resolved);
          low.push(this.data.bySeverity[1][t].notFault + this.data.bySeverity[1][t].resolved);

          avgHigh.push(this.data.bySeverity[10][t].durationSummary.avg ? (this.data.bySeverity[10][t].durationSummary.avg / 60) / 24 : 0);
          avgMedium.push(this.data.bySeverity[5][t].durationSummary.avg ? (this.data.bySeverity[5][t].durationSummary.avg / 60) / 24 : 0);
          avgLow.push(this.data.bySeverity[1][t].durationSummary.avg ? (this.data.bySeverity[1][t].durationSummary.avg / 60) / 24 : 0);
        }
        month++;
        if (month == 12) {
          month = 0;
          year++;
        }
      }

      this.firstChart.updateSeries({ data: newFaults, id: "newfaults" }, "newfaults", false);
      this.firstChart.updateSeries({ data: notFault, id: "notfault" }, "notfault", false);
      this.firstChart.updateSeries({ data: resolved, id: "resolved" }, "resolved", false);
      this.firstChart.updateSeries({ data: averageduration, id: "averageduration" }, "averageduration", false);
      this.firstChart.updateSeries({ data: total, id: "totalfaults" }, "totalfaults", false);


      this.secondChart.updateSeries({ data: high, id: "high" }, "high", false);
      this.secondChart.updateSeries({ data: medium, id: "medium" }, "medium", false);
      this.secondChart.updateSeries({ data: low, id: "low" }, "low", false);
      this.secondChart.updateSeries({ data: avgHigh, id: "durationhigh" }, "durationhigh", false);
      this.secondChart.updateSeries({ data: avgMedium, id: "durationmedium" }, "durationmedium", false);
      this.secondChart.updateSeries({ data: avgLow, id: "durationlow" }, "durationlow", false);

      this.firstChart.redraw();
      this.secondChart.redraw();
    })
  }

  public updateCategories() {
    let d = this.date.clone();

    d.subtract(1, "year");
    d.startOf("month");


    let cat = [];

    for (let i = 0; i <= 12; i++) {
      cat.push(this.transloco.translate('date.months.' + this.months[d.month()]));
      d.add(1, "month");
    }

    this.firstChart.updateXaxis({
      categories: cat
    })

    this.secondChart.updateXaxis({
      categories: cat
    })

    this.currentMonth = this.transloco.translate('date.months.' + this.months[d.month() - 1]);
  }

  chosenMonthHandler(normalizedMonth: Moment, datepicker: MatDatepicker<Moment>) {
    this.month = normalizedMonth.month();
    datepicker.close();
    this.date = normalizedMonth;
  }

  chosenYearHandler(normalizedYear: Moment) {
    this.year = normalizedYear.year();
  }

  public summary = {
    totalOpen: 0,
    newOpen: 0,
    resolved: 0,
    longest: "",
    avgHigh: 0,
    avgMedium: 0,
    avgLow: 0,
    openHigh: 0,
    openMedium: 0,
    openLow: 0,
    apps: 0
  }
}
