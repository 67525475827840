import { Component, Directive, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { MatDatepicker } from '@angular/material/datepicker';
import { FutureDecisionsDate } from 'app/components/classes/futuredecisions.date';
import { Moment } from 'moment-timezone';

@Component({
  selector: 'app-date-selector',
  templateUrl: './date-selector.component.html',
  styleUrls: ['./date-selector.component.css']
})
export class DateSelectorComponent implements OnInit {

  @Input() max: FutureDecisionsDate;
  @Input() selectionView: Timeselection = Timeselection.Month;
  @Input() timeSelectionOptions: string[] = ["Day"]

  @Input() start: FutureDecisionsDate = new FutureDecisionsDate();
  @Output() startChange: EventEmitter<FutureDecisionsDate> = new EventEmitter<FutureDecisionsDate>();

  @Input() timeSelection: string = "Day";
  @Output() timeSelectionChange: EventEmitter<string> = new EventEmitter<string>();

  constructor() { }

  ngOnInit(): void {
  }

  public valueschanged() {
    this.startChange.emit(this.start);
    this.timeSelectionChange.emit(this.timeSelection);
  }

  public keyUp(value) {
    console.log(value);
  }


  chosenMonthHandler(normalizedMonth: Moment, datepicker: MatDatepicker<FutureDecisionsDate>) {
    if (this.selectionView == Timeselection.Month || this.selectionView == Timeselection.MonthOnly) {
      this.start.dateObject = normalizedMonth.startOf("month");
      datepicker.close();
      this.valueschanged();
    }
  }

  chosenYearHandler(normalizedYear: Moment, datepicker: MatDatepicker<FutureDecisionsDate>) {
    if (this.selectionView == Timeselection.Year) {
      this.start.dateObject = normalizedYear.startOf("year");
      datepicker.close();
      this.valueschanged();
    }
  }
}

export enum Timeselection {
  Day = "Day",
  Month = "Month",
  MonthOnly = "MonthOnly",
  Year = "Year"
}



@Directive({
  selector: '[dateformatmonthyear]',
  providers: [
    {
      provide: MAT_DATE_FORMATS, useValue: {
        parse: {
          dateInput: 'MM/YYYY',
        },
        display: {
          dateInput: 'MM/YYYY',
          monthYearLabel: 'MMM YYYY',
          dateA11yLabel: 'LL',
          monthYearA11yLabel: 'MMMM YYYY',
        },
      }
    },
  ],
})
export class FormatMonthYear {
}


@Directive({
  selector: '[dateformatyear]',
  providers: [
    {
      provide: MAT_DATE_FORMATS, useValue: {
        parse: {
          dateInput: 'YYYY',
        },
        display: {
          dateInput: 'YYYY',
          monthYearLabel: 'MMM YYYY',
          dateA11yLabel: 'LL',
          monthYearA11yLabel: 'MMMM YYYY',
        },
      }
    },
  ],
})
export class FormatYear {
}

@Directive({
  selector: '[dateformatmonth]',
  providers: [
    {
      provide: MAT_DATE_FORMATS, useValue: {
        parse: {
          dateInput: 'MMM',
        },
        display: {
          dateInput: 'MMM',
          monthYearLabel: 'MMM YYYY',
          dateA11yLabel: 'LL',
          monthYearA11yLabel: 'MMMM YYYY',
        },
      }
    },
  ],
})
export class FormatMonth {
}

