import { AfterViewInit, Component, OnInit } from '@angular/core';
import { MatDatepicker } from '@angular/material/datepicker';
import { Moment } from 'moment-timezone';

import * as moment from "moment-timezone";
import { BuildingService } from 'app/services/building.service';
import { SpinnerService } from 'app/services/spinner.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-admin-meters-reading-point-popup',
  templateUrl: './admin-meters-reading-point-popup.component.html',
  styleUrls: ['./admin-meters-reading-point-popup.component.css']
})
export class AdminMetersReadingPointPopupComponent implements OnInit, AfterViewInit {

  public form = new FormGroup({
    building: new FormControl("", [Validators.required]),
    date: new FormControl(moment.utc().startOf("day").subtract(1, "month"), [Validators.required]),
    report: new FormControl("", Validators.required)
  });

  constructor(public dialogRef: MatDialogRef<AdminMetersReadingPointPopupComponent>, private spinner: SpinnerService, private buildingService: BuildingService) { }
  ngAfterViewInit(): void {
    this.spinner.activate();
    this.buildingService.getAll().subscribe(x => {
      this.buildings = x.data;

      this.spinner.desactivate();
    })
  }

  ngOnInit(): void {
  }

  public max = moment.utc().subtract(1, "month").endOf("month");

  public pagination: { page: number, pageSize: number, hasNext: boolean } = { page: 1, pageSize: 50, hasNext: false }

  chosenMonthHandler(normalizedMonth: Moment, datepicker: MatDatepicker<Moment>) {
    datepicker.close();
    this.form.get("date").setValue(normalizedMonth);

    this.resetPage();
    this.onBuildingChange();
  }

  public resetPage() {
    this.pagination.page = 1;
    this.pagination.pageSize = 50;
    this.pagination.hasNext = false;
  }

  public selectRow(row) {
    this.dialogRef.close({
      building: this.form.get("building").value,
      date: this.form.get("date").value,
      report: row,
      reportName: this.buildings.find(x => x._id == this.form.get("building").value).name + " - " + row.point.friendlyName + " - " + this.form.get("date").value.format("MM/YYYY")
    })
  }

  public buildings;

  public points;

  public pageChanged(pagination) {
    this.pagination.pageSize = pagination.pageSize;
    this.pagination.page = pagination.page;

    this.onBuildingChange();
  }

  public noResults = false;

  public onBuildingChange() {
    this.form.get("report").setValue("");

    this.spinner.activate();
    this.buildingService.meterReports(this.form.get("building").value, this.form.get("date").value.month() + 1, this.form.get("date").value.year(), this.pagination.page, this.pagination.pageSize).subscribe(x => {
      this.spinner.desactivate();

      this.points = x.data;

      this.noResults = x.data.length == 0;

      for (let i of this.points) {
        if (i.avg != undefined || i.avg != null) {
          i.avg = i.avg.toFixed(2) + i.point.unit;
        }
        else {
          i.avg = "No data";
        }

        if (i.max != undefined || i.max != null) {
          i.max = i.max.toFixed(2) + i.point.unit;
        }
        else {
          i.max = "No data";
        }

        if (i.min != undefined || i.min != null) {
          i.min = i.min.toFixed(2) + i.point.unit;
        }
        else {
          i.min = "No data";
        }

        if (i.monthConsumption != undefined || i.monthConsumption != null) {
          i.monthConsumption = i.monthConsumption.toFixed(2) + i.point.unit;
        }
        else {
          i.monthConsumption = "No data";
        }
      }

      this.pagination.hasNext = x.data.length > this.pagination.pageSize;
    })
  }
}
