import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class HelpPageService {

  private _pageUrl = "";

  get pageUrl() {
    return this._pageUrl;
  }

  constructor(private router: Router) {
    router.events.subscribe((val) => {
      this._pageUrl = "";
    });
  }

  public changeUrl(url: string){
    this._pageUrl = url;
  }
}
