import { Component, OnInit, ViewChild } from "@angular/core";
import { AppI18nService } from "app/services/app.i18n.service";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { AppService } from "app/services/app.service";
import { SpinnerService } from "app/services/spinner.service";
import { SweetalertService } from "app/services/sweetalert.service";
import { LoginService } from "app/services/login.service";
import { AdminPointService } from "../../../../services/data.service";
import { ActivatedRoute } from "@angular/router";
import { UserType } from "app/interfaces/baseinterface";

import * as moment from "moment-timezone";
import { PointTagsComponent } from 'app/components/common/point-tags/point-tags.component';
import { MatDialog } from '@angular/material/dialog';
import { BuildingService } from 'app/services/building.service';
import { QrPopupComponent } from "app/components/common/qr-popup/qr-popup.component";
import { SessionService } from "app/services/session.service";
import { QueryparamsService } from "app/services/queryparams.service";


@Component({
  selector: "app-admin-metadata-list",
  templateUrl: "./metadata.list.component.html",
  styleUrls: ["./metadata.list.component.css"]
})
export class AdminMetadataListComponent implements OnInit {

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  public dataSource = new MatTableDataSource();

  public displayedColumns: string[];
  public building: string = null;
  public building_name: string;
  public enums = UserType;

  public hasPoints = true;

  public pagination: { hasNext: boolean, page: number, pageSize: number } = { hasNext: false, page: 1, pageSize: 100 }
  public changePage(event) {

    this.pagination.page = event.page;
    this.pagination.pageSize = event.pageSize;

    this.updateData();
  }

  public filter: string = "";
  public applyFilter(value) {
    this.filter = value;
    this.pagination.page = 1;
    this.updateData();
  }

  public openFacets(id: string) {
    let p = this.data.find(x => x._id == id);
    const dialogRef = this.dialog.open(PointTagsComponent, {
      width: '600px',
      data: {
        list: p.meta,
        title: "Meta for " + p.point
      }
    });
  }
  public openTags(id: string) {
    let p = this.data.find(x => x._id == id);
    const dialogRef = this.dialog.open(PointTagsComponent, {
      width: '600px',
      data: {
        list: p.tags,
        title: "Tags for " + p.point
      }
    });
  }

  public buildings = [];

  public data = [];
  public updateData() {

    this.queryService.ReplaceParams({
      filter: this.filter,
      page: this.pagination.page,
      pageSize: this.pagination.pageSize,
      building: this.building
    }).subscribe(x => {

    });

    this.sessionService.building = this.building;
    this.spinnerService.activate();
    this.dataService.GetAllPointsWithMetadata(this.building, false, this.filter, this.pagination.page, this.pagination.pageSize, this.linked).subscribe(
      results => {
        // console.log(results);
        this.data = [];
        let c = 1;
        for (let d of results.data) {

          // d.lastReceivedData = d.lastReceivedData ? this.appService.Ago(d.lastReceivedData) : "No data received";
          d.dateInstalled = this.appService.GetDateString(d.dateInstalled);
          d.meterDataPoint = d.meterDataPoint ? this.i18n.translate("interfaces.point.meterDataPoint") : "";

          // while (d.pointName.indexOf("$20") >= 0) {
          //   d.pointName = d.pointName.replace("$20", "_");
          // }

          // while (d.pointName.indexOf("$2d") >= 0) {
          //   d.pointName = d.pointName.replace("$2d", "-")
          // }

          // while (d.pointName.indexOf("$20") >= 0) {
          //   d.pointName = d.pointName.replace("$20", " ")
          // }

          // while (d.pointName.indexOf("$2e") >= 0) {
          //   d.pointName = d.pointName.replace("$2e", ".")
          // }

          let lastValue = "";

          if (d.lastReceivedData == null) {
            lastValue = "No readings";
          }
          else if (d.dataType == 8) {
            lastValue = d.lastReceivedDataValue;
          } else {
            if (d.dataTypeDesc && d.dataTypeDesc[d.lastReceivedDataValue]) {
              lastValue = d.dataTypeDesc[d.lastReceivedDataValue];
            }
            else {
              if (typeof (d.lastReceivedDataValue) == "number") {
                lastValue = (Math.floor(d.lastReceivedDataValue * 100) / 100) + (d.unit ? (" " + d.unit) : "")
              }
              else {
                lastValue = d.lastReceivedDataValue;
              }
            }
          }


          this.data.push({
            index: (this.pagination.page - 1) * this.pagination.pageSize + c,
            point: d.pointName.slice(d.pointName.lastIndexOf("/") + 1, d.pointName.length),
            // parent: d.pointName.slice(0, d.pointName.lastIndexOf("/") + 1),
            parent: d.displayOrd ? d.displayOrd : d.friendlyname,
            building: d.building,
            friendlyname: d.friendlyName ? d.friendlyName : "Undefined",
            lastreceived: lastValue,
            tooltip: d.tags ? "Tags: " + d.tags.join(", ") + "\nFirst Reading at " + moment(d.firstReceivedData).format("DD/MM/YYYY HH:mm Z") : "First Reading at " + moment(d.firstReceivedData).format("DD/MM/YYYY HH:mm Z"),
            device: d.device,
            lastReceivedAt: moment(d.lastReceivedData).format("DD/MM/YYYY HH:mm"),
            lastReceivedTooltip: moment(d.lastReceivedData).format("DD/MM/YYYY HH:mm Z z"),
            hasTooltipValue: null,
            tooltipLastValue: d.dataType == 8 ? d.lastReceivedDataValue : "",
            dataType: d.dataType,
            _id: d._id,
            serialNumber: d.serialNumber,
            tags: d.tags ? d.tags : [],
            meta: d.facetsObj ? Object.keys(d.facetsObj).map(x => {
              return x + " : " + JSON.stringify(d.facetsObj[x]);
            }) : []
          })

          c++;
        }

        this.hasPoints = this.data.length > 0;

        this.pagination.hasNext = this.data.length > this.pagination.pageSize;

        this.dataSource.data = this.data;
        this.spinnerService.desactivate();
      },
      err => {
        this.spinnerService.desactivate();
        console.log(err);
        // this.alert.error(this.i18n.translate('general.errors.error'), this.i18n.translate('general.errors.unkwonError', err));
      }
    );
  }

  public openQR(value, label) {
    const dialogRef = this.dialog.open(QrPopupComponent, {
      width: '460px',
      data: {
        qr: [{ inputString: value, label: "Sensor token for " + label }],
        qrSize: 400
      }
    });
  }

  constructor(private queryService: QueryparamsService, private sessionService: SessionService, public buildingService: BuildingService, public loginService: LoginService, public dataService: AdminPointService, private appService: AppService,
    public i18n: AppI18nService, private spinnerService: SpinnerService, private alert: SweetalertService, private route: ActivatedRoute, public dialog: MatDialog) {



    route.queryParams.subscribe(params => {
      this.building = params["building"];
    });
  }

  public URLhasId = false;

  ngOnInit() {

    this.URLhasId = this.building != null;



    this.spinnerService.activate();
    this.buildingService.getAll().subscribe(x => {
      this.spinnerService.desactivate();

      this.buildings = x.data;

      this.queryService.params().subscribe(params => {
        this.pagination.pageSize = params["pageSize"] ? parseInt(params["pageSize"]) : 100;
        this.pagination.page = params["page"] ? parseInt(params["page"]) : 1;
        this.filter = params["filter"];

        if (params["building"]) {
          this.building = params["building"];
        }
        else if (this.sessionService.building != "") {
          this.building = this.sessionService.building;
        }


        this.updateData();
      })
    });

    //   if (!this.building && this.sessionService.building) {
    //     this.building = this.sessionService.building;
    //   }
    //   else if (this.building) {
    //     this.sessionService.building = this.building;
    //   }


    //   if (this.building != null)
    //     this.updateData();
    // })

    // this.appService.sectionName = this.i18n.translate("general.sections.building.metadata");

    this.displayedColumns = [
      "index", "icons", "parent", /*"point", "friendlyname",*/ "lastreceived", "lastReceivedAt", "meta", "tags"/*, "tooltip"*/
    ];

    // merge(this.sort.sortChange, this.paginator.page).pipe()

  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
  }

  public linked: boolean = true;
}
