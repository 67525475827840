import { PlatformLocation } from '@angular/common';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ThemingService {

  private _dictionary: any = undefined;
  private options: { origin: string, dictionary: any }[] = [
    {
      origin: "https://agriculturedev.futuredecisions.net", dictionary:
      {
        loginBackgroundImages: ["agro-login.jpg"],
        title: "Digital Agriculture Platform",
        bodyClass: "agro",
      }
    },
    {
      origin: "https://agriculture.futuredecisions.net", dictionary:
      {
        loginBackgroundImages: ["agro-login.jpg"],
        title: "Digital Agriculture Platform",
        bodyClass: "agro",
      }
    },
    {
      origin: "http://localhost:4200",
      dictionary:
      {
        loginBackgroundImages: ["agro-login.jpg"],
        title: "Digital Agriculture Platform",
        bodyClass: "agro",
      }
    }
  ]

  constructor(private platformLocation: PlatformLocation) {
    let origin = (this.platformLocation as any).location.origin;
    for (let o of this.options) {
      if (origin.indexOf(o.origin) == 0) {
        this._dictionary = o.dictionary;
        //  break;
      }
    }
  }

  get dictionary() {
    return this._dictionary;
  }

  public hasProperty(prop): boolean{
    return this.dictionary != undefined && this.dictionary[prop] != undefined;
  }

}
