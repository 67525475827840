import { Component, OnInit } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { BuildingService } from 'app/services/building.service';
import { PointgroupsService } from 'app/services/pointgroups.service';
import { QueryparamsService } from 'app/services/queryparams.service';
import { SessionService } from 'app/services/session.service';
import { SpinnerService } from 'app/services/spinner.service';

@Component({
  selector: 'app-admin-groups-list',
  templateUrl: './admin-groups-list.component.html',
  styleUrls: ['./admin-groups-list.component.css']
})
export class AdminGroupsListComponent implements OnInit {

  constructor(private queryService: QueryparamsService, private sessionService: SessionService, private translocoService: TranslocoService, private spinnerService: SpinnerService, private pointsGroupService: PointgroupsService, private buildingService: BuildingService) { }

  public buttonText = "";

  public points = [];
  public qrCodeLocked = "";
  public items = [];
  public searchField = "";
  public building = "";
  public buildings = [{ _id: "", name: "All Buildings" }];
  public pagination = { page: 1, pageSize: 100, hasNext: false };

  public searchChange(value) {
    console.log(value);
    this.searchField = value;
    this.refresh();
  }

  public pointsChanged(event) {
    this.points = event;
    this.pagination.pageSize = 100;
    this.pagination.page = 1;
    this.refresh();

  }
  public qrCodeLockedChanged(values: boolean[]) {
    if (values.length == 2 || values.length == 0) {
      this.qrCodeLocked = "";
    }
    else {
      this.qrCodeLocked = values[0] + "";
    }
    this.refresh();
  }

  public pageChanged(value) {
    this.pagination = value;

    this.refresh();
  }

  ngOnInit(): void {

    this.buttonText = this.translocoService.translate("admin.groupings.groups.create");

    this.spinnerService.activate();
    this.buildingService.getAll().subscribe(x => {
      this.spinnerService.desactivate();

      this.buildings = this.buildings.concat(x.data);

      this.queryService.hasParams().subscribe(check => {
        if (check) {
          this.queryService.params().subscribe(params => {
            this.building = params["building"];
            this.pagination.page = parseInt(params["page"]);
            this.pagination.pageSize = parseInt(params["pageSize"]);
            this.searchField = params["searchFilter"];
            this.points = params["points"];

          })
        }
        else if (this.sessionService.building) {
          this.building = this.sessionService.building;

          this.refresh();
        }
      })

    })
  }

  public changeBuilding(b) {
    this.building = b;

    this.pagination.page = 1;
    this.pagination.pageSize = 100;
    this.sessionService.building = b;

    this.refresh();
  }

  public refresh() {
    this.spinnerService.activate();
    // this.queryService.ReplaceParams({
    //   page: this.pagination.page,
    //   pageSize: this.pagination.pageSize,
    //   searchField: this.searchField,
    //   building: this.building,
    //   qrCodeLocked: this.qrCodeLocked,
    //   points: this.points
    // }).subscribe(x => {

    // })
    this.pointsGroupService.pointGroups(2, this.pagination.page, this.pagination.pageSize, this.searchField, this.building, this.qrCodeLocked, this.points).subscribe(x => {
      this.spinnerService.desactivate();
      this.items = x.data;

      this.items.forEach(i => i.building = this.buildings.find(b => b._id == i.building))

      this.pagination.hasNext = x.data.length > this.pagination.pageSize;
    });
  }

}
