import { Component, OnInit } from "@angular/core";
import { AppI18nService } from "app/services/app.i18n.service";
import { LoginService } from "app/services/login.service";
import { SpinnerService } from "app/services/spinner.service";
import { SweetalertService } from "app/services/sweetalert.service";
import { ActivatedRoute, Router } from "@angular/router";
import { IApiKey } from "../../../../interfaces/apiKey";
import { ApiKeyService } from "../../../../services/apiKey.service";
import { FormGroup } from '@angular/forms';
import * as moment from "moment";
import { SessionService } from "app/services/session.service";

@Component({
  selector: "app-admin-create-buildings",
  templateUrl: "./admin-create-buildings.component.html",
  styleUrls: ["./admin-create-buildings.component.css"]
})
export class AdminCreateApiKeyComponent implements OnInit {

  constructor(private sessionService: SessionService, private route: ActivatedRoute, private router: Router, private spinner: SpinnerService, private apiKeyService: ApiKeyService,
    private loginService: LoginService, private alert: SweetalertService) {


  }

  ngOnInit() {
  }

  public save(event: IApiKey) {
    this.spinner.activate();
    this.sessionService.building = event.building;
    this.apiKeyService.create(event).subscribe(
      x => {
        this.spinner.desactivate();

        // this.alert.success( this.i18n.translate( "general.messages.success" ),
        //   this.i18n.translate( "general.messages.created", this.i18n.translate( "interfaces.apiKey.apiKeys" ) ) );
        // this.router.navigate( [ "admin", "buildings", this.building, "apiKeys" ] );
        this.alert.success("Success!", "New API Key created!");
        this.router.navigate(["admin/security/accesskey/"]);
      },
      e => {
        console.log(e);
        this.spinner.desactivate();
      }
    );
  }
}
