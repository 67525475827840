import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { FutureDecisionsDate } from 'app/components/classes/futuredecisions.date';
import { BuildingService } from 'app/services/building.service';
import { SpinnerService } from 'app/services/spinner.service';

import * as moment from "moment-timezone";
import * as humanizeDuration from "humanize-duration"

import { FaultsActionComponent } from '../popups/faults-action/faults-action.component';
import { FaultsResolvedComponent } from '../popups/faults-resolved/faults-resolved.component';
import { TranslocoService } from '@ngneat/transloco';
import { FaultsNotfaultComponent } from '../popups/faults-notfault/faults-notfault.component';
import { SweetalertService } from 'app/services/sweetalert.service';
import { PointgroupsService } from 'app/services/pointgroups.service';
import { AdminPointService } from 'app/services/data.service';
import { forkJoin } from 'rxjs';
import { FaultsDataComponent } from '../popups/faults-data/faults-data.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FaultAssignComponent } from '../popups/fault-assign/fault-assign.component';

@Component({
  selector: 'app-faults-view',
  templateUrl: './faults-view.component.html',
  styleUrls: ['./faults-view.component.css']
})
export class FaultsViewComponent implements OnInit {

  public datasource = new MatTableDataSource<any>();
  public datasourceActions = new MatTableDataSource<any>();

  public displayedActionsColumns = ["date", "by", "action", "message"];
  public displayedColumns = ["severity", "date", "status", "issue", "subject", "assignedto"];
  public filter = "";
  public pagination = { pageSize: 100, page: 1, hasNext: false }

  constructor(private _snackBar: MatSnackBar, private dataService: AdminPointService, private assetService: PointgroupsService, private alertService: SweetalertService, private transloco: TranslocoService, public dialog: MatDialog, private activatedroute: ActivatedRoute, private buildingService: BuildingService, private spinner: SpinnerService) { }

  private building: string = "";
  private faultId: string = "";

  public fault = {
    timestamp: "",
    type: 0,
    raised: "",
    createdAt: "",
    by: "",
    canBeAssigned: false,
    assign: "",
    duration: "",
    severity: "",
    asset: {
      name: "",
      _id: ""
    },
    status: "",
    statusCode: 0,
    subject: "",
    description: "",
    audio: null
  }

  public audioLink = "";

  @ViewChild('audio_player', { static: false }) audioPlayer;

  public openViewData() {
    console.log(this.fault);

    this.spinner.activate();
    this.assetService.getPointGroup(this.fault.asset._id).subscribe(x => {

      let arr = [];

      if (!x.points) {
        this.spinner.desactivate();

        this._snackBar.open(this.transloco.translate("admin.faults.view.nodata"),
          this.transloco.translate("admin.faults.view.dismiss"), {
          duration: 5000,
          horizontalPosition: "right",
          verticalPosition: "bottom",
        })
      }
      else {

        let searchDate = moment(this.fault.timestamp);

        x.points.forEach(p => {
          arr.push(
            this.dataService.GetDataForPoint({
              building: this.building,
              point: p._id,
              from: searchDate.clone().startOf("day").toISOString(),
              to: searchDate.clone().endOf("day").toISOString()
            })
          )
        })

        let data:
          {
            name: string,
            id: string,
            data: any[]
          }[] = []

        forkJoin(arr).subscribe((res: any) => {
          this.spinner.desactivate();


          data = (res.map(y => {
            let point = x.points.find(z => z._id == y.data[0].point);
            return {
              name: point.friendlyName,
              id: point.friendlyName,
              data: y.data[0].weekPointData[searchDate.weekday()].map((v, i) => {
                return [searchDate.clone().startOf("day").add(i, "minute").valueOf(), v]
              })
            }
          }))

          const dialogRef = this.dialog.open(FaultsDataComponent, {
            width: '1080px',
            data: {
              points: data,
              title: this.fault.asset.name + " - " + searchDate.format("DD/MM/YYYY")
            }
          });

        })
      }


    })

  }

  public actions = [];

  private init() {
    this.spinner.activate();
    this.activatedroute.params.subscribe(x => {
      this.faultId = x["id"];
      this.building = x["building"];

      this.buildingService.getFault(this.building, this.faultId).subscribe(t => {

        this.playlist[0].link = t.audioURL;

        console.log(t);

        this.fault.createdAt = moment(t.created_at).format("DD/MM/YYYY HH:mm")
        this.fault.subject = t.data.subject;
        this.fault.timestamp = t.data.timestamp;
        this.fault.canBeAssigned = t.data.assignContact ? false : true;
        this.fault.assign = t.data.assignContact ? t.data.assignContact : this.transloco.translate("admin.faults.view.unassigned");
        this.fault.by = t.data.createdBy ? t.data.createdBy.person.name : this.transloco.translate("admin.faults.view.app");
        this.fault.description = t.data.description;
        this.fault.duration = humanizeDuration(moment().diff(moment(t.data.timestamp)), { units: ["d"], round: true });
        this.fault.raised = moment(t.timestamp).format("DD/MM/YYYY");
        this.fault.severity = t.data.severity;
        this.fault.type = t.data.type;
        this.fault.statusCode = t.data.resolved ? 1 : (t.data.notFault ? 0 : -1);
        this.fault.status = t.data.resolved ? this.transloco.translate("admin.faults.list.resolved") : (t.data.notFault ? this.transloco.translate("admin.faults.list.notfault") : this.transloco.translate("admin.faults.list.open"))
        this.fault.asset = t.data.pointGroup ? {
          name: t.data.pointGroup.name,
          _id: t.data.pointGroup._id
        } : { name: this.transloco.translate("admin.faults.view.noasset"), _id: undefined }

        this.spinner.desactivate();

        this.actions = t.data.actions ? t.data.actions.map(y => {
          return {
            action: this.transloco.translate("admin.faults.actionsenum." + y.action),
            date: moment(y.timestamp).format("DD/MM/YYYY HH:mm Z"),
            message: y.message,
            by: y.createdByName
          }
        }) : []

        if (this.fault.asset._id != "") {
          this.refresh();
        }
      })
    })
  }

  public assignContact() {
    const dialogRef = this.dialog.open(FaultAssignComponent, {
      width: '480px',
      data: {
        buidling: this.building
      }
    });

    dialogRef.afterClosed().subscribe(ret => {
      if(ret){
        this.spinner.activate();
        this.buildingService.updateFault(this.building, this.faultId, {assign: ret}).subscribe(x =>{
          this.spinner.desactivate();

          this.init();
        })
      }
    })
  }

  ngOnInit(): void {
    this.init();
  }

  public applyFilter(filter) {
    this.filter = filter;
  }

  public assetName = "Asset XYZ"
  public severityfilter = [1, 5, 10];
  public issueType = [1, -1];

  public severityenym = [1, 5, 10];
  public issuetypeenum = [1, -1];
  public date = new FutureDecisionsDate();
  public timewindow = "Year";

  public playlist = [
    {
      title: 'Digital Buildings App',
      link: "/assets/audios/test.mp3",
      artist: 'Digital Buildings App'
    }
  ]

  public refresh() {

    let q = "";

    let start = this.date.dateObject.startOf("day");
    let end;
    switch (this.timewindow) {
      case "One day":
        end = start.clone().add(1, "day").startOf("day");
        break;
      case "Last three days":
        end = start.clone().startOf("day");
        start = start.add(3, "day").startOf("day");
        break;
      case "Week":
        start = start.startOf("week");
        end = start.clone().endOf("week");
        break;
      case "Month":
        start = start.startOf("month");
        end = start.clone().endOf("month");
        break;
      case "Year":
        start = start.startOf("year");
        end = start.clone().endOf("year");
        break;
    }

    q = `start=${start.toISOString()}&end=${end.toISOString()}&type=${this.issueType.join("&type=")}&severity=${this.severityfilter.join("&severity=")}&asset=${this.fault.asset._id}`

    this.datasource.data = [];

    this.spinner.activate();
    this.buildingService.getFaults(this.building, this.pagination.page, this.pagination.pageSize, this.filter, q).subscribe(x => {
      this.spinner.desactivate();

      this.pagination.hasNext = x.data.length > this.pagination.pageSize;

      this.datasource.data = x.data.map(t => {

        let v = {
          date: moment(t.timestamp).format("DD/MM/YYYY"),
          status: t.resolve ? this.transloco.translate("admin.faults.list.resolved") : (t.notFault ? this.transloco.translate("admin.faults.list.notfault") : this.transloco.translate("admin.faults.list.open")),
          issue: this.transloco.translate("admin.faults.issueenum." + t.type),
          subject: t.subject,
          assign: t.assign ? `${t.assign.person.name} (${t.assign.email})` : this.transloco.translate("admin.faults.view.unassigned"),
          severity: this.transloco.translate('admin.faults.severityenum.' + t.severity),
          severityCode: t.severity,
          _id: t._id,
          building: t.building
        }

        return v;
      });
    })
  }

  public possibleWindows = ["One day", "Last three days", "Week", "Month", "Year"];
  public today = moment();

  @ViewChild(MatSort) sort: MatSort;

  ngAfterViewInit() {
    this.datasource.sort = this.sort;
    this.audioPlayer.repeat = "none";
  }

  public openresolved() {
    const dialogRef = this.dialog.open(FaultsResolvedComponent, {
      width: '960px'
    });
    dialogRef.afterClosed().subscribe(x => {
      if (x == undefined) return;
      this.spinner.activate();
      this.buildingService.faultAction(this.building, this.faultId, x.description, 1).subscribe(t => {
        this.spinner.desactivate();

        this.alertService.success(this.transloco.translate('messages.success'), this.transloco.translate('admin.faults.view.messages.resolved'))

        this.init();
      })
    })
  }

  public opennewaction() {
    const dialogRef = this.dialog.open(FaultsActionComponent, {
      width: '960px'
    });
    dialogRef.afterClosed().subscribe(x => {
      if (x == undefined) return;
      this.spinner.activate();
      this.buildingService.faultAction(this.building, this.faultId, x.description, 3).subscribe(t => {
        this.spinner.desactivate();

        this.alertService.success(this.transloco.translate('messages.success'), this.transloco.translate('admin.faults.view.messages.newaction'))
        this.init();
      })
    })
  }

  public openotfault() {
    const dialogRef = this.dialog.open(FaultsNotfaultComponent, {
      width: '960px'
    });
    dialogRef.afterClosed().subscribe(x => {
      if (x == undefined) return;

      this.spinner.activate();
      this.buildingService.faultAction(this.building, this.faultId, x.description, 2).subscribe(t => {
        this.spinner.desactivate();

        this.alertService.success(this.transloco.translate('messages.success'), this.transloco.translate('admin.faults.view.messages.notfault'))
        this.init();
      })
    })
  }


  public pageChanged(pagination) {
    this.pagination.page = pagination.page;
    this.pagination.pageSize = pagination.pageSize;
  }

}
