import { Component, Inject, Input, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-qr-popup',
  templateUrl: './qr-popup.component.html',
  styleUrls: ['./qr-popup.component.css']
})
export class QrPopupComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: { qrSize: number, qr: { inputString: string, label: string }[] }) { }

  ngOnInit(): void {
  }


  public copy(text) {
    const el = document.createElement('textarea');
    el.value = text;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
  }

}
