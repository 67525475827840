import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from "@angular/core";
import { Location } from "@angular/common";
import { ActivatedRoute, Router } from "@angular/router";
import { CompanyService } from "app/services/company.service";
import { SpinnerService } from "app/services/spinner.service";
import { SweetalertService } from "app/services/sweetalert.service";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { AppService } from "app/services/app.service";
import { IApiKey } from "../../../interfaces/apiKey";
import { AdminPointService } from "../../../services/data.service";
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { DATEPICKER_FORMAT } from 'app/components/adapters/DatepickerFormats';
import { BuildingService } from 'app/services/building.service';
import * as moment from "moment";
import { SessionService } from "app/services/session.service";
import { Observable } from "rxjs";
import { SearchPopupComponent } from "../search-popup/search-popup.component";
import { MatDialog } from "@angular/material/dialog";

@Component({
  selector: "app-apiKey-form",
  templateUrl: "./apiKey-form.component.html",
  styleUrls: ["./apiKey-form.component.css"],
  providers: [
    { provide: MAT_DATE_FORMATS, useValue: DATEPICKER_FORMAT }
  ]
})
export class ApiKeyFormComponent implements OnInit {

  @Output() onsubmit: EventEmitter<any> = new EventEmitter<any>();
  @Output() onDelete: EventEmitter<any> = new EventEmitter<any>();
  @Input() apiKey: IApiKey;

  @Input() enabledEdit = true;

  public min = moment().add(1, "day").startOf("day");

  // name: string;
  // address: string;
  // company: string;
  // description: string;
  // contact: Contact;
  // geographicalPosition: Vector2;

  public points: any = [];

  constructor(public dialog: MatDialog, private sessionService: SessionService, private buildingService: BuildingService, private route: ActivatedRoute, private pointService: AdminPointService, public appService: AppService,
    private router: Router, private _location: Location, private spinnerService: SpinnerService, private alertService: SweetalertService) {
  }

  public apiKeyForm: FormGroup = new FormGroup({
    description: new FormControl("", Validators.required),
    expiration_date: new FormControl(""),
    building: new FormControl('', [Validators.required]),
    linkedIPAddress: new FormControl(""),
    allPoints: new FormControl(true, Validators.required)
  });



  ngOnChanges(changes: SimpleChanges) {
    if (this.apiKey) {
      this.points = this.apiKey.points;
      this.apiKeyForm = new FormGroup({
        building: new FormControl(this.apiKey.building, [Validators.required]),
        description: new FormControl(this.apiKey.description, Validators.required),
        expiration_date: new FormControl(this.apiKey.expiration_date),
        linkedIPAddress: new FormControl(this.apiKey.linkedIPAddress),
        allPoints: new FormControl(this.apiKey.allPoints, Validators.required)
      });

      this.changeBuilding();
    }
  }


  public openDialog() {
    const dialogRef = this.dialog.open(SearchPopupComponent, {
      width: '960px',
      data: {
        single: false,
        assetName: "Points",
        displayField: "name",
        valueField: "id",
        retunObject: true,
        disableSelect: false,
        searchCallback: this.searchPoints.bind(this),
      }
    });

    dialogRef.afterClosed().subscribe(x => {
      if (x.length == 0) {
        return;
      }
      else {
        this.add(x);
      }
    })
  }

  public searchPoints(filter: string, pageOptions: { page: number, pageSize: number, hasNext: boolean }): Observable<any> {
    return new Observable(observer => {
      this.spinnerService.activate();
      this.pointService.GetListOfPoints(this.apiKeyForm.get("building").value, false, filter, pageOptions.page, pageOptions.pageSize).subscribe(x => {
        this.spinnerService.desactivate();

        observer.next(x.data);
        observer.complete();
      })

    })
  }

  public changeBuilding() {
    this.points = [];
    // this.spinnerService.activate();
    // this.pointService.GetListOfPoints(this.apiKeyForm.get("building").value).subscribe(
    //   results => {
    //     this.points = [];
    //     this.pointList = results.data;
    //     this.filterPoints();
    //     this.spinnerService.desactivate();
    //   },
    //   err => {
    //     this.spinnerService.desactivate();
    //     console.log(err);
    //   }
    // );
  }

  public buildings = [];

  ngOnInit() {
    // this.spinnerService.activate();
    // this.route.params.subscribe(
    //   params => {

    //   },
    //   err => {
    //     console.log( err );
    //     this.spinnerService.desactivate();
    //   }
    // );

    this.apiKeyForm.get("building").setValue(this.sessionService.building);

    this.spinnerService.activate();
    this.buildingService.getAll().subscribe(x => {
      this.spinnerService.desactivate();
      this.buildings = x.data;
    })
  }

  public goBack() {
    this._location.back();
  }

  public onSubmit() {
    if (this.apiKeyForm.valid) {
      let apiKey: any = this.apiKeyForm.value;
      apiKey.points = this.points.map((p: any) => p.id);
      this.onsubmit.emit(apiKey);
    }
  }

  public reset() {
    this.apiKeyForm.reset({});
  }

  public delete() {
    this.onDelete.emit(this.apiKey._id);
  }

  // public filterPoints() {
  //   let filter = (this.newPoint instanceof Object ? this.newPoint.name : this.newPoint) || "";

  //   this.filteredPoints = this.pointList.filter((p: any) => {
  //     return p.name.toLowerCase().indexOf(filter.toLowerCase()) >= 0;
  //   });
  // }

  public add(event) {
    for (let p of event) {
      if (this.points.find(x => x.id == p.id)) return;
      this.points.push(p);
    }
    // this.filterPoints();
  }

  public remove(point) {
    this.points = this.points.filter((p: any) => p.id != point.id);
  }

  public displayByName(obj) {
    return "";
  }

  public newPoint: any = "";
  public filteredPoints: any;
  private pointList: any;
}
