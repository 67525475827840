import { NgModule } from '@angular/core';

import { AppSelectComponent } from './select.component';
import { BrowserModule } from "@angular/platform-browser";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";

@NgModule({
    imports: [BrowserModule, CommonModule, FormsModule],
    exports: [AppSelectComponent],
    declarations: [AppSelectComponent],
    providers: [],
})
export class AppSelectModule { }
