import { ErrorHandler, Injectable, NgZone } from "@angular/core";
import { SpinnerService } from "app/services/spinner.service";

import { MatSnackBar } from '@angular/material/snack-bar';
import { HttpErrorResponse } from "@angular/common/http";
import { LoginService } from "app/services/login.service";

import * as moment from "moment-timezone";

import { ErrorReportService } from "app/services/error-report.service";
import { AppService } from "app/services/app.service";

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {

  private _hasError = false;

  private ignoredErrors = ["ExpressionChangedAfterItHasBeenCheckedError"]

  constructor(private appService: AppService, private reportService: ErrorReportService, private zone: NgZone, private spinner: SpinnerService, private _snackBar: MatSnackBar, private loginService: LoginService) { }

  public showError(){
    if(this._hasError){
      this._hasError = false;
    }
  }

  handleError(error: Error) {
    for (let i of this.ignoredErrors) {
      if (error.message.indexOf(i) > -1) {
        // console.log(error);
        return
      }
    }

    if (location.hostname == "localhost") {
      console.log(error);
      return;
    };

    this.spinner.fullStop();

    console.error("Error from global error handler\n", error);

    let r: {
      stack: string,
      user?: string,
      date: string,
      url?: string,
      other?: string,
      img?: any
    } = {
      date: moment().toISOString(),
      stack: ""
    };

    r.stack = error.stack;

    if (error instanceof HttpErrorResponse) {
      let t: any = error
      if (t.error && t.error.errors) {
        r.other = t.error.errors.map(x => x.message).join("\n");
        r.url = t.url;
      }
      else {
        r.other = error.name + "\n" + error.message;
      }
    }
    else {
      r.other = error.name + "\n" + error.message;
    }


    if (this.loginService.isAuthenticated()) {
      r.user = this.loginService.userInfo.primaryEmail + " " + this.loginService.userInfo._id;
    }



    this.reportService.prepareReport(r.stack, r.date, r.user, r.url, r.other, false);
    this._hasError = true;

    this.appService.hasError=true;

    // this._snackBar.open('Anomaly detected and has been automatically reported.', 'Dismiss', {
    //   duration: 5000,
    //   horizontalPosition: "right",
    //   verticalPosition: "bottom",
    // });
  }
}
